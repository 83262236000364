
:root {
    --menu-transition: 350ms ease;
}

/*
* frankenMenu v1.0 // Karmabunny Web Design // built by Luke Underwood
*/


/** Desktop and Mobile styling
===================================*/
#frankenmenu-list,
#frankenmenu-list ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: block;
}

#frankenmenu-list a {
    text-decoration: none;
    display: block;
    font-size: 1.6rem;
}


/** Desktop menu only
===================================*/
@include screen-menu-desktop() {

    #frankenmenu {
        z-index: 1000;
    }

    #frankenmenu-list {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: -webkit-box;
        display: flex;
    }
    #mobile-header {
        display: none;
    }

    /* ---- All level menu items ---- */
    #frankenmenu-list .menu-item {
        position: relative;
    }
    #frankenmenu-list .sub-menu {
        display: none;
        min-width: 200px;
        position: absolute;
        -webkit-box-shadow: 0 0 16px 1px rgba(0,0,0,0.4);
                box-shadow: 0 0 16px 1px rgba(0,0,0,0.4);
        z-index: 1;
        min-width: 250px;
        max-width: 350px;
        text-align: left;
        top: 0;
        left: 100%;
    }

    /* ---- First level menu ---- */
    #frankenmenu-list .menu-item-depth1 {
        text-align: center;
        flex: 1 1 auto;
        margin-left: calc((var(--default-container) - 236px) * 0.038);

        &:first-child {
            margin-left: 0;
        }
    }
    .faux-menu-item-link,
    #frankenmenu-list .menu-item-depth1 > a {
        position: relative;
        display: block;
        color: $color-white;
        font-size: calc(var(--body-font-size)*1.12);
        line-height: 32px;
        padding: 4px 0;
        font-family: $accent-font;
        font-weight: $fw-medium;

        &:after {
            content: "";
            position: absolute;
            left: 50%;
            right: 50%;
            top: 100%;
            height: 1px;
            background-color: transparent;
            transition:
                left $link-transition,
                right $link-transition,
                background-color $link-transition;
        }
    }

    /* Hover */
    .faux-menu-item-link:hover,
    .faux-menu-item-link:focus,
    #frankenmenu-list .menu-item-depth1 > a:hover,
    #frankenmenu-list .menu-item-depth1 > a:focus,
    #frankenmenu-list .menu-item-depth1.frankenhover > a,
    #frankenmenu-list .menu-item-depth1.frankenhold > a {
        color: $color-accent-02;

        &:after {
            left: 0;
            right: 0;
            background-color: $color-accent-02;
        }
    }
    .faux-menu-item-link {
        text-decoration: none;
    }

    /* Current item */
    .faux-menu-item-link--current,
    #frankenmenu-list .menu-item-depth1.menu-current-item > a {
        color: $color-white;

        &:after {
            left: 0;
            right: 0;
            background-color: $color-white;
        }
    }
    .menu-home-page {
        display: none;
    }

    /* Ancestor item */
    #frankenmenu-list .menu-current-item-ancestor a {
        color: $color-white;

        &:before {
            content: "";
            position: absolute;
            top: 100%;
            left: 50%;
            right: auto;
            transform: translateX(-50%);
            border-style: solid;
            border-width: 5px 5px 0 5px;
	        border-color: #FFF transparent transparent transparent;
        }

        &:hover,
        &:focus,
        &:active {
            &:before {
                border-color: $color-accent-02 transparent transparent transparent;
            }
        }
    }

    /* ---- Submenus ---- */
    #frankenmenu-list .sub-menu a {
        border-top: $color-grey-01;
        padding: 0.75em 1em;
        font-size: 1.5rem;
        background-color: $color-white;
        color: $color-body-font;
        line-height: normal;
        transition: all 200ms ease;
        font-weight: $fw-semibold;
    }
    #frankenmenu-list .sub-menu .menu-item:first-child a {
        border-top: none;
    }
    #frankenmenu-list .sub-menu .menu-item > a:hover,
    #frankenmenu-list .sub-menu .menu-item > a:focus,
    #frankenmenu-list .sub-menu .menu-item.frankenhover > a,
    #frankenmenu-list .sub-menu .menu-item.frankenhold > a {
        background-color: $color-accent-02;
        color: $color-primary;
    }

    /* Sub menu - Current item */
    #frankenmenu-list .sub-menu .menu-item.menu-current-item > a {
        color: $color-white;
        background-color: $color-accent-01;
    }

    #frankenmenu-list .frankenhold > .sub-menu {
        display: block !important;
        opacity: 1 !important;
    }

    /* ---- First level submenus ---- */
    #frankenmenu-list .sub-menu-depth1 {
        top: 100%;
        left: auto;
    }

    /* ---- Right aligned submenus ---- */
    #frankenmenu-list .sub-menu.submenu-right-align,
    #frankenmenu-list .sub-menu.submenu-right-align .sub-menu {
        left: auto;
        right: 100%;
    }
    #frankenmenu-list .sub-menu-depth1.submenu-right-align {
        left: auto;
        right: 0;
    }


    /* ---- Sub-navigation ---- */
    #frankenmenu-list .menu-item-subnav {
        display: none;
    }
    #frankenmenu-subnav {
        float: right;
    }
    #frankenmenu-subnav-list,
    #frankenmenu-subnav-list ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }
    #frankenmenu-subnav-list .menu-item-depth1 {
        display: inline-block;
    }
    #frankenmenu-subnav-list .menu-item-depth1 > a {
        text-decoration: none;
        padding: 5px 5px;
        display: block;
        color: inherit;
    }
    #frankenmenu-subnav-list .menu-item-depth1 > a:hover {
        text-decoration: underline;
    }
    #frankenmenu-subnav-list .sub-menu {
        display: none;
    }

    /* ---- Moved elements ---- */
    .frankenmoved {
        display: none;
    }

    /* ---- Megamenu ---- */
    #frankenmenu-list .mega-menu {
        left: 0;
        right: 0;
        background-color: $color-white;
        text-align: left;
        top: 100%;
        position: absolute;
        border-bottom: 4px solid $color-grey-02;
        z-index: 51;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        width: 1px;
    }
    /* Remove relative positioning for mega menu */
    .frankenmenu-mega #frankenmenu-list .menu-item {
        position: static;
    }
    #frankenmenu-list .menu-item.frankenhover .mega-menu,
    #frankenmenu-list .menu-item.frankenhold .mega-menu {
        padding: 40px 0;
        width: auto;
        height: auto;
        clip: auto;
        overflow: visible;
        margin: auto;
    }
    #frankenmenu-list .menu-item.frankenhold .mega-menu {
        display: block !important;
        height: auto !important;
    }

    /* Megamenu preview */
    #frankenmenu-list .mega-menu-preview {
        float: left;
        color: $color-white;
        padding: 18px var(--body-font-size);
        background-color: $color-grey-06;
        width: 22%;
        margin-right: 3%;
    }
    #frankenmenu-list .mega-menu-preview-image {
        height: 1var(--body-font-size);
        background: url(../images/mega-menu-image-fallback.jpg) no-repeat center center;
        background-size: cover;
    }
    #frankenmenu-list .mega-menu-preview-text {
        margin-bottom: 0;
        line-height: 1.2;
    }

    /* Megamenu columns */
    #frankenmenu-list .mega-menu .mega-menu-columns {
        float: left;
        width: 75%;
    }
    #frankenmenu-list .mega-menu-column {
        width: 31.33333333%;
        float: left;
        margin-right: 3%;
    }
    #frankenmenu-list .mega-menu-column:last-child {
        margin-right: 0;
    }

    /* Megamenu submenus */
    #frankenmenu-list .mega-menu-submenu .menu-item {
        border-top: 1px solid $color-grey-02;
    }
    #frankenmenu-list .mega-menu-submenu .menu-item:last-child {
        border-bottom: 1px solid $color-grey-02;
    }
    #frankenmenu-list .mega-menu-submenu a {
        color: $color-black;
        padding: 5px 0;
    }

    /* ---- Specialty Menu Items ---- */
    #frankenmenu-list .menu-item.menu-login {
        display: none;
    }

}

@include screen-1200 {
    #frankenmenu-list .menu-item-depth1 > a {
        font-size: calc(var(--body-font-size)*1.25);
    }
    .menu-home-page {
        display: block;
    }
    .faux-menu-item-link--home {
        display: none;
    }
}

@include screen-1400 {
    #frankenmenu-list .menu-item-depth1 {
        margin-left: calc((var(--default-container) - 236px) * 0.045);
    }
    #frankenmenu-list .menu-item-depth1 > a {
        font-size: 2.4rem;
    }
}

@include screen-1600 {
    #frankenmenu-list .menu-item-depth1 {
        margin-left: 64px;
    }
}

/** Mobile menu only
===================================*/
@include screen-menu-mobile() {

    /* Prevents font-boosting of menu items on Android */
    #frankenmenu * {
        max-height: 999999px;
    }

    #wrap {
        margin-top: 40px;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        background-color: $color-white;
    }
    #frankenmenu {
        top: 0;
        position: fixed;
        overflow-y: scroll;
        overflow-x: hidden;
        height: 100%;
        background-color: $color-grey-01;
        width: 70%;
        z-index: 0;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        padding-top: 64px;
    }
    #frankenmenu .container {
        position: relative;
        padding: 0;
        max-width: none;
        width: 100%;
        margin: 0;
        opacity: 0;
    }

    /* ---- Mobile header ---- */
    #mobile-header {
        background-color: $color-primary;
        height: 40px;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 100;
    }
    #mobile-menu-button {
        -webkit-appearance: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        outline: none;
        border: none;
        padding: 0;
        text-indent: -9999px;
        width: 40px;
        height: 36px;
        margin-top: 2px;
        position: relative;
        background: none;
    }

    .mobile-menu-button-top,
    .mobile-menu-button-middle,
    .mobile-menu-button-bottom {
        height: 3px;
        background-color: $color-white;
        left: 6px;
        -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
        -webkit-animation-duration: 400ms;
        animation-duration: 400ms;
        position: absolute;
        display: block;
        right: 6px;
    }
    .mobile-menu-button-top {
        top: 8px;
    }
    .mobile-menu-button-middle {
        top: 17px;
    }
    .mobile-menu-button-bottom {
        top: 26px;
    }

    /* Animations active */
    .frankenmenu-mob-menu-animations .mobile-menu-button-top {
        -webkit-animation-name: mobileMenuBtnTopClose;
        animation-name: mobileMenuBtnTopClose;
    }
    .frankenmenu-mob-menu-animations .mobile-menu-button-middle {
        -webkit-animation-name: mobileMenuBtnMiddleClose;
        animation-name: mobileMenuBtnMiddleClose;
    }
    .frankenmenu-mob-menu-animations .mobile-menu-button-bottom {
        -webkit-animation-name: mobileMenuBtnBottomClose;
        animation-name: mobileMenuBtnBottomClose;
    }

    /* Close button */
    .frankenmenu-mob-menu-visible #mobile-menu-button .mobile-menu-button-top {
        -webkit-animation-name: mobileMenuBtnTopOpen;
        animation-name: mobileMenuBtnTopOpen;
    }
    .frankenmenu-mob-menu-visible #mobile-menu-button .mobile-menu-button-middle {
        -webkit-animation-name: mobileMenuBtnMiddleOpen;
        animation-name: mobileMenuBtnMiddleOpen;
    }
    .frankenmenu-mob-menu-visible #mobile-menu-button .mobile-menu-button-bottom {
        -webkit-animation-name: mobileMenuBtnBottomOpen;
        animation-name: mobileMenuBtnBottomOpen;
    }



    @-webkit-keyframes mobileMenuBtnTopClose {
        0% {
            -webkit-transform: translate(0, 9px) rotate(45deg);
            transform: translate(0, 9px) rotate(45deg);
        }
        50%, 70% {
            -webkit-transform: translate(0, 9px);
            transform: translate(0, 9px);
        }
        100% {
            -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
        }
    }



    @keyframes mobileMenuBtnTopClose {
        0% {
            -webkit-transform: translate(0, 9px) rotate(45deg);
            transform: translate(0, 9px) rotate(45deg);
        }
        50%, 70% {
            -webkit-transform: translate(0, 9px);
            transform: translate(0, 9px);
        }
        100% {
            -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
        }
    }
    @-webkit-keyframes mobileMenuBtnTopOpen {
        0% {
            -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
        }
        50%, 70% {
            -webkit-transform: translate(0, 9px);
            transform: translate(0, 9px);
        }
        100% {
            -webkit-transform: translate(0, 9px) rotate(45deg);
            transform: translate(0, 9px) rotate(45deg);
        }
    }
    @keyframes mobileMenuBtnTopOpen {
        0% {
            -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
        }
        50%, 70% {
            -webkit-transform: translate(0, 9px);
            transform: translate(0, 9px);
        }
        100% {
            -webkit-transform: translate(0, 9px) rotate(45deg);
            transform: translate(0, 9px) rotate(45deg);
        }
    }
    @-webkit-keyframes mobileMenuBtnBottomClose {
        0% {
            -webkit-transform: translate(0, -9px) rotate(-45deg);
            transform: translate(0, -9px) rotate(-45deg);
        }
        50%, 70% {
            -webkit-transform: translate(0, -9px);
            transform: translate(0, -9px);
        }
        100% {
            -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
        }
    }
    @keyframes mobileMenuBtnBottomClose {
        0% {
            -webkit-transform: translate(0, -9px) rotate(-45deg);
            transform: translate(0, -9px) rotate(-45deg);
        }
        50%, 70% {
            -webkit-transform: translate(0, -9px);
            transform: translate(0, -9px);
        }
        100% {
            -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
        }
    }
    @-webkit-keyframes mobileMenuBtnBottomOpen {
        0% {
            -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
        }
        50%, 70% {
            -webkit-transform: translate(0, -9px);
            transform: translate(0, -9px);
        }
        100% {
            -webkit-transform: translate(0, -9px) rotate(-45deg);
            transform: translate(0, -9px) rotate(-45deg);
        }
    }
    @keyframes mobileMenuBtnBottomOpen {
        0% {
            -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
        }
        50%, 70% {
            -webkit-transform: translate(0, -9px);
            transform: translate(0, -9px);
        }
        100% {
            -webkit-transform: translate(0, -9px) rotate(-45deg);
            transform: translate(0, -9px) rotate(-45deg);
        }
    }
    @-webkit-keyframes mobileMenuBtnMiddleClose {
        0% {
            opacity: 0;
        }
        50%, 70%, 100% {
            opacity: 1;
        }
    }
    @keyframes mobileMenuBtnMiddleClose {
        0% {
            opacity: 0;
        }
        50%, 70%, 100% {
            opacity: 1;
        }
    }
    @-webkit-keyframes mobileMenuBtnMiddleOpen {
        0% {
            opacity: 1;
        }
        50%, 70%, 100% {
            opacity: 0;
        }
    }
    @keyframes mobileMenuBtnMiddleOpen {
        0% {
            opacity: 1;
        }
        50%, 70%, 100% {
            opacity: 0;
        }
    }


    /* ---- All level menu items ---- */
    #frankenmenu-list, #frankenmenu-list ul {
        width: 100%;
    }
    #frankenmenu-list .menu-item {
        position: relative;
        border-bottom: 1px solid $color-grey-01;
        float: none;
        width: 100%;
    }
    #frankenmenu-list a {
        min-height: 40px;
        width: 100%;
        padding: 0.6em 56px 0.6em var(--body-font-size);
        color: $color-body-font;
        background-color: $color-grey-01;
    }
    #frankenmenu-list .menu-item.menu-current-item > a {
        background-color: $color-accent-01-mid;
        color: $color-primary;
    }

    /* ---- First level menu ---- */
    #frankenmenu-list .menu-item-depth1 > a {
        font-size: 1.5rem;
    }

    /* ---- Submenus ---- */
    #frankenmenu-list .sub-menu {
        display: none;
    }
    #frankenmenu-list .menu-current-item-ancestor > .sub-menu {
        display: block;
    }

    /* Depth differences */
    #frankenmenu-list .sub-menu .menu-item > a  {
        padding-left: calc(var(--body-font-size) * 2);
        font-size: 1.4rem;
    }
    #frankenmenu-list .sub-menu-depth2 .menu-item > a  {
        padding-left: calc(var(--body-font-size) * 3);
    }

    /* ---- Ancestor ---- */
    #frankenmenu-list .menu-current-item-ancestor {
        > a {
            color: $color-white;
            background-color: $color-accent-01;
        }

        .submenu-toggle:before,
        .submenu-toggle:after {
            background-color: $color-primary;
        }
    }

    /* Expand buttons */
    .submenu-toggle {
        -webkit-appearance: none;
        -moz-appearance: none;
        outline: none;
        border: none;
        width: 55px;
        background: none;
        height: 40px;
        display: block;
        padding: 0;
        margin: 0;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        cursor: pointer;
        padding: 0;
    }
    .submenu-toggle:before,
    .submenu-toggle:after {
        content: " ";
        width: 12px;
        height: 2px;
        display: block;
        position: absolute;
        background-color: $color-grey-06;
        margin: 0 auto;
        left: 0;
        right: 0;
        top: 22px;
        transition: all 400ms ease;
    }
    .submenu-toggle:before {
        -webkit-transform: rotate(45deg) translateX(-5px);
        -ms-transform: rotate(45deg) translateX(-5px);
        transform: rotate(45deg) translateX(-5px);
    }
    .submenu-toggle:after {
        -webkit-transform: rotate(-45deg) translateX(5px);
        -ms-transform: rotate(-45deg) translateX(5px);
        transform: rotate(-45deg) translateX(5px);
    }
    .menu-item-submenu-open > .submenu-toggle:before,
    .menu-item-submenu-open > .submenu-toggle:after {
        top: 15px;
    }
    .menu-item-submenu-open > .submenu-toggle:before {
        -webkit-transform: rotate(-45deg) translateX(-5px);
        -ms-transform: rotate(-45deg) translateX(-5px);
        transform: rotate(-45deg) translateX(-5px);
    }
    .menu-item-submenu-open > .submenu-toggle:after {
        -webkit-transform: rotate(45deg) translateX(5px);
        -ms-transform: rotate(45deg) translateX(5px);
        transform: rotate(45deg) translateX(5px);
    }

    /* ---- Sub-navigation ---- */
    #frankenmenu-subnav-list {
        display: none;
    }

    /* ---- Menu positions ---- */

    /* Left */
    .frankenmenu-mobile-pos-left #frankenmenu {
        left: 0;
    }
    .frankenmenu-mobile-pos-left #frankenmenu .container {
        -webkit-transform: translate3d(-10%, 0, 0) scale(0.9);
        transform: translate3d(-10%, 0, 0) scale(0.9);
    }
    .frankenmenu-mobile-pos-left.frankenmenu-mob-menu-visible #wrap {
        -webkit-transform: translate3d(70%, 0, 0);
        transform: translate3d(70%, 0, 0);
    }

    /* Right */
    .frankenmenu-mobile-pos-right #frankenmenu {
        right: 0;
    }
    .frankenmenu-mobile-pos-right #frankenmenu .container {
        -webkit-transform: translate3d(10%, 0, 0) scale(0.9);
        transform: translate3d(10%, 0, 0) scale(0.9);
    }
    .frankenmenu-mobile-pos-right.frankenmenu-mob-menu-visible #wrap {
        -webkit-transform: translate3d(-70%, 0, 0);
        transform: translate3d(-70%, 0, 0);
    }
    .frankenmenu-mobile-pos-right #mobile-menu-button {
        float: right;
    }

    /* ---- Transitions ---- */
    #frankenmenu {
        transition:
            left var(--menu-transition),
            right var(--menu-transition);
    }
    #frankenmenu .container {
        transition:
            transform var(--menu-transition),
            opacity var(--menu-transition);
    }
    .js #frankenmenu {
        opacity: 0;
    }

    /* Menu open */
    .frankenmenu-mob-menu-visible #frankenmenu {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    .frankenmenu-mob-menu-visible #frankenmenu .container {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0) scale(1);
        transform: translate3d(0, 0, 0) scale(1);
    }

    /* ---- Moved elements ---- */
    .frankenmove {
        display: none;
    }

    /* ---- Megamenu ---- */
    .mega-menu-preview {
        display: none;
    }
    .mega-menu-preview-image {
        background-image: none !important;
    }
    .mega-menu-column-title {
        color: $color-white;
        font-size: 1.6rem;
        padding: 3px var(--body-font-size);
        margin: 0;
        background: rgba(0,0,0,0.3);
    }
    #frankenmenu-list .mega-menu {
        display: none;
    }
    #frankenmenu-list .mega-menu ul {
        display: block;
    }

    /* ---- Specialty Menu Items ---- */
    #frankenmenu-list .menu-item.menu-login a {
        background-color: $color-accent-02;
        color: $color-primary;
        @include button-typography;
        display: inline-grid;
        grid-auto-flow: column;
        column-gap: 0.5em;
        align-items: center;
        justify-content: start;

        svg {
            height: 1em;
            width: 1em;

            @include svg-contents {
                fill: currentColor;
            }
        }
    }

}
