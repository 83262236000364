:root {
    --field-default-border: 1px solid #{$color-grey-02};
    --field-default-background: #{$color-grey-01};

    --field-padding-vertical: 0.8em;
    --field-padding-horizontal: 0.88888em;

    --field-padding-vertical-sm: 0.5em;
    --field-padding-horizontal-sm: 0.8em;

    --field-padding-vertical-xsm: 0.3em;
    --field-padding-horizontal-xsm: 0.75em;
}

@mixin form-textbox-styles {
    border: var(--field-default-border);
    background-color: var(--field-default-background);
}

@mixin form-textbox($size: 'default') {
    display: block;
    width: 100%;
    outline: none;
    -webkit-appearance: none;
    line-height: var(--body-line-height);

    @if $size == 'default' {
        padding: calc(var(--field-padding-vertical) * 1.25) var(--field-padding-horizontal) calc(var(--field-padding-vertical) * 0.75);
    }

    @if $size == 'small' {
        font-size: var(--centi-font-size);
        padding: var(--field-padding-vertical-sm) var(--field-padding-horizontal-sm);
    }

    @if $size == 'xsmall' {
        font-size: var(--milli-font-size);
        padding: var(--field-padding-vertical-xsm) var(--field-padding-horizontal-xsm);
    }
}

@mixin field-label {
    display: inline-block;
    font-weight: $fw-bold;
    line-height: normal;
    font-family: $primary-font;
    padding: 2px 20px 2px 12px;
    background-color: $color-grey-001;
    color: $color-body-font;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    position: relative;
    z-index: 1;
    margin-right: 4%;
}

@mixin field-message($type: 'error') {
    margin: 0 0 $spacing;
    @include site-utility-message;

    @if $type == 'error' {
        background: $color-utility-alert-subtle;
    }

    @if $type == 'warning' {
        background: $color-utility-warning-subtle;
    }

    @if $type == 'confirm' {
        background: $color-utility-positive-subtle;
    }

    a {
        color: inherit;
    }
}

// Fieldsets
@mixin fieldsetLabel {
    padding-left: $form-spacing*4;
    position: relative;
    cursor: pointer;
    display: inline-block;
}

@mixin fieldsetInput($small: false) {
    display: inline-block;
    position: relative;
    border-radius: $standard-radius;
    @include form-textbox-styles;

    @if $small {
        width: var(--input-small-diameter);
        height: var(--input-small-diameter);
    }
}

@mixin fieldsetInputCheckboxChecked($small: false) {
    @include fieldsetInput($small);
    background-image: url(../assets/icon-system/icon_form_tick-white.svg);
    background-color: $color-utility-neutral;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 90%;

    @if $small {
        background-image: url(../assets/icon-system/icon_form_tick-thick-white.svg);
    }
}

@mixin fieldsetInputRadioChecked($small: false) {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: calc(var(--input-small-diameter) - 1em);
    height: calc(var(--input-small-diameter) - 1em);
    border-radius: 100%;
    background: $color-white;

    @if $small {
        width: calc(var(--input-small-diameter) - 0.75em);
        height: calc(var(--input-small-diameter) - 0.75em);
    }
}
