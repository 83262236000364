.widget-ChildrenGallery {
    .children-gallery-list {
        list-style-type: none;
        margin: 20px -10px 10px;
        padding: 0;
        display: flex;
        flex-wrap: wrap;

        .children-gallery-list-item {
            position: relative;
            margin: 0 10px 10px;
            flex: 1 1 auto;
            width: 100%;
            flex-basis: 100%;
            max-width: 100%;

            &__img-wrap {
                overflow: hidden;
                max-height: 260px;
                aspect-ratio: 16 / 9;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }
        }

        &--3,
        &--4,
        &--5 {
            .children-gallery-list-item {
                width: 50%;
                flex-basis: 50%;
                max-width: 50%;
            }
        }

        @include screen-560 {
            &--3,
            &--5 {
                .children-gallery-list-item {
                    width: 33%;
                    flex-basis: 33%;
                    max-width: 33%;
                }
            }
        }

        @include screen-768 {
            &--4 {
                .children-gallery-list-item {
                    width: 25%;
                    flex-basis: 25%;
                    max-width: 25%;
                }
            }
        }

        @include screen-992 {
            &--5 {
                .children-gallery-list-item {
                    width: 25%;
                    flex-basis: 25%;
                    max-width: 25%;
                }
            }
        }
    }

    .children-gallery-list-item-link {
        display: block;
        text-decoration: none;
        background-color: $color-alternative;
        color: $color-white;
        font-weight: $fw-bold;
        height: 100%;

        &:hover,
        &:focus,
        &:active {
            background-color: $color-primary;

            .children-gallery-list-item-image-placeholder:after {
                background-color: $color-primary;
            }
        }
    }

    .children-gallery-list-item-image-placeholder {
        padding-top: 86.666666667%;
        position: relative;
        background: url(../images/qaams-artwork-borderless.jpg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        opacity: 0.5;
        transition:
            opacity $link-transition,
            background-color $link-transition;

        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            mix-blend-mode: color;
            background-color: $color-alternative;
            transition:
                opacity $link-transition,
                background-color $link-transition;
        }
    }

    .children-gallery-list-item-link:hover .children-gallery-list-item-image {
        opacity: 0.9;
    }

    .children-gallery-list-item-title {
        padding: 0.75em $spacing*2.5;
        margin-bottom: 0;
        line-height: 1.15;
    }

    .children-gallery-list-item-anchor {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    @supports (display: grid) {
        .children-gallery-list {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
            grid-gap: $spacing;
            justify-items: center;
            margin-left: 0;
            margin-right: 0;

            @include screen-480 {
                grid-gap: $spacing*2;
                grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
            }

            .children-gallery-list-item {
                width: 100%;
                max-width: 100%;
                margin: 0;
            }
        }

        @include screen-768 {
            .children-gallery-list--3 {
                grid-template-columns: repeat(3, 1fr);
            }
        }

        @include screen-768 {
            .children-gallery-list--2 {
                grid-template-columns: repeat(2, 1fr);
            }

            .children-gallery-list-item-title {
                font-size: 2.2rem;
            }
        }

        @include screen-1200 {
            .children-gallery-list--4 {
                grid-template-columns: repeat(4, 1fr);
            }
            .children-gallery-list--5 {
                grid-template-columns: repeat(5, 1fr);
            }

        }

    }
}
