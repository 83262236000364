/* ------------------------------------------------------
**** ATOMS / SCSS Inclusions
------------------------------------------------------ */


/* ---- Global Variables ---- */

@import "06-abstracts/_breakpoints";

@import "06-abstracts/colors";

@import "06-abstracts/fonts";

@import "06-abstracts/utilities";

@import "06-abstracts/animation";

@import "06-abstracts/decorative-mixins";


/* ---- Mixins ---- */

@import "00-base/buttons-and-links/button-mixins";

// @import "00-base/box/box-mixins";

@import "00-base/helpers/utility-mixins";

@import "00-base/section/section-mixins";

@import "00-base/typography/reverse-text-mixins";

@import "00-base/typography/typography-mixins";

@import "01-components/forms/form-mixins";

@import "01-components/text-blocks/tooltip-mixins";



/* ---- Base HTML ---- */


@import "00-base/_base/viewport";

@import "00-base/_base/box-model-reset";

@import "00-base/_base/page";

@import "00-base/_base/font-face";

@import "00-base/_flexboxgrid-extensions/_flexboxgrid-extensions";

@import "00-base/images/img";

@import "00-base/container/container";

@import "00-base/wrap/wrap";

@import "00-base/section/background-colours";

@import "00-base/section/section";

@import "00-base/section/wedge-section";

@import "00-base/block/block";

@import "00-base/box/box";

@import "00-base/content-align/content-align";

@import "00-base/floats/floats";




/* ---- Text ---- */

@import "00-base/typography/headings";

@import "00-base/typography/paragraph";

@import "00-base/typography/selected-text";

@import "00-base/typography/inline-elements";

@import "00-base/typography/hr";

@import "00-base/typography/lists/lists";

@import "00-base/typography/lists/inline-list";



/* ---- Buttons and links ---- */



@import "00-base/buttons-and-links/text-link";

@import "00-base/buttons-and-links/button";





/* ---- Images and Icons ---- */


@import "00-base/icons/icons";




/* ---- Video ---- */


@import "00-base/video-embed";



/* ---- Tables ---- */


@import "00-base/tables/table";

@import "00-base/tables/content-table/content-table";

@import "00-base/tables/small-content-table/small-content-table";

@import "00-base/tables/responsive-table/responsive-table";






/* ---- Helpers ---- */


@import "00-base/helpers/clearfix";

@import "00-base/helpers/img-replace";

@import "00-base/helpers/visibility";

@import "00-base/helpers/_pseudo-element-content";



/* ------------------------------------------------------
**** MOLECULES
------------------------------------------------------ */

/* ---- Text ---- */

@import "01-components/text-blocks/blockquote";

@import "01-components/text-blocks/highlight";

@import "01-components/text-blocks/expando";

@import "01-components/text-blocks/site-messages";

@import "01-components/text-blocks/attention-pill";

@import "01-components/text-blocks/tooltip";



/* ---- Lists ---- */


@import "01-components/lists/sidebar-linklist";

@import "01-components/lists/social-list";

@import "01-components/lists/children-pg-list/children-pg-list";

@import "01-components/lists/file-list";

@import "01-components/lists/block-list/block-list";

@import "01-components/lists/icon-lists";



/* ---- Navigation ---- */


@import "01-components/navigation/breadcrumb";

@import "01-components/navigation/pagination";

@import "01-components/navigation/primary-nav/_primary-nav";

@import "01-components/navigation/related-links";



/* ---- Plugins / third-party styles ---- */

@import "01-components/magnific-popup/magnific-popup";



/* ---- Blocks ---- */


@import "01-components/date-card/date-card";

@import "01-components/tile/tile";

@import "01-components/hero-banner/hero-banner";

@import "01-components/image-galleries/image-gallery";

@import "01-components/media-objects/media-object";

@import "01-components/media-objects/card";

@import "01-components/media-objects/card-lnd-hvr";

@import "01-components/image-slider/slick-slider/image-slider";

@import "01-components/image-slider/slick-slider/image-slider-theme";

@import "01-components/image-slider/sans-slick/image-slider-clean";

@import "01-components/testing-machines/testing-machines";

@import "01-components/calendar-item/calendar-item";

@import "01-components/article/article";

@import "01-components/program-partners/program-partners";

@import "01-components/widget/widget";





/* ---- Forms ---- */


@import "01-components/forms/form-base";

@import "01-components/forms/color-picker";

@import "01-components/forms/date-input";

@import "01-components/forms/date-picker";

@import "01-components/forms/date-time-input";

@import "01-components/forms/fieldsets";

@import "01-components/forms/money";

@import "01-components/forms/range";

@import "01-components/forms/select";

@import "01-components/forms/range";

@import "01-components/forms/text-field";

@import "01-components/forms/time-pickers";

@import "01-components/forms/total-selector";

@import "01-components/forms/upload";

@import "01-components/forms/submit-bar";

@import "01-components/forms/submit-button";

@import "01-components/forms/site-search";

@import "01-components/forms/captcha";

@import "01-components/forms/clearable-field";

@import "01-components/forms/upload/upload--inline";


/* ------------------------------------------------------
**** LAYOUTS
------------------------------------------------------ */

/* ---- Global ---- */

@import "02-layout/header/header";

@import "02-layout/footer/footer";

@import "02-layout/forms/login-form";

@import "02-layout/sidebar/sidebar";

@import "02-layout/lists/tile-list";

@import "02-layout/lists/top-heavy-list";

@import "02-layout/lists/media-object_list";

@import "02-layout/lists/card_list";

@import "02-layout/lists/card-lnd-hvr-list";

@import "02-layout/lists/video-list";



/* ------------------------------------------------------
**** MODULES
------------------------------------------------------ */

/*
 * NOTE: [modules:01]
 * DO NOT PUT core module css here
 * the following are for bespoke skin styles that are ADDITIONAL to core module css
 */

@import "03-modules/tests/test-utilities";


/* ----- Team members ----- */

@import "03-modules/team-members/_team-members-list";

/* ----- Publications ----- */

@import "03-modules/publications/_publications-list";



/* ----- Sponsors ----- */

@import "03-modules/sponsors/_sponsor_list.scss";

@import "03-modules/sponsors/_sponsors_slider.scss";

/* ------------------------------------------------------
**** PAGES
------------------------------------------------------ */

@import "04-pages/home/home";

@import "04-pages/inner/inner-scaffold";



/* ------------------------------------------------------
**** Print styles
------------------------------------------------------ */

@media print {
    * {
        background: transparent !important;
        color: #000 !important; /* Black prints faster: h5bp.com/s */
        -webkit-box-shadow: none !important;
                box-shadow: none !important;
        text-shadow: none !important;
    }

    a,
    a:visited {
        text-decoration: underline;
    }

    a[href]:after {
        content: " (" attr(href) ")";
    }

    abbr[title]:after {
        content: " (" attr(title) ")";
    }

    /* Don't show links for images, or javascript/internal links */

    .ir a:after,
    a[href^="javascript:"]:after,
    a[href^="#"]:after {
        content: "";
    }

    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }

    thead {
        display: table-header-group; /* h5bp.com/t */
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
    }

    @page {
        margin: 0.5cm;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }
}
