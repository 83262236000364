/* -----------------------------
Visibility Utilities
------------------------------*/

@mixin vis-hidden() {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;

    &.focusable:active,
    &.focusable:focus {
        clip: auto;
        height: auto;
        margin: 0;
        overflow: visible;
        position: static;
        width: auto;
    }
}

@mixin vis-hidden-reset() {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}


/* -----------------------------
Clearfix
------------------------------*/
@mixin clearfix() {
    &:before,
    &:after {
        content: " ";
        display: table;
    }
    &:after {
        clear: both;
    }
}


/* -----------------------------
SVG
------------------------------*/
@mixin svg-contents() {
    path,
    circle,
    ellipse,
    rect,
    line,
    polygon {
        @content;
    }
}


/* -----------------------------
List Mixins
------------------------------*/
@mixin list-reset() {
    list-style-type: none;
    padding-left: 0;
}


/* -----------------------------
Fallback logo image
------------------------------*/
@mixin fallback-logo-image() {
    background: $color-grey-07;
    background-image: url(#{$file-path}logo.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 50%;
}


/* -----------------------------
High visibility focus
------------------------------*/
@mixin focus {
    outline-color: indigo;
    outline-style: auto;
    outline-width: 2px;
}

/* -----------------------------
Icon Wrapper
------------------------------*/
@mixin icon-wrapper {
    border: 3px solid $color-secondary;
    border-radius: 100%;
    width: 120px;
    height: 120px;
    padding: 24px;
}


/* -----------------------------
Text Link
------------------------------*/
@mixin text-link {
    text-transform: uppercase;
    font-weight: $fw-heavy;
    text-decoration: none;

    display: inline-grid;
    grid-auto-flow: column;
    column-gap: 0.5em;
    align-items: center;

    @include svg-contents {
        fill: currentColor;
        transition: fill 250ms ease-in-out;
    }
}

/* -----------------------------
Links list link
------------------------------*/
@mixin links-list-item {
    text-decoration: none;
    letter-spacing: 0.04687em;
    text-transform: uppercase;
    font-weight: $fw-bold;
}

/* -----------------------------
Site Utility Messages
------------------------------*/
@mixin site-utility-message {
    padding: $spacing 24px;
    text-align: left;
    border-radius: 40px;
    color: $color-grey-10 !important;
    font-family: $primary-font;
    font-weight: $fw-semibold;
    font-size: 1.4rem;
    line-height: normal;
}

