.field-element--money .field-input {
    position: relative;
}

.field-element--money .field-input .money-symbol:before {
    content: "\00a4";       /* Generic currency sign */
    position: absolute;
    display: block;
    left: calc( #{$form-spacing}/4);
    top: calc(50% - 14px);
    color: $color-grey-07;
    border-right: 1px solid $color-grey-04;
    font-weight: bold;
    font-size: calc(var(--body-font-size)*1.2);
    width: $form-spacing*5;
    text-align: center;
}

.field-element--money .field-input .money-symbol--dollar:before {
    content: "$";    /* Dollars */
}

.field-element--money .field-input .money-symbol--pound:before {
    content: "\00a3";    /* Pounds */
}

.field-element--money .field-input .money-symbol--yen:before {
    content: "\00a5";    /* Yen */
}

.field-element--money .field-input .money-symbol--indian_rupee:before {
    content: "\20B9";   /* Indian rupee */
}
