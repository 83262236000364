.login-form {
    padding-top: 56px;

    @include screen-768 {
        padding-top: var(--section-small);
    }

    @include screen-992 {
        padding-top: 24px;
    }

    @include screen-1200 {
        padding-top: 16px;
    }

    @include screen-1400 {
        padding-top: 24px;
    }

    @include screen-1600 {
        padding-top: var(--section-small);
    }

    > .container {
        @include screen-1200 {
            display: grid;
            grid-template-columns: 380px 1fr;
            align-items: end;
            gap: 24px;
        }

        @include screen-1400 {
            gap: 32px;
            grid-template-columns: 2fr 5fr;
        }

        @include screen-1600 {
            padding-right: var(--container-padding);
            padding-left: var(--container-padding);
        }
    }

    .login-form__preamble {
        margin-bottom: 1em;

        @include screen-768 {
            display: flex;
            flex-direction: row;
            gap: 24px;
            margin-bottom: 0;
        }

        @include screen-1200 {
            display: block;
        }
    }

    .login-form__title {
        flex: 1 0 auto;
        margin-bottom: 12px;
        color: $color-primary;
        font-size: 3.2rem;
    }

    .attention-pill {
        flex: 1 1 auto;
        padding-right: 20px;

        @include screen-768 {
            max-width: 380px;
            display: inline-flex;
        }

        @include screen-1200 {
            display: flex;
            padding-right: 12px;
            max-width: unset;
        }
    }

    form {
        display: grid;
        grid-template-columns: 1fr;
        align-items: end;

        @media screen and (min-width: 22.75em) {
            gap: 1em;
        }

        @include screen-560 {
            grid-template-columns: 1fr 1fr;
        }

        @include screen-768 {
            grid-template-columns: 1fr 1fr auto;
        }

        @include screen-1200 {
            gap: 24px;
        }

        @include screen-1400 {
            gap: 32px;
        }

        .field-element {
            @media screen and (min-width: 22.75em) {
                margin-bottom: 0;
            }

            &--password {
                margin-bottom: 0;
            }
        }

        .field-element--checkboxboollist {
            display: none;
        }

        .submit-bar {
            position: relative;
            background: none;
            margin-top: 0;
            margin-bottom: 0;
            padding: 0;

            @include screen-560 {
                grid-column: span 2;
            }

            @include screen-768 {
                grid-column: auto;
            }

            [href*="/user/forgotten_password"] {
                font-size: var(--centi-font-size);
                margin-bottom: 24px;
                display: block;
                margin-right: 0;

                @media screen and (min-width: 22.75em) {
                    display: inline-block;
                    margin-right: 20px;
                    margin-bottom: 0;
                }

                @include screen-768 {
                    position: absolute;
                    top: calc(100% + 1em);
                    right: 0;
                    white-space: nowrap;
                    margin-right: 0;
                }

                @include screen-1200 {
                    top: 0;
                }
            }

            button[type="submit"] {
                display: block;
                width: 100%;
                background-color: $color-accent-02;
                color: $color-primary;
                @include button-width-large;
                @include button-height-large;

                @media screen and (min-width: 22.75em) {
                    display: inline-grid;
                    width: auto;
                }

                @include screen-768 {
                    margin-top: 32px;
                }

                @include screen-992 {
                    margin-top: 16px;
                }

                @include screen-1200 {
                    margin-top: 50px;
                }
            }
        }
    }
}
