:root {
    --test-type-identifier-diameter: 56px;
    --test-initial-diameter: 40px;
}

@include screen-768 {
    :root {
        --test-type-identifier-diameter: 80px;
        --test-initial-diameter: 56px;
    }
}

/* ------------------------------
Result value validation field styles
--------------------------------- */
[class*="Qaams-Widgets"] .field-label {
    border-left: 4px solid rgba($color-black, 0.15);
}

.field-element--result-action .field-label {
    border-left: 4px solid $color-utility-alert;
}

.field-element--result-warning .field-label {
    border-left: 4px solid $color-utility-warning;
}

.field-element--result-target .field-label {
    border-left: 4px solid $color-utility-positive;
}

.field-input .result-warning,
.field-input .result-target,
.field-input .result-action {
    background-repeat: no-repeat;
    background-position: center right $spacing;
    background-size: $form-spacing*4;
    padding-right: $form-spacing*5;
}

.field-input .result-warning {
    background-image: url(../images/icon-system/icon_warning.svg);
}

.field-input .result-target {
    background-image: url(../images/icon-system/icon_tick.svg);
}

.field-input .result-action {
    background-image: url(../images/icon-system/icon_cross.svg);
}

/* ----------------------------
Result value validation site messages
------------------------------ */
.test-utility-messages {
    font-size: 1.4rem;
    font-weight: $fw-semibold;
    border-bottom: 2px solid transparent;
    margin-bottom: calc(var(--paragraph-break) * 1.5);

    ul.messages {
        margin-bottom: 0;
    }

    p {
        padding: $spacing 24px;
        margin-bottom: 0.5em;
    }

    &.error {
        border-bottom-color: $color-utility-alert;

        p {
            background-image: url(../images/icon-system/icon_information.svg);
            background-repeat: no-repeat;
            background-position: center left 24px;
            background-size: 24px;
            padding-left: 56px;
        }
    }

    &.warning {
        border-bottom-color: $color-utility-warning;
    }
}

/* ----------------------------
Page header test type identifier
------------------------------ */
.test-type-identifier {
    position: absolute;
    bottom: calc(var(--test-type-identifier-diameter) / -2);
    right: var(--default-container-gutter);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--test-type-identifier-diameter);
    height: var(--test-type-identifier-diameter);
    width: var(--test-type-identifier-diameter);
    z-index: 1;

    &:before {
        content: attr(test-type);
        display: block;
        font-family: $accent-font;
        font-weight: $fw-bold;
        font-size: 3rem;
    }

    &[test-type="QC"] {
        background-color: $color-alternative;
        color: $color-white;
    }

    &[test-type="QA"] {
        background-color: $color-accent-01;
        color: $color-white;
    }
}

/* ----------------------------
Test form section header
------------------------------ */
.test-header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: calc(var(--paragraph-break) * 2);
    margin-bottom: var(--paragraph-break);

    @include screen-360() {
        align-items: baseline;
    }

    @include screen-560 {
        align-items: flex-end;
    }

    [data-test-lot] {
        margin-bottom: 0;
        flex: 0 0 auto;

        @media screen and (max-width: $bp359) {
            font-weight: $fw-bold;
            opacity: 0.5;
            flex-basis: 100%;
            padding-left: calc(var(--test-initial-diameter) + 0.66em);
            margin-top: -0.8em;
        }

        @include screen-360() {
            font-size: 1.3rem;
        }

        @include screen-560 {
            font-size: 1em;
        }
    }
}

.test-heading {
    flex: 1 1 auto;
    font-family: $primary-font;
    font-weight: $fw-heavy;
    margin-bottom: 0;

    @media screen and (max-width: $bp559) {
        font-size: 1.25em;
    }

    &--grid {
        display: grid;
        grid-auto-flow: column;
        column-gap: 0.5em;
        justify-content: start;
        align-items: center;
    }

    [data-test-initial] {
        display: inline-grid;
        grid-auto-flow: column;
        column-gap: 0.5em;
        justify-content: start;
        align-items: center;

        &:before {
            content: attr(data-test-initial);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: var(--test-initial-diameter);
            height: var(--test-initial-diameter);
            width: var(--test-initial-diameter);
            text-transform: uppercase;
        }
    }

    [data-test-initial="U"]:before {
        background-color: $color-accent-02;
        color: $color-white;
    }

    [data-test-initial="H"]:before {
        background-color: $color-secondary;
        color: $color-white;
    }

    [data-test-indicator] {
        display: inline-grid;
        grid-auto-flow: column;
        column-gap: 0.25em;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;

        &:before {
            content: "";
            background: center center / auto 100% no-repeat transparent;
            height: 1.25em;
            width: 1.25em;
        }
    }

    [data-test-indicator="low"]:before {
        background-image: url(../images/icon_low.svg);
    }

    [data-test-indicator="high"]:before {
        background-image: url(../images/icon_high.svg);
    }

    &__light {
        font-weight: $fw-light;
    }
}

.test-subheading {
    margin-top: 0.25em;
}

/* ------------------------------
Use visual identifiers for the field type.
--------------------------------- */
.field-element--albumin input,
.field-element--creatinine input,
.field-element--normal input,
.field-element--abnormal input {
    background-repeat: no-repeat;
    background-position: center right $spacing;
    background-size: $form-spacing*4;
    padding-right: $form-spacing*5;
}

.field-element--albumin input {
    background-image: url(../images/icon_albumin.svg);
}

.field-element--creatinine input {
    background-image: url(../images/icon_creatinine.svg);
}

.field-element--albumin input {
    background-image: url(../images/icon_albumin.svg);
}

.field-element--normal input {
    background-image: url(../images/icon_normal.svg);
}

.field-element--abnormal input {
    background-image: url(../images/icon_abnormal.svg);
}

