/* time picker */
.field-element--timepicker .field-input {
    position: relative;
}

.field-element--timepicker .field-input:before {
    content: "\00a0";
    display: block;
    position: absolute;
    left: calc( #{$form-spacing}/4);
    top: calc(50% - 12px);
    width: $form-spacing*5;
    background-image: url(../images/icon-system/icon_form_timepicker.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    border-right: 1px solid $color-grey-04;
}

/* Timepicker modal */
.ui-timepicker {
    display: block;
    position: absolute;
    height: 200px;
    overflow: auto;
    z-index: 9999 !important;
}

.ui-timepicker-standard {
    background-color: $color-white;
    border: 1px solid $color-grey-04;
    display: block;
    margin: $form-spacing*0.5 0 0;
    padding: $form-spacing*0.75;
    list-style: none outside none;
    border-radius: $standard-radius;
}

.ui-timepicker-standard a {
    color: $color-grey-09;
}

.ui-timepicker-standard .ui-state-hover {
    background-color: $color-grey-02;
}

.ui-timepicker-standard .ui-menu-item {
    clear: left;
    float: left;
    margin: 0;
    padding: 0;
    width: 100%;
}

.ui-timepicker-standard .ui-menu-item a {
    display: block;
    padding: $form-spacing*0.75 $form-spacing*1.5;
    line-height: 1.5;
    text-decoration: none;
    border-radius: $standard-radius;
}

.ui-timepicker-hidden {
    display: none;
}
