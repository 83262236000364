/* fieldset styles */
fieldset {
   border: none;
   padding: 0;
   margin: 0;
}

.field-element--checkboxlist--columns .field-element__input-set {
    columns: 3;
}

.field-element__input-set {
    @include form-textbox;
    border: var(--field-default-border);
    border-radius: $standard-radius;
}

/* Hide checkbox and radio field element) */
input[type="checkbox"],
input[type="radio"] {
    @include vis-hidden;
}

input[type="checkbox"] + label,
input[type="radio"] + label,
.field-element--small input[type="checkbox"] + label,
.field-element--small input[type="radio"] + label {
    padding-left: $form-spacing*4;
    position: relative;
    cursor: pointer;
    display: inline-block;
}

/* -- Field set pseudo elements -- */
input[type="checkbox"] + label:before,
input[type="radio"] + label:before,
.field-element--small input[type="checkbox"] + label:before,
.field-element--small input[type="radio"] + label:before {
    content: " ";
    display: inline-block;
    width: $form-spacing*3;
    height: $form-spacing*3;
    margin-right: $form-spacing*1.5;
    position: relative;
    top: calc( #{$form-spacing}/2);
    border: var(--field-default-border);
    background-color: var(--field-default-background);
    border-radius: 2px;
    margin-left: -$form-spacing*4;
}

/* Radio button (pseudo element) */
input[type="radio"] + label:before,
.field-element--small input[type="radio"] + label:before {
    border-radius: $form-spacing*1.5;
}

/* -- Field set pseudo elements : checked -- */
input[type="checkbox"]:checked + label:after,
input[type="radio"]:checked + label:after,
.field-element--small input[type="checkbox"]:checked + label:after,
.field-element--small input[type="radio"]:checked + label:after {
    content: " ";
    position: absolute;
    left: 1px;
    top: $form-spacing*0.75;
}
/* Checkbox box checked (pseudo element) */
input[type="checkbox"]:checked + label:before,
.field-element--small input[type="checkbox"]:checked + label:before {
    background-color: $color-primary;
    border-color: $color-primary;
}

input[type="checkbox"]:checked + label:after,
.field-element--small input[type="checkbox"]:checked + label:after {
   content: "\00a0";
   background-image: url(../images/icon-system/icon_form_tick-md-white.svg);
   background-position: left top;
   background-repeat: no-repeat;
   background-size: 90%;
   width: $form-spacing*3;
   height: $form-spacing*3;
}

/* Radio dot (pseudo element) */
input[type="radio"]:checked + label:before,
.field-element--small input[type="radio"]:checked + label:before {
    background-color: $color-primary;
    border-color: $color-primary;
}

input[type="radio"]:checked + label:after,
.field-element--small input[type="radio"]:checked + label:after {
    left: $form-spacing - 1;
    top: $form-spacing *1.5 - 1;
    width: $form-spacing*1.2;
    height: $form-spacing*1.2;
    border-radius: $form-spacing*0.6;
    background: $color-white;
}

/* White */
.field-element--white input[type="checkbox"] + label:before,
.field-element--white input[type="radio"] + label:before {
    background-color: $color-white;
}

/* input focus state */
.fieldset--multiradio:focus-within {
    @include focus;
}
input[type="checkbox"]:focus + label {
    @include focus;
    outline-offset: -3px;
}
