@mixin section-title {
    color: $color-primary;
    font-weight: $fw-medium;
    font-size: var(--h1-font-size);
    line-height: 1.05;
    margin-bottom: 1em;

    @include screen-768 {
        font-size: 4.8rem;
    }

    @include screen-1400 {
        font-size: 5.6rem;
    }
}

@mixin subtitle($colour: $color-grey-05, $size: 'regular') {
    display: block;
    color: $colour;
    margin-bottom: 0;
    text-transform: uppercase;
    font-weight: $fw-bold;

    @if $size == 'regular' {
        font-size: var(--body-font-size);
    }

    @if $size == 'small' {
        font-size: var(--milli-font-size);
    }
}


/* ----------------------------
Default titles
------------------------------- */

@mixin h1($colour: inherit) {
    font-size: var(--h1-font-size);
    margin-bottom: calc(0.2em + var(--paragraph-break));

    @if $colour {
        color: $colour;
    }
}

@mixin h2($colour: inherit) {
    font-size: var(--h2-font-size);
    margin-bottom: calc(0.2em + var(--paragraph-break));

    @if $colour {
        color: $colour;
    }
}

@mixin h3($colour: inherit) {
    font-size: var(--h3-font-size);

    @if $colour {
        color: $colour;
    }
}

@mixin h4($colour: inherit) {
    font-size: var(--h4-font-size);

    @if $colour {
        color: $colour;
    }
}

@mixin h5($colour: inherit) {
    font-size: var(--h5-font-size);

    @if $colour {
        color: $colour;
    }
}

@mixin h6($colour: inherit) {
    font-size: var(--h6-font-size);

    @if $colour {
        color: $colour;
    }
}


/* ----------------------------
Two line indented text
------------------------------- */

@mixin title-duo-line {
    font-size: var(--mega-font-size);
    line-height: normal;
    color: $color-grey-07;

    @include screen-768 {
        padding-left: 1.25em;
    }

    span {
        display: block;

        &:first-child {
            font-weight: $fw-semibold;
        }

        &:last-child {
            font-weight: $fw-light;

            @include screen-768 {
                padding-left: 0.828125em;
            }
        }
    }
}

/* ----------------------------
Subtitle with Title
------------------------------- */
@mixin title-avec-subtitle {
    font-size: var(--mega-font-size);
    line-height: normal;
    color: $color-grey-07;

    @include screen-768 {
        padding-left: 1.25em;
    }

    span {
        display: block;

        &:first-child {
            @include subtitle;
        }

        &:last-child {
            font-weight: $fw-semibold;
        }
    }

}
