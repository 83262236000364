
.messages,
ul.messages {
    list-style: none;
    margin: 1em 0;
    padding: 0;

    li {
        margin: 0 0 0.5em;
        @include site-utility-message;
    }

    .error {
        background: $color-utility-alert-subtle;
    }

    .confirm {
        background: $color-utility-positive-subtle;
    }

    .warning {
        background: $color-utility-warning-subtle;
    }

    a {
        color: #FFF;
    }
}
