:root {
    --banner-height: 400px;
}

@include screen-768 {
    :root {
        --banner-height: 50vw;
    }
}

@include screen-992 {
    :root {
        --banner-height: 32.875vw;
    }
}

@include screen-1920 {
    :root {
        --banner-height: 631px;
    }
}


.hero-banner {
    margin-top: calc(2.333333vw + 32px);
    position: relative;
    background: $color-secondary;
    color: $color-white;

    @include screen-2400 {
        margin-top: calc((#{$max-page-width} * 0.02333333) + 32px);
    }

    &:before,
    &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        z-index: 1;
        background-repeat: no-repeat;
    }

    &:before {
        top: -0.5px;
        height: 0.75vw;
        background-image: url(../images/top-n-tails/banner-negative-curve-top.svg);
        background-size: cover;
        background-position: top;
        background-size: 100%;

        @include screen-2400 {
            width: $max-page-width;
            height: calc(0.0075 * #{$max-page-width});
        }
    }

    &:after {
        bottom: -0.5px;
        height: 0.8333333333vw;
        background-image: url(../images/top-n-tails/banner-negative-curve-bottom.svg);
        background-size: cover;
        background-position: center bottom;

        @include screen-2400 {
            width: $max-page-width;
            height: calc(0.008333333333 * #{$max-page-width});
        }
    }

    &__inner {
        position: relative;
        height: var(--banner-height);
        z-index: 0;
        overflow: hidden;

        &:before,
        &:after {
            content: "";
            position: absolute;
            border-radius: 100%;
            height: calc(var(--banner-height) * 1.9790874525);
            width: calc(var(--banner-height) * 1.9790874525);
            top: calc(var(--banner-height) * -0.1920152091);
            right: calc(50vw + (50vw * 0.1525));

            @include screen-2400 {
                right: calc((#{$max-page-width}/2) + ((#{$max-page-width}/2) * 0.1525));
            }
        }

        &:before {
            background: url(../images/qaams-artwork.jpg);
            background-position: 50% 60%;
            background-size: 102%;
            background-repeat: no-repeat;
            opacity: 0.5;
        }

        &:after {
            mix-blend-mode: color;
            background-color: #CF454A;
        }

        &__circle {
            &:before,
            &:after {
                content: "";
                position: absolute;
                border-radius: 100%;
                height: calc(var(--banner-height) * 1.9790874525);
                width: calc(var(--banner-height) * 1.9790874525);
                bottom: calc(var(--banner-height) * 0.4486692015);
                left: calc(50vw + (50vw * 0.59625));

                @include screen-2400 {
                    left: calc((#{$max-page-width}/2) + ((#{$max-page-width}/2) * 0.59625));
                }
            }

            &:before {
                background: url(../images/qaams-artwork.jpg);
                background-position: 0 0;
                background-size: 100%;
                background-repeat: no-repeat;
                opacity: 0.5;
            }

            &:after {
                mix-blend-mode: color;
                background-color: #CF454A;
            }
        }
    }
}

.hero-banner__img {
    position: absolute;
    border-radius: 100%;
    z-index: 2;
    right: calc(40vw + (50vw * 0.09));
    top: calc(var(--banner-height) * 0.45);
    width: calc(var(--banner-height) * 0.66);
    height: calc(var(--banner-height) * 0.66);

    @include screen-560 {
        top: calc(var(--banner-height) * 0.3);
        right: calc(50vw + (50vw * 0.09));
        width: calc(var(--banner-height) * 0.75);
        height: calc(var(--banner-height) * 0.75);
    }

    @include screen-768 {
        top: calc(var(--banner-height) * 0.1102661597);
        right: calc(50vw + (50vw * 0.09));
        width: calc(var(--banner-height) * 0.9467680608);
        height: calc(var(--banner-height) * 0.9467680608);
    }

    @include screen-2400 {
        right: calc((#{$max-page-width}/2) + ((#{$max-page-width}/2) * 0.09));
    }
}

.hero-banner__heading {
    font-family: $accent-font;
    font-size: 3.6rem;
    max-width: fit-content;
    line-height: 1;
    position: absolute;
    z-index: 1;
    top: 56px;
    left: auto;
    right: var(--default-container-gutter);

    @include screen-360 {
        font-size: var(--mega-font-size);
    }

    @include screen-560 {
        top: var(--section-large);
        right: auto;
        left: calc(50vw * 0.8);
    }

    @media screen and (min-width: 39em) { /* 624px */
        left: calc(50vw * 0.875);
    }

    @include screen-768 {
        top: calc(var(--banner-height) * 0.2243346008);
        left: calc(50vw * 0.975);
    }

    @include screen-2400 {
        left: calc((#{$max-page-width}/2) * 0.975);
    }

    .line {
        display: block;

        &.line--01,
        &.line--04 {
            font-size: 0.5714285714em;
            display: block;
        }

        &.line--01 {
            margin-bottom: 0.25em;
        }

        &.line--04 {
            text-align: right;
            margin-top: 0.2em;
        }

        &.line--03 {
            font-weight: $fw-bold;
        }

        &.line--02 {
            display: flex;

            span:after {
                content: "\00a0";
            }

            > span:first-child {
                display: block;
                line-height: 1;
                align-self: flex-start;
                font-size: 0.4285714286em;
                padding-left: 1.1111111111em;
            }

            > span:nth-child(2) {
                font-weight: $fw-bold;
            }

            > span:last-child {
                font-weight: $fw-normal;
            }
        }
    }
}
