
.left,
.center,
.right {
    margin: 30px auto;
    display: block;
    clear: both;
}


@media screen and (max-width: $bp559) {
    .mainbar {
        img {
            &.left,
            &.center,
            &.right {
                // width: 100%;
            }
        }

    }
    .highlight > p:first-child img {
        &.left,
        &.center,
        &.right {
            margin-top: 0;
        }
    }
}


/* BP Smaller */
@media screen and (min-width:  $bp560) {
    .left {
        margin: 0 30px 30px 0;
        float: left;
    }

    .right {
        margin: 0 0 30px 30px;
        float: right;
    }

    .mainbar {
        img {
            &.left,
            &.right {
                // width: unset;
                max-width: calc(50% - 30px);
            }
        }
    }
}
