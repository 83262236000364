.section--program-partners {
    padding-top: calc(var(--section-small) * 1.5);
    padding-bottom: var(--section-large);
}

.program-partners {
    @include screen-1200 {
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: end;
    }

    &__title {
        @include section-title;
        text-align: center;

        @include screen-560 {
            text-align: left;
        }

        @include screen-1200 {
            margin-bottom: 0;
        }
    }

    &__list {
        padding-left: 80px;
        padding-right: 80px;

        @include screen-560 {
            padding-left: 0;
            padding-right: 0;

            display: grid;
            grid-template-columns: repeat(3, auto);
        }
    }

    .partner-item {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding-top: 24px;
        padding-bottom: 24px;

        @include screen-560 {
            padding-top: 0;
            padding-bottom: 0;
        }

        @include screen-1200 {
            padding-left: calc(var(--default-container) * 0.026666667);
            padding-right: calc(var(--default-container) * 0.026666667);
        }

        @include screen-1400 {
            padding-left: calc(var(--default-container) * 0.04166666667);
            padding-right: calc(var(--default-container) * 0.04166666667);
        }

        &:after {
            content: "";
            position: absolute;
            height: 8px;
            left: 0;
            right: 0;
            bottom: -4px;
            background-image: url(../images/decal_lined-separator_horizontal.svg);
            background-repeat: repeat-x;
            background-size: auto 100%;

            @include screen-560 {
                width: 8px;
                height: auto;
                top: 0;
                bottom: 0;
                left: auto;
                right: -4px;
                background-image: url(../images/decal_lined-separator_vertical.svg);
                background-repeat: repeat-y;
                background-size: 100% auto;
            }
        }

        &:first-child {
            padding-top: 0;
            padding-left: 0;
        }

        &:last-child {
            padding-bottom: 0;
            padding-right: 0;

            &:after {
                content: none;
            }
        }

        svg {
            @include screen-560 {
                height: calc(var(--default-container) * 0.125);
            }

            @include screen-992 {
                height: auto;
            }
        }

        /* svg ratio sizing */

        &:first-child {
            svg {
                @include screen-560 {
                    width: calc((var(--default-container) * 0.125) * 1.48);
                }

                @include screen-992 {
                    width: auto;
                }
            }
        }

        &[title*="Flinders"] {
            svg {
                @include screen-560 {
                    width: calc((var(--default-container) * 0.125) * 2.26);
                }

                @include screen-992 {
                    width: auto;
                }
            }
        }

        &:last-child {
            svg {
                @include screen-560 {
                    width: calc((var(--default-container) * 0.125) * 2.54);
                }

                @include screen-992 {
                    width: auto;
                }
            }
        }
    }
}
