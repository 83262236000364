:root {
--image-circle-height: calc(var(--default-container));
}

@include screen-560 {
    :root {
        --image-circle-height: calc((var(--default-container) - 24px) / 2);
    }
}

@include screen-768 {
    :root {
        --image-circle-height: calc(((var(--default-container) - (var(--container-padding)*2)) - 24px) / 2);
    }
}

@include screen-992 {
    :root {
        --image-circle-height: calc(((((var(--default-container) - (var(--container-padding)*2)) - 72px) / 2) - 24px) / 2);
    }
}

.testing-machines {
    &__container {
        @include screen-992 {
            padding-left: 0;
            padding-right: 0;
        }

        @include screen-1200 {
            padding-left: var(--container-padding);
            padding-right: var(--container-padding);
        }
    }

    &__columns {
        display: grid;
        grid-template-columns: 1fr;
        gap: 56px;

        @include screen-992 {
            grid-template-columns: 1fr 1fr;
            gap: 72px;
        }
    }

    &__item {
        @include screen-560 {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 24px;
            align-items: center;
        }

        @include screen-992 {
            align-items: end;
        }

        &__picture {
            position: relative;
            display: block;
            width: 100%;

            &__image {
                display: block;
                border-radius: 100%;
                background-color: $color-accent-01;
                width: 100%;
                height: var(--image-circle-height);
                box-shadow: 0 2px 4px rgba($color-black, 15%);

                @supports(aspect-ratio: 1) {
                    aspect-ratio: 1;
                    height: auto;
                }
            }

            &__bubble {
                position: absolute;
                bottom: 3.1415926535%;
                right: 3.1415926535%;
                display: flex;
                justify-content: center;
                align-items: center;
                width: max(60px, calc(var(--image-circle-height) * 0.2068965517));
                height: max(60px, calc(var(--image-circle-height) * 0.2068965517));
                border-radius: 60px;
                background-color: $color-alternative;
                color: $color-white;
                text-transform: uppercase;
                margin: 0;
                font-weight: $fw-heavy;
            }
        }

        &__heading {
            font-size: var(--h3-font-size);

            @include screen-768 {
                font-size: var(--h2-font-size);
            }

            @include screen-1400 {
                font-size: 4rem;
            }
        }

        &__description {
            color: $color-grey-06;

            @include screen-560 {
                font-size: 2rem;
            }

            @include screen-992 {
                font-size: var(--body-font-size);
            }

            @include screen-1400 {
                font-size: 2.4rem;
            }
        }

        &__cta {
            @include screen-992 {
                padding-left: 1.5em;
                padding-right: 1.5em;
            }

            @include screen-1200 {
                @include button-width-regular;
            }
        }
    }
}


.testing-machines {
    &--dashboard {
    }
    &--dashboard .wedge-section__content {
        padding-bottom: $spacing*8;
        // better spacing for the dashboard without affecting logout page
        margin-bottom: -$spacing*4;
    }
    &--dashboard .wedge-section__content {
        padding-bottom: $spacing*6;
    }

    &__columns--one-item {
        grid-template-columns: unset;
        max-width: 580px;
        margin: auto;

    }
}
