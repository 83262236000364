.widget-FileList {
    position: relative;
    background: $color-white;
    padding: 20px;
    margin-bottom: 1em;
    box-shadow: 0 2px 8px rgb(0 0 0 / 30%);
    padding-left: calc(0.875vw + 0.83333333vw + 40px);

    @media screen and (min-width: 23.75em) { /* 380px */
        padding: 30px;
        padding-left: calc(0.875vw + 0.83333333vw + 50px);
    }


    &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        top: 0;
        width: calc(0.875vw + 0.83333333vw + 20px);
        background: url(../images/qaams-artwork.jpg);
        background-position: center 38%;
        background-size: auto 100%;
        background-repeat: no-repeat;

        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
    }
}

.widget-FileList ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.widget-FileList li {
    margin-bottom: 8px;
}

.widget-FileList li:last-child {
    margin-bottom: 0;
}

.widget-FileList .document {
    min-height: $spacing--large;
    padding-left: $spacing--xlarge;
    background: url(../images/icon-system/icon_document.svg) no-repeat;
}

.widget-FileList .document-pdf {
    background-image: url(../images/icon-system/icon_pdf.svg);
}

.widget-FileList .document-png,
.widget-FileList .document-gif,
.widget-FileList .document-jpg {
    background-image: url(../images/icon-system/icon_image.svg);
}

.widget-FileList .document-rtf,
.widget-FileList .document-txt,
.widget-FileList .document-doc,
.widget-FileList .document-docx {
    background-image: url(../images/icon-system/icon_word-doc.svg);
}

.widget-FileList .document-csv,
.widget-FileList .document-xls,
.widget-FileList .document-xlsx {
    background-image: url(../images/icon-system/icon_spreadsheet.svg);
}

.widget-FileList .document-mp3,
.widget-FileList .document-aac,
.widget-FileList .document-oga {
    background-image: url(../images/icon-system/icon_sound.svg);
}

.widget-FileList .document-mp4,
.widget-FileList .document-mpeg,
.widget-FileList .document-mpg,
.widget-FileList .document-webm,
.widget-FileList .document-ogv,
.widget-FileList .document-aac,
.widget-FileList .document-avi,
.widget-FileList .document-mov,
.widget-FileList .document-wmv {
    background-image: url(../images/icon-system/icon_video.svg);
}

.widget-FileList a {
    display: inline-block;
    text-decoration: none;
    color: $color-primary;
    transition: all 200ms ease-in-out;
}

.widget-FileList a:hover,
.widget-FileList a:focus,
.widget-FileList a:active {
    text-decoration: underline;
}


