
.team-members-list {
    margin-top: $spacing*3;
    @include list-reset;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr)) ;
    gap: $spacing*2;

    @include screen-768 {
        margin-top: $spacing*4;
    }

    &__item {
        text-align: center;



        a {
            font-size: .9em;
            line-height: 1.12;
            display: inline-block;
            margin: auto;
            max-width: 222px;
        }
    }

}

.team-member {

    &__img {
        margin-bottom: $spacing;
        display: block;
        border-radius: 100%;
        margin-left: auto;
        margin-right: auto;
        aspect-ratio: 1;
    }

    &__name {
        @include h3;
        color: $color-primary;
        margin: 0 0 $spacing;
    }

    &__position {
        @include h4;
    }
}
