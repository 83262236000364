@mixin attention-pill($background: $color-accent-02-mid, $iconBackground: $color-accent-02, $textSize: 'default') {
    display: inline-grid;
    grid-auto-flow: column;
    column-gap: 10px;
    align-items: center;

    background-color: $background;
    padding: 10px 12px 10px 10px;
    border-radius: 60px;
    margin-bottom: 0;

    @if $textSize == 'heading' {
        padding-right: 24px;
    }

    &__icon {
        display: block;
        background-color: $iconBackground;
        width: 40px;
        height: 40px;
        border-radius: 40px;
    }

    &__copy {
        color: $color-body-font;
        margin-bottom: 0;

        @if $textSize == 'default' {
            font-size: var(--centi-font-size);
        }

        @if $textSize == 'heading' {
            font-size: var(--h4-font-size);
            color: $color-primary;
            font-weight: $fw-medium;

            @include screen-768 {
                font-size: var(--h3-font-size);
            }
        }
    }
}

.attention-pill {
    @include attention-pill;
}
