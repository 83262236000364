#footer {
    position: relative;
    background-color: $color-primary;
    @include reverse-text;
    margin-top: calc(0.7916666667vw + 0.75vw + 20px);

    @include screen-2400 {
        max-width: $max-page-width;
        margin-left: auto;
        margin-right: auto;
    }
}

.footer {
    position: relative;
    overflow: hidden;
    padding: var(--section-medium) 0;

    &:before,
    &:after {
        content: "";
        position: absolute;
        border-radius: 100%;
        height: 1024px;
        width: 1024px;
        top: 50%;
        transform: translateY(-50%);
        right: calc(var(--default-container-gutter) + (var(--default-container) * 0.4791666667));

        @include screen-992 {
            height: calc(var(--default-container) * 0.7229166667);
            width: calc(var(--default-container) * 0.7229166667);
            top: var(--section);
            right: calc(var(--default-container-gutter) + (var(--default-container) * 0.4791666667));
            transform: none;
        }
    }

    &:before {
        background: url(../images/qaams-artwork.jpg);
        background-position: 50% 60%;
        background-size: 102%;
        background-repeat: no-repeat;
        opacity: 0.1;
    }

    &:after {
        mix-blend-mode: color;
        background-color: $color-primary;
    }

    &__artwork {
        position: absolute;
        bottom: 100%;
        left: 0;
        right: 0;
        height: calc(0.7916666667vw + 0.75vw + 20px);
        background: url(../images/qaams-artwork.jpg);
        background-position: center 30.5%;
        background-size: 102%;
        background-repeat: no-repeat;

        @include screen-2400 {
            height: calc((0.015416666667 * #{$max-page-width}) + 20px);
        }

        .footer-artwork-negative-curve-top {
            margin-top: -0.5px;
            display: block;
            width: 100vw;
            height: 0.7916666667vw;
            margin-bottom: 21px;

            @include screen-2400 {
                width: $max-page-width;
                height: calc(0.007916666667 * #{$max-page-width});
            }
        }

        .footer-curve {
            display: block;
            width: 100vw;
            height: 0.75vw;

            @include screen-2400 {
                width: $max-page-width;
                height: calc(0.0075 * #{$max-page-width});
            }
        }
    }

    .container {
        position: relative;
        z-index: 1;

        @include screen-768 {
            padding-left: var(--container-padding);
            padding-right: var(--container-padding);
        }

        @include screen-1200 {
            padding-left: 0;
            padding-right: 0;
        }

        @include screen-1400 {
            padding-left: var(--container-padding);
            padding-right: var(--container-padding);
        }
    }

    > .container:first-child {
        display: grid;
        grid-template-rows: repeat(4, auto);
        gap: 56px;

        @include screen-992 {
            grid-template-columns: 2fr 3fr;
            grid-template-rows: repeat(2, auto);
            grid-auto-flow: column;
            gap: 56px 80px;
        }

        @include screen-1200 {
            gap: 80px;
            grid-template-columns: 9fr 7fr;
            grid-template-rows: repeat(2, auto);
        }
    }

    &__quicklinks {
        @include screen-560 {
            columns: 2;
        }

        @include screen-992 {
            columns: 1;
            border-top: 3px solid $color-accent-01-mid;
            border-bottom: 3px solid $color-accent-01-mid;
            padding-top: var(--section);
            padding-bottom: var(--section);
            margin-top: 56px;
        }

        @include screen-1200 {
            columns: 2;
        }

        .block-list {
            margin-bottom: 0;
        }
    }

    &__socials {
        grid-row: 4;

        @include screen-992 {
            grid-row: auto;
        }
    }

    &__acknowledgement {
        position: relative;
        border-top: 3px solid $color-accent-01-mid;
        border-bottom: 3px solid $color-accent-01-mid;
        padding-top: 80px;
        padding-bottom: var(--section-small);
        margin-top: 56px;
        z-index: 1;

        @include screen-768 {
            padding-top: var(--section);
        }

        &__header-icon,
        &__footer-icon {
            content: "";
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            z-index: 1;
            background-color: $color-accent-01;
            border: 12px solid $color-primary;
            border-radius: 100%;
        }

        &__header-icon {
            top: -66px;
            width: 132px;
            height: 132px;
        }

        &__footer-icon {
            bottom: -32px;
            width: 64px;
            height: 64px;
        }

        &__flags {
            text-align: center;

            svg {
                width: 135px;
                height: auto;
            }
        }

        h2:nth-of-type(1) {
            text-align: center;
            margin-bottom: 1em;
            font-size: var(--h3-font-size);
        }
    }

    &__warning {
        @include attention-pill($color-accent-01-mid, $color-accent-01);
        position: relative;
        z-index: 1;
    }

    &__footer {
        padding-top: var(--section);
        font-size: var(--centi-font-size);

        > .container {
            @include screen-560 {
                display: flex;
            }
        }
    }

    .footer__text {
        flex: 1 1 auto;
        margin: 0;

        > * {
            display: block;
            margin-bottom: 1em;

            @include screen-992 {
                display: inline-block;
                margin-left: calc((var(--default-container) - 236px) * 0.04651162791);
                margin-bottom: 0;
            }

            @include screen-1600 {
                margin-left: 56px;
            }

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        a {
            @include links-list-item;
        }
    }

    .website-attribution {
        align-self: flex-end;
        flex: 0 0 auto;
        margin: 0;

        &__link {
            display: block;
            transition: filter 250ms ease;
        }

        svg {
            width: 20px;
            height: auto;
        }
    }
}
