
a {
    color: $color-accent-01;
    word-break: break-word;
    transition:
        color $link-transition,
        opacity $link-transition,
        background-color $link-transition,
        box-shadow $link-transition;
}

a:hover,
a:active,
a:focus {
    color: darken($color-accent-01, 20%);
}

/* Address `outline` inconsistency between Chrome and other browsers. */
a:focus {
    @include focus;
    outline-offset: 4px;
}

/* Improve readability when focused and also mouse hovered in all browsers. */
a:active,
a:hover {
    outline: 0;
}

/* Include file type and size in document links */
a.document::after {
    content: " (" attr(data-ext) " " attr(data-size) ")";
}

.text-link {
    @include text-link;
}
