.card {
    background: #fff;
    padding: 0;
    display: flex;
    flex-direction: column;
}
.card:hover {
    box-shadow: 0 0 2px 0 rgba(0,0,0,0.33);
}
.card a:focus {
    text-decoration: underline;
}
.card:focus-within {
    box-shadow: 0 0 2px 0 rgba(0,0,0,0.33);
}
.card:focus-within a:focus {
    text-decoration: none;
}
.card__content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  order: 1;
}
.card__content > * + * {
  margin-top: $spacing--small;
}
.card__content :last-child {
  margin-top: auto;
}
.card__content :nth-last-child(2) {
  margin-bottom: $spacing--small;
}
.card__img {
  height: 200px;
}
.card__img img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
