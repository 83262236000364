

// Brand colours

$color-primary: 					#2D1945; // Indigo

$color-secondary:                   #D05559; // Coral

$color-secondary-mid:               #E6ABA3; // Coral

$color-secondary-pastel:            #F9EAE8; // Coral

$color-alternative:                 #00847F; // Teal

$color-alternative-mid:             #ABD8D6; // Teal

$color-alternative-pastel:          #EEF7F7; // Teal

$color-accent-01: 				    #655DC6; // Lilac

$color-accent-01-mid: 				#C1BDDE; // Lilac

$color-accent-01-pastel:            #EFEEF7; // Lilac

$color-accent-02:                   #FFB548; // Honey

$color-accent-02-mid:               #FED9A9; // Honey

$color-accent-02-pastel:            #FFF5E9; // Honey


// Neutral Colours

$color-white:						#ffffff;

$color-grey-01:                     #F5f5f5;

$color-grey-001:					#E4E4E4;

$color-grey-02:						#DADADA;

$color-grey-03:						#b8b8b8;

$color-grey-04:						#9c9c9c;

$color-grey-05:						#7f7f7f;

$color-grey-06:						#636363;

$color-grey-07:						#474747;

$color-grey-08:                     #3D3935;

$color-grey-09:						#2b2b2b;

$color-grey-10:						#0f0f0f;

$color-black:						#000000;


// Utility Colours

$color-utility-positive:			#A2CF5F;

$color-utility-positive-subtle:		#DCEBC4;

$color-utility-alert:				#BD202E;

$color-utility-alert-subtle:		#E9C2B6;

$color-utility-warning:				#FCB34C;

$color-utility-warning-subtle:		#FFE1BB;

$color-utility-neutral:				#2E4A77;

$color-utility-neutral-subtle:		#5684CF;

$color-utility-selection:           #FFD58C;

$color-utility-placeholder:         $color-grey-04;
