/* ---- Clearable fields ---- */
.field-clearable__wrap {
    position: relative;
}

.field-clearable__clear {
    outline: none;
    border: none;
    background: none;
    padding: 0;
    color: #D4D7DE;
    position: absolute;
    right: 6px;
    top: 9px;
    top: calc(50% - (22px / 2));
    background-image: url(../../../media/images/form-icons.svg);
    background-repeat: no-repeat;
    background-position: top -1152px left 3px;
    width: 22px;
    height: 22px;
}

.field-clearable__clear:hover {
    background-position: top -1208px left 3px;
}

.field-element--disabled .field-clearable__clear {
    display: none;
}
