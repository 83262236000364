.field-input select {
    -moz-appearance: none;
    background-image:
        url(../images/icon-system/icon_form_arrow-down.svg),
        url(../images/form_bg-fill.svg);
    background-repeat:
        no-repeat,
        repeat-Y;
    background-position:
        center right $form-spacing,
        center right;
    background-size:
        $form-spacing*3,
        $form-spacing*5;
    padding-right: $form-spacing*6.5;
}

.field-input select[multiple] {
    background-image: none;
    padding-right: $form-spacing*2.5;
}

.field-input select::-ms-expand {
    display: none;
}

/* Multiple selects */
.field-element--select--multiple select {
    padding: $form-spacing;
    background-image: none;
}

.field-element--select--multiple option {
    padding: $form-spacing;
}
