@mixin tooltip-content-position {
	position: absolute;
	top: 100%;
	left: 0;
}

@mixin tooltip-content-style {
	background-color: lighten($color-grey-02, 5%);
	border: 1px solid $color-grey-02;
	padding: 0;
	font-size: var(--body-font-size);
	border-radius: 4px;
	margin-top: 7px;
	text-align: left;
	color: $color-grey-06;
	z-index: 100;
	font-weight: $fw-normal;
	white-space: normal;
	text-transform: none;
}


@mixin tooltip-anchor($vertical-position: 'top', $horizontal-position: 'left') {
	&:before,
	&:after {
		content: " ";
		position: absolute;
		bottom: 100%;
		left: 14px;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 5px 5px 5px;
		border-color: transparent transparent lighten($color-grey-02, 5%) transparent;
		z-index: 1;
	}

	&:after {
		left: 13px;
		border-width: 0 6px 6px 6px;
		border-color: transparent transparent $color-grey-02 transparent;
		z-index: 0;
	}


	@if $vertical-position == 'bottom' {
		&:before,
		&:after {
			bottom: auto;
			top: 100%;
		}
	}

	@if $horizontal-position == 'right' {
		&:before {
			right: 14px;
			left: auto;
		}

		&:after {
			right: 13px;
			left: auto;
		}
	}
}
