* {
    margin: 0;
}

html,
body {
    background: #FFF;
    height: 100%;
}

html {
    font-size: 62.5%;
}

body {
    min-width: 320px;
    line-height: var(--body-line-height);
    font-size: $font-body;
    color: $color-body-font;
    font-family: $primary-font;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
