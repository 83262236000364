.header {
    background: $color-primary;
    padding: 16px 0 20px;
    position: relative;
    margin-bottom: calc(0.875vw + 0.83333333vw + 20px);

    @include screen-2400 {
        max-width: $max-page-width;
        margin: 0 auto calc((0.0170833333 * #{$max-page-width}) + 20px);
    }

    @include screen-menu-mobile() {
        padding-top: 0;
        padding-bottom: 24px;
    }

    .row {
        @include screen-menu-mobile() {
            justify-content: center;
        }

        .col-xs-grow {
            @include screen-menu-mobile() {
                display: none;
            }
        }
    }

    &__artwork {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        height: calc(0.875vw + 0.83333333vw + 20px);
        background: url(../images/qaams-artwork.jpg);
        background-position: center 38%;
        background-size: 102%;
        background-repeat: no-repeat;

        @include screen-2400 {
            height: calc((0.0170833333 * #{$max-page-width}) + 20px);
        }

        .header-block-colour-curve {
            margin-top: -0.5px;
            display: block;
            width: 100vw;
            height: 0.875vw;

            @include screen-2400 {
                width: $max-page-width;
                height: calc(0.00875 * #{$max-page-width});
            }
        }

        .header-artwork-negative-curve {
            margin-top: 21px;
            display: block;
            width: 100vw;
            height: 0.8333333333vw;

            @include screen-2400 {
                width: $max-page-width;
                height: calc(0.008333333333 * #{$max-page-width});
            }
        }
    }

    .header__logo {
        display: block;
    }

    &__rhs-column {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        &__nav-btns {
            text-decoration: none;
            display: grid;
            gap: $spacing*2;
            grid-auto-flow: column;
            align-items: center;
        }
    }

    &__login-button {
        margin-top: 24px;
        margin-bottom: 24px;

        @include screen-menu-mobile() {
            display: none;
        }
    }
}

.header__search .field-element {
    margin-bottom: 0;
}

