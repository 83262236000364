h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    font-family: $accent-font;
    margin: 0 0 var(--paragraph-break);
    line-height: 1.1;
    font-weight: $fw-medium;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
    font-weight: normal;
    line-height: 1;
    color: #8a7b7b;
}

p + h1,
p + h2,
p + h3,
p + h4,
p + h5,
p + h6 {
    margin-top: 3rem;
}


h1, .h1 {
    @include h1();
}

h2, .h2 {
    @include h2();
}

h3, .h3 {
    @include h3();
}

h4, .h4 {
    @include h4();
}

h5, .h5 {
    @include h5();
}

h6, .h6 {
    @include h6();
}

p,
ol,
ul,
dl,
address {
    margin: 0 0 var(--body-font-size);
}

small {
    font-size: var(--milli-font-size);
}

.heading-inline {
    display: inline;
    float: left;
    padding: 0;
}

.section-title {
    @include section-title;
    text-align: center;
}

.section-subtitle {
    @include h2;
    text-align: center;
}

.widget-RichText {
    h2 {
        color: $color-primary;
    }
}
