

.row--vertical-gutters *[class*="col-xs"],
.row--vertical-gutters *[class*="col-sm"],
.row--vertical-gutters *[class*="col-md"],
.row--vertical-gutters *[class*="col-lg"] {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

/* ---- Row - nowrap  ---- */
.row-nowrap--xs {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
}

@media screen and (min-width: 36em) {
    .row-nowrap--xsm {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    }
}

@media screen and (min-width: 48em) {
    .row-nowrap--sm {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    }
}

@media screen and (min-width: 62em) {
    .row-nowrap--md {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    }
}

@media screen and (min-width: 75em) {
    .row-nowrap--lg {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    }
}

@media screen and (min-width: 100em) {
    .row-nowrap--xlg {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    }
}


/* ---- Unpadded row ---- */
.row-0 {
    margin-right: 0;
    margin-left: 0;
}

.row-0 > .col-xs,
.row-0 > .col-xs-1,
.row-0 > .col-xs-2,
.row-0 > .col-xs-3,
.row-0 > .col-xs-4,
.row-0 > .col-xs-5,
.row-0 > .col-xs-6,
.row-0 > .col-xs-7,
.row-0 > .col-xs-8,
.row-0 > .col-xs-9,
.row-0 > .col-xs-10,
.row-0 > .col-xs-11,
.row-0 > .col-xs-12,
.row-0 > .col-xs-grow,
.row-0 > .col-xs-shrink {
    padding-right: 0;
    padding-left: 0;
}
