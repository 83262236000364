blockquote {
    margin: 1.5rem 0;
    padding: 20px 25px 20px;
    background: #f6f6f6;
    font-style: italic;
    position: relative;
    clear: both;
    border-left: 4px solid $color-alternative;
}

blockquote *:first-child {
    margin-top: 0;
}

blockquote *:last-child {
    margin-bottom: 0;
}

@media only screen and (min-width: 62em) { /* 992px */
    .blockquote--left {
        float: left;
        width: 45%;
        margin-left: 0px;
        margin-right: 20px;
        clear: right;
    }

    .blockquote--right {
        float: right;
        width: 45%;
        margin-left: 20px;
        margin-right: 0px;
        clear: left;
    }
}
