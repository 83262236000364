
#wrap {
    transition:
    transform var(--menu-transition),
    opacity var(--menu-transition);

    @include screen-menu-mobile {
        overflow-x: hidden;
        margin-top: var(--header-height);
        backface-visibility: hidden;
        background-color: $color-white;
    }

    @include screen-menu-desktop {
        padding-top: var(--header-height);
    }

    @include screen-2400 {
        max-width: $max-page-width;
        margin: 0 auto;
        overflow: hidden; /* [03] */
    }
}

/*
 * [03]
 * needed for bleed left and right utilities as they overflow the wrap container
 */
