.sidebar .widget {
    background-color: $color-white;
    position: relative;

    @include screen-992 {
        box-shadow: 0 2px 4px rgba($color-black, 0.15);
        margin-top: calc((var(--section) + 1.83333vw) * -1);
    }

    &:before {

    }

    .widget-title {
        padding: calc(24px + var(--body-font-size)) 24px var(--body-font-size);
        text-transform: uppercase;
        font-size: var(--body-font-size);
        font-weight: $fw-bold;
        color: $color-primary;
    }
}

.sidebar {
    .widget-RichText {
        padding:  calc(16px + var(--body-font-size)) 32px var(--body-font-size) 24px;
    }
}
