:root {
    --default-container: 88vw;
    --default-container-gutter: 6vw;
    --container-padding: 0;
}

@include screen-768 {
    :root {
        --default-container: 92vw;
        --default-container-gutter: 4vw;
        --container-padding: 80px;
    }
}

@include screen-1600 {
    :root {
        --default-container: 1440px;
        --default-container-gutter: calc(50vw - (var(--default-container) / 2));
    }
}

@include screen-2400 {
    :root {
        --default-container-gutter: calc((#{$max-page-width} - var(--default-container)) / 2);
    }
}


.container {
    width: var(--default-container);
    margin: 0 auto;
}

.container--padded {
    padding-right: var(--container-padding);
    padding-left: var(--container-padding);

    &--md {
        @include screen-992 {
            padding-right: var(--container-padding);
            padding-left: var(--container-padding);
        }
    }

    &--lg {
        @include screen-1200 {
            padding-right: var(--container-padding);
            padding-left: var(--container-padding);
        }
    }
}
