
.block-list {
    list-style: none;
    padding: 0;
    margin: 0 0 20px;
}

.block-list a {
    display: block;
    @include links-list-item;
    padding: 0.75em 0;
}
