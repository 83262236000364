
.video-list {
    @include list-reset;
    display: grid;
    gap: $spacing*1.5;

    @include screen-480 {
        gap: $spacing*4;
        grid-template-columns: repeat(auto-fit, minmax(195px, 1fr));
    }

    &__item {

        &__img-link {
            display: block;
            position: relative;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background: url(../images/icon-system/icon_youtube.svg) no-repeat center center;
                background-size: 20% auto;
                opacity: 0;
                transition: background $link-transition, opacity $link-transition;
            }

            &:hover::after {
                background-size: 25% auto;
                opacity: 1;
            }
        }

        &__button {
            @include button($color-alternative, $color-white);
            margin-top: $spacing;
            display: block;
            @include button-text-small;
            @include button-width-small;
            @include button-height-small;
            line-height: 1.12;
        }
    }
}
