.wedge-section__title {
    position: relative;
    padding-top: var(--section);
    padding-bottom: calc(var(--section) - 1.833333333vw);

    h1 {
        @include section-title;
        font-size: var(--h2-font-size);
        color: inherit;
        margin-bottom: 0.2em;
    }

    h2 {
       @include section-title;
       font-size: var(--h2-font-size);
       color: inherit;
       margin-bottom: 0;
    }

    &:after {
        content: "";
        position: absolute;
        top: calc(100% - 0.5px);
        left: 0;
        right: 0;
        height: 1.833333333vw;
        background-position: center top;
        background-size: 100%;
        background-repeat: no-repeat;
    }
}

.wedge-section__content {
    padding-top: calc(var(--section) + 1.833333333vw);
    padding-bottom: var(--section-large);
}

.wedge-section--alternative {
    .wedge-section__title {
        background-color: $color-alternative;
        color: $color-white;

        &:after {
            background-image: url(../images/top-n-tails/section-header-wedge-teal.svg);
        }
    }

    .wedge-section__content {
        background-color: $color-alternative-pastel;
    }
}

.wedge-section--secondary {
    .wedge-section__title {
        background-color: $color-secondary;
        color: $color-white;

        &:after {
            background-image: url(../images/top-n-tails/section-header-wedge-coral.svg);
        }
    }

    .wedge-section__content {
        background-color: $color-secondary-pastel;
    }
}

.wedge-section--accent-01 {
    .wedge-section__title {
        background-color: $color-accent-01;
        color: $color-white;

        &:after {
            background-image: url(../images/top-n-tails/section-header-wedge-lilac.svg);
        }
    }

    .wedge-section__content {
        background-color: $color-accent-01-pastel;
    }
}

.wedge-section--accent-01-mid {
    .wedge-section__title {
        background-color: $color-accent-01-mid;
        color: $color-primary;

        &:after {
            background-image: url(../images/top-n-tails/section-header-wedge-lilac-mid.svg);
        }
    }

    .wedge-section__content {
        background-color: $color-accent-01-pastel;
    }
}

.section--content {
    .wedge-section__title {
        padding-bottom: calc(var(--section-small) - 1.833333333vw);
    }

    .wedge-section__content {
        padding-top: calc(var(--section-small) + 1.833333333vw);

        > .container {
            @include screen-992 {
                padding-left: 0;
                padding-right: 0;
            }

            @include screen-1200 {
                padding-right: var(--container-padding);
                padding-left: var(--container-padding);
            }
        }
    }
}

.section--content.inner {
    .wedge-section__title > .container {
        @include screen-992 {
            display: grid;
            column-gap: var(--inner-scaffold-gap);
            grid-template-columns: 1fr 2fr;
        }

        > * {
            @include screen-992 {
                grid-column: 2;
            }
        }
    }
}
