.article {
    display: grid;
    grid-template-columns: 1fr;
    gap: 56px;
    align-items: center;

    @include screen-992 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 72px;
    }

    &__picture {
        img {
            border-radius: 100%;
        }
    }

    &__copy {

        p {
            margin-bottom: 1em;
        }

        @include screen-560 {
            font-size: 2rem;
        }

        @include screen-992 {
            font-size: var(--body-font-size);
        }

        @include screen-1200 {
            font-size: 2rem;
        }

        @include screen-1400 {
            font-size: 2.4rem;
        }
    }
}
