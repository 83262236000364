@charset "UTF-8";
/* ------------------------------------------------------
**** ATOMS / SCSS Inclusions
------------------------------------------------------ */
/* ---- Global Variables ---- */
/*
Variable Example
*/
/*
Breakpoint Mixins
*/
:root {
  --body-font-size: 1.6rem;
  --body-line-height: 1.5;
  --h1-font-size: 4rem;
  --h2-font-size: 3.2rem;
  --h3-font-size: 2.4rem;
  --h4-font-size: 2rem;
  --h5-font-size: 2rem;
  --h6-font-size: 1.8rem;
  /* [02] */
  --giga-font-size: 8rem;
  --mega-font-size: 4rem;
  --kilo-font-size: 3.2rem;
  --milli-font-size: 1.1rem;
  --centi-font-size: 1.3rem;
}

@media screen and (min-width: 48em) {
  :root {
    --body-font-size: 1.7rem;
    --kilo-font-size: 4rem;
    --mega-font-size: 5.25vw;
  }
}
@media screen and (min-width: 100em) {
  :root {
    --body-font-size: 1.8rem;
  }
}
@media screen and (min-width: 120em) {
  :root {
    --mega-font-size: 10rem;
  }
}
/* Weights */
/*
Basic system font stacks
*/
/*
$fw-normal
$fw-semibold
$fw-bold
$fw-heavy
*/
/*
$fw-normal
$fw-medium
$fw-bold
*/
/* Size */
/* -----
* NOTES
* [02]
* A series of classes for setting massive type; for use in heroes, mastheads,
* promos, etc.
* As per: csswizardry.com/2012/02/pragmatic-practical-font-sizing-in-css
------ */
/*
Brandon Grotesque Regular
font-family: brandon-grotesque, sans-serif;
font-weight: 400;
font-style: normal;
*/
/*
Brandon Grotesque Medium
font-family: brandon-grotesque, sans-serif;
font-weight: 500;
font-style: normal;
*/
/*
Brandon Grotesque Bold
font-family: brandon-grotesque, sans-serif;
font-weight: 700;
font-style: normal;
*/
/*
Nunito Sans Regular
font-family: nunito-sans, sans-serif;
font-weight: 400;
font-style: normal;
*/
/* -----------------------------
File Path
-------------------------------- */
/* -----------------------------
Spacing
-------------------------------- */
/* -----------------------------
style variables
-------------------------------- */
/* -----------------------------
Header
-------------------------------- */
/* -----------------------------
Page
-------------------------------- */
/* Fade in */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Fade in down */
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
}
/* Fade in up margin */
@-webkit-keyframes fadeInUpMargin {
  from {
    opacity: 0;
    margin-bottom: -20px;
  }
  to {
    opacity: 1;
    margin-bottom: 0;
  }
}
@keyframes fadeInUpMargin {
  from {
    opacity: 0;
    margin-bottom: -20px;
  }
  to {
    opacity: 1;
    margin-bottom: 0;
  }
}
.animate-fade {
  animation: fadeInDown 400ms ease-in-out;
}

.demo-animate .demo-animate__item {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.demo-animate:hover .demo-animate__item {
  clip: auto;
  height: auto;
  overflow: visible;
  position: relative;
  width: auto;
  padding: 5px;
  -webkit-animation: fadeInDown 400ms ease-in-out;
  animation: fadeInDown 400ms ease-in-out;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

/* ---- Mixins ---- */
:root {
  --button-border-width: 0;
  --button-border-radius: 2em;
}

/* --------------------------------
Sizes
----------------------------------- */
/* Width - Inline Padding  */
/* Height - Block Padding */
/* Text Size */
/* -----------------------------
Visibility Utilities
------------------------------*/
/* -----------------------------
Clearfix
------------------------------*/
/* -----------------------------
SVG
------------------------------*/
/* -----------------------------
List Mixins
------------------------------*/
/* -----------------------------
Fallback logo image
------------------------------*/
/* -----------------------------
High visibility focus
------------------------------*/
/* -----------------------------
Icon Wrapper
------------------------------*/
/* -----------------------------
Text Link
------------------------------*/
/* -----------------------------
Links list link
------------------------------*/
/* -----------------------------
Site Utility Messages
------------------------------*/
/* ----------------------------
Default titles
------------------------------- */
/* ----------------------------
Two line indented text
------------------------------- */
/* ----------------------------
Subtitle with Title
------------------------------- */
:root {
  --field-default-border: 1px solid #DADADA;
  --field-default-background: #F5f5f5;
  --field-padding-vertical: 0.8em;
  --field-padding-horizontal: 0.88888em;
  --field-padding-vertical-sm: 0.5em;
  --field-padding-horizontal-sm: 0.8em;
  --field-padding-vertical-xsm: 0.3em;
  --field-padding-horizontal-xsm: 0.75em;
}

/* ---- Base HTML ---- */
@viewport {
  width: device-width;
  zoom: 1;
}
html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*, *:before, *:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

* {
  margin: 0;
}

html,
body {
  background: #FFF;
  height: 100%;
}

html {
  font-size: 62.5%;
}

body {
  min-width: 320px;
  line-height: var(--body-line-height);
  font-size: var(--body-font-size);
  color: #3D3935;
  font-family: nunito-sans, helvetica neue, helvetica, roboto, noto, arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* - Montserrat - */
/*
font-family: 'Montserrat', Helvetica, Arial, sans-serif;
*/
/* Light (used as regular) */
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserrat/montserrat-light-webfont.eot");
  src: url("../fonts/montserrat/montserrat-light-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/montserrat/montserrat-light-webfont.woff2") format("woff2"), url("../fonts/montserrat/montserrat-light-webfont.woff") format("woff"), url("../fonts/montserrat/montserrat-light-webfont.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
/* Regular (used as semibold) */
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserrat/montserrat-regular-webfont.eot");
  src: url("../fonts/montserrat/montserrat-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/montserrat/montserrat-regular-webfont.woff2") format("woff2"), url("../fonts/montserrat/montserrat-regular-webfont.woff") format("woff"), url("../fonts/montserrat/montserrat-regular-webfont.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
/* Semi bold (used as bold) */
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserrat/montserrat-semibold-webfont.eot");
  src: url("../fonts/montserrat/montserrat-semibold-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/montserrat/montserrat-semibold-webfont.woff2") format("woff2"), url("../fonts/montserrat/montserrat-semibold-webfont.woff") format("woff"), url("../fonts/montserrat/montserrat-semibold-webfont.ttf") format("truetype");
  font-weight: 700;
  font-style: bold;
}
.row--vertical-gutters *[class*=col-xs],
.row--vertical-gutters *[class*=col-sm],
.row--vertical-gutters *[class*=col-md],
.row--vertical-gutters *[class*=col-lg] {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

/* ---- Row - nowrap  ---- */
.row-nowrap--xs {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

@media screen and (min-width: 36em) {
  .row-nowrap--xsm {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
}
@media screen and (min-width: 48em) {
  .row-nowrap--sm {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
}
@media screen and (min-width: 62em) {
  .row-nowrap--md {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
}
@media screen and (min-width: 75em) {
  .row-nowrap--lg {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
}
@media screen and (min-width: 100em) {
  .row-nowrap--xlg {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
}
/* ---- Unpadded row ---- */
.row-0 {
  margin-right: 0;
  margin-left: 0;
}

.row-0 > .col-xs,
.row-0 > .col-xs-1,
.row-0 > .col-xs-2,
.row-0 > .col-xs-3,
.row-0 > .col-xs-4,
.row-0 > .col-xs-5,
.row-0 > .col-xs-6,
.row-0 > .col-xs-7,
.row-0 > .col-xs-8,
.row-0 > .col-xs-9,
.row-0 > .col-xs-10,
.row-0 > .col-xs-11,
.row-0 > .col-xs-12,
.row-0 > .col-xs-grow,
.row-0 > .col-xs-shrink {
  padding-right: 0;
  padding-left: 0;
}

img {
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}

:root {
  --default-container: 88vw;
  --default-container-gutter: 6vw;
  --container-padding: 0;
}

@media screen and (min-width: 48em) {
  :root {
    --default-container: 92vw;
    --default-container-gutter: 4vw;
    --container-padding: 80px;
  }
}
@media screen and (min-width: 100em) {
  :root {
    --default-container: 1440px;
    --default-container-gutter: calc(50vw - (var(--default-container) / 2));
  }
}
@media screen and (min-width: 150em) {
  :root {
    --default-container-gutter: calc((2400px - var(--default-container)) / 2);
  }
}
.container {
  width: var(--default-container);
  margin: 0 auto;
}

.container--padded {
  padding-right: var(--container-padding);
  padding-left: var(--container-padding);
}
@media screen and (min-width: 62em) {
  .container--padded--md {
    padding-right: var(--container-padding);
    padding-left: var(--container-padding);
  }
}
@media screen and (min-width: 75em) {
  .container--padded--lg {
    padding-right: var(--container-padding);
    padding-left: var(--container-padding);
  }
}

#wrap {
  transition: transform var(--menu-transition), opacity var(--menu-transition);
}
@media screen and (max-width: 61.9375em) {
  #wrap {
    overflow-x: hidden;
    margin-top: var(--header-height);
    backface-visibility: hidden;
    background-color: #ffffff;
  }
}
@media screen and (min-width: 62em) {
  #wrap {
    padding-top: var(--header-height);
  }
}
@media screen and (min-width: 150em) {
  #wrap {
    max-width: 2400px;
    margin: 0 auto;
    overflow: hidden;
    /* [03] */
  }
}

/*
 * [03]
 * needed for bleed left and right utilities as they overflow the wrap container
 */
.bg-white {
  background-color: #ffffff;
}

.bg-lightgrey {
  background-color: #DADADA;
}

.bg-primary {
  background-color: #2D1945;
}

.bg-secondary {
  background-color: #D05559;
}

.bg-alternative {
  background-color: #00847F;
}

.bg-accent-01 {
  background-color: #655DC6;
}

.bg-accent-02 {
  background-color: #FFB548;
}

.reverse-text {
  color: #ffffff;
}

.reverse-text a {
  color: inherit;
}

.reverse-text a:hover,
.reverse-text a:focus,
.reverse-text a:active {
  color: inherit;
  opacity: 0.75;
}

:root {
  --section-small: 24px;
  --section: 40px;
  --section-medium: 56px;
  --section-large: 80px;
}

@media screen and (min-width: 48em) {
  :root {
    --section-small: 40px;
    --section: 80px;
    --section-medium: 104px;
    --section-large: 136px;
  }
}
.section,
.section-regular {
  padding-top: var(--section);
  padding-bottom: var(--section);
}

.section-small {
  padding-top: var(--section-small);
  padding-bottom: var(--section-small);
}

.section-large {
  padding-top: var(--section-large);
  padding-bottom: var(--section-large);
}

.section--page {
  padding-top: var(--section-small);
  padding-bottom: var(--section);
}

.section-gutter {
  padding-top: var(--inner-scaffold-gap);
  padding-bottom: var(--inner-scaffold-gap);
}

.section-t0 {
  padding-top: 0;
}

.section-b0 {
  padding-bottom: 0;
}

.section--content {
  padding-top: var(--section-small);
}

.wedge-section__title {
  position: relative;
  padding-top: var(--section);
  padding-bottom: calc(var(--section) - 1.833333333vw);
}
.wedge-section__title h1 {
  color: #2D1945;
  font-weight: 500;
  font-size: var(--h1-font-size);
  line-height: 1.05;
  margin-bottom: 1em;
  font-size: var(--h2-font-size);
  color: inherit;
  margin-bottom: 0.2em;
}
@media screen and (min-width: 48em) {
  .wedge-section__title h1 {
    font-size: 4.8rem;
  }
}
@media screen and (min-width: 87.5em) {
  .wedge-section__title h1 {
    font-size: 5.6rem;
  }
}
.wedge-section__title h2 {
  color: #2D1945;
  font-weight: 500;
  font-size: var(--h1-font-size);
  line-height: 1.05;
  margin-bottom: 1em;
  font-size: var(--h2-font-size);
  color: inherit;
  margin-bottom: 0;
}
@media screen and (min-width: 48em) {
  .wedge-section__title h2 {
    font-size: 4.8rem;
  }
}
@media screen and (min-width: 87.5em) {
  .wedge-section__title h2 {
    font-size: 5.6rem;
  }
}
.wedge-section__title:after {
  content: "";
  position: absolute;
  top: calc(100% - 0.5px);
  left: 0;
  right: 0;
  height: 1.833333333vw;
  background-position: center top;
  background-size: 100%;
  background-repeat: no-repeat;
}

.wedge-section__content {
  padding-top: calc(var(--section) + 1.833333333vw);
  padding-bottom: var(--section-large);
}

.wedge-section--alternative .wedge-section__title {
  background-color: #00847F;
  color: #ffffff;
}
.wedge-section--alternative .wedge-section__title:after {
  background-image: url(../images/top-n-tails/section-header-wedge-teal.svg);
}
.wedge-section--alternative .wedge-section__content {
  background-color: #EEF7F7;
}

.wedge-section--secondary .wedge-section__title {
  background-color: #D05559;
  color: #ffffff;
}
.wedge-section--secondary .wedge-section__title:after {
  background-image: url(../images/top-n-tails/section-header-wedge-coral.svg);
}
.wedge-section--secondary .wedge-section__content {
  background-color: #F9EAE8;
}

.wedge-section--accent-01 .wedge-section__title {
  background-color: #655DC6;
  color: #ffffff;
}
.wedge-section--accent-01 .wedge-section__title:after {
  background-image: url(../images/top-n-tails/section-header-wedge-lilac.svg);
}
.wedge-section--accent-01 .wedge-section__content {
  background-color: #EFEEF7;
}

.wedge-section--accent-01-mid .wedge-section__title {
  background-color: #C1BDDE;
  color: #2D1945;
}
.wedge-section--accent-01-mid .wedge-section__title:after {
  background-image: url(../images/top-n-tails/section-header-wedge-lilac-mid.svg);
}
.wedge-section--accent-01-mid .wedge-section__content {
  background-color: #EFEEF7;
}

.section--content .wedge-section__title {
  padding-bottom: calc(var(--section-small) - 1.833333333vw);
}
.section--content .wedge-section__content {
  padding-top: calc(var(--section-small) + 1.833333333vw);
}
@media screen and (min-width: 62em) {
  .section--content .wedge-section__content > .container {
    padding-left: 0;
    padding-right: 0;
  }
}
@media screen and (min-width: 75em) {
  .section--content .wedge-section__content > .container {
    padding-right: var(--container-padding);
    padding-left: var(--container-padding);
  }
}

@media screen and (min-width: 62em) {
  .section--content.inner .wedge-section__title > .container {
    display: grid;
    column-gap: var(--inner-scaffold-gap);
    grid-template-columns: 1fr 2fr;
  }
}
@media screen and (min-width: 62em) {
  .section--content.inner .wedge-section__title > .container > * {
    grid-column: 2;
  }
}

.block {
  margin-top: 20px;
  margin-bottom: 20px;
}

.block-mb {
  margin-bottom: 20px;
}

.block-large {
  margin-top: 20px;
  margin-bottom: 20px;
}

.block-mb-large {
  margin-bottom: 20px;
}

@media screen and (min-width: 23.75em) {
  /* 380px */
  .block-large {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .block-mb-large {
    margin-bottom: 25px;
  }
}
/* BP small */
@media screen and (min-width: 48em) {
  /* 768px */
  .block-large {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .block-mb-large {
    margin-bottom: 30px;
  }
}
.box {
  padding: 20px 15px;
}

@media screen and (min-width: 23.75em) {
  /* 380px */
  .box {
    padding: 20px;
  }
}
/* BP small */
@media screen and (min-width: 48em) {
  /* 768px */
  .box {
    padding: 30px;
  }
}
.box > *:last-child {
  margin-bottom: 0;
}

/* - Right - */
.col--right-align {
  text-align: right;
}

/* BP small */
@media screen and (min-width: 48em) {
  /* 768px */
  .col-sm--right-align {
    text-align: right;
  }
}
/* BP medium */
@media only screen and (min-width: 62em) {
  /* 992px */
  .col-md--right-align {
    text-align: right;
  }
}
/* BP large */
@media only screen and (min-width: 75em) {
  /* 1200px */
  .col-lg--right-align {
    text-align: right;
  }
}
/* - Left - */
.col--left-align {
  text-align: left;
}

/* BP small */
@media screen and (min-width: 48em) {
  /* 768px */
  .col-sm--left-align {
    text-align: left;
  }
}
/* BP medium */
@media only screen and (min-width: 62em) {
  /* 992px */
  .col-md--left-align {
    text-align: left;
  }
}
/* BP large */
@media only screen and (min-width: 75em) {
  /* 1200px */
  .col-lg--left-align {
    text-align: left;
  }
}
/* - center - */
.col--center-align {
  text-align: center;
}

/* BP small */
@media screen and (min-width: 48em) {
  /* 768px */
  .col-sm--center-align {
    text-align: center;
  }
}
/* BP medium */
@media only screen and (min-width: 62em) {
  /* 992px */
  .col-md--center-align {
    text-align: center;
  }
}
/* BP large */
@media only screen and (min-width: 75em) {
  /* 1200px */
  .col-lg--center-align {
    text-align: center;
  }
}
.left,
.center,
.right {
  margin: 30px auto;
  display: block;
  clear: both;
}

@media screen and (max-width: 34.9375em) {
  .highlight > p:first-child img.left, .highlight > p:first-child img.center, .highlight > p:first-child img.right {
    margin-top: 0;
  }
}
/* BP Smaller */
@media screen and (min-width: 35em) {
  .left {
    margin: 0 30px 30px 0;
    float: left;
  }

  .right {
    margin: 0 0 30px 30px;
    float: right;
  }

  .mainbar img.left, .mainbar img.right {
    max-width: calc(50% - 30px);
  }
}
/* ---- Text ---- */
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: brandon-grotesque, sans-serif;
  margin: 0 0 var(--paragraph-break);
  line-height: 1.1;
  font-weight: 500;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  font-weight: normal;
  line-height: 1;
  color: #8a7b7b;
}

p + h1,
p + h2,
p + h3,
p + h4,
p + h5,
p + h6 {
  margin-top: 3rem;
}

h1, .h1 {
  font-size: var(--h1-font-size);
  margin-bottom: calc(0.2em + var(--paragraph-break));
  color: inherit;
}

h2, .h2 {
  font-size: var(--h2-font-size);
  margin-bottom: calc(0.2em + var(--paragraph-break));
  color: inherit;
}

h3, .h3 {
  font-size: var(--h3-font-size);
  color: inherit;
}

h4, .h4 {
  font-size: var(--h4-font-size);
  color: inherit;
}

h5, .h5 {
  font-size: var(--h5-font-size);
  color: inherit;
}

h6, .h6 {
  font-size: var(--h6-font-size);
  color: inherit;
}

p,
ol,
ul,
dl,
address {
  margin: 0 0 var(--body-font-size);
}

small {
  font-size: var(--milli-font-size);
}

.heading-inline {
  display: inline;
  float: left;
  padding: 0;
}

.section-title {
  color: #2D1945;
  font-weight: 500;
  font-size: var(--h1-font-size);
  line-height: 1.05;
  margin-bottom: 1em;
  text-align: center;
}
@media screen and (min-width: 48em) {
  .section-title {
    font-size: 4.8rem;
  }
}
@media screen and (min-width: 87.5em) {
  .section-title {
    font-size: 5.6rem;
  }
}

.section-subtitle {
  font-size: var(--h2-font-size);
  margin-bottom: calc(0.2em + var(--paragraph-break));
  color: inherit;
  text-align: center;
}

.widget-RichText h2 {
  color: #2D1945;
}

:root {
  --paragraph-break: 1.6rem;
}

p,
address {
  margin: 0 0 var(--paragraph-break);
}

/* ---- Text selection ---- */
::-moz-selection {
  color: #000;
  background: #EAFF00;
}

::selection {
  color: #000;
  background: #EAFF00;
}

/* ---- Text level elements ---- */
abbr[title] {
  border-bottom: 1px dotted #c6bfbf;
  cursor: help;
}

b, strong {
  font-weight: 800;
}

dfn {
  font-style: italic;
}

ins {
  background-color: #f6f6f6;
  color: #473f3f;
  text-decoration: none;
}

mark {
  background-color: #f6f6f6;
  color: #473f3f;
  font-style: italic;
  font-weight: bold;
}

pre,
code,
kbd,
samp {
  font-family: "Monaco", "Courier New", monospace;
  color: #484040;
  background: #efefef;
  background: rgba(0, 0, 0, 0.07);
  padding: 0 2px;
  margin: 0 -2px;
}

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

small {
  font-size: 1.28rem;
}

hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 2rem 0;
  padding: 0;
}

ul,
ol dl {
  margin: 0 0 1.6rem;
  padding: 0 0 0 2rem;
}

li ul,
li ol {
  margin: 1rem 0;
}

ol {
  list-style-type: none;
  counter-reset: item;
}

ol ul {
  list-style-type: disc;
}

ol > li {
  display: table;
  counter-increment: item;
  margin-bottom: 0.6em;
}

ol > li:before {
  content: counters(item, ".") ". ";
  display: table-cell;
  padding-right: 0.6em;
}

li ol > li {
  margin: 0;
}

li ol > li:before {
  content: counters(item, ".") " ";
}

ul.inline,
ol.inline {
  list-style-type: none;
  margin-left: 0;
}

ul.inline > li,
ol.inline > li {
  display: inline-block;
  padding-left: 12px;
  padding-right: 12px;
}

/* ---- Buttons and links ---- */
a {
  color: #655DC6;
  word-break: break-word;
  transition: color 250ms ease-in-out, opacity 250ms ease-in-out, background-color 250ms ease-in-out, box-shadow 250ms ease-in-out;
}

a:hover,
a:active,
a:focus {
  color: #38318c;
}

/* Address `outline` inconsistency between Chrome and other browsers. */
a:focus {
  outline-color: indigo;
  outline-style: auto;
  outline-width: 2px;
  outline-offset: 4px;
}

/* Improve readability when focused and also mouse hovered in all browsers. */
a:active,
a:hover {
  outline: 0;
}

/* Include file type and size in document links */
a.document::after {
  content: " (" attr(data-ext) " " attr(data-size) ")";
}

.text-link {
  text-transform: uppercase;
  font-weight: 800;
  text-decoration: none;
  display: inline-grid;
  grid-auto-flow: column;
  column-gap: 0.5em;
  align-items: center;
}
.text-link path,
.text-link circle,
.text-link ellipse,
.text-link rect,
.text-link line,
.text-link polygon {
  fill: currentColor;
  transition: fill 250ms ease-in-out;
}

.button {
  text-align: center;
  vertical-align: middle;
  display: inline-grid;
  grid-auto-flow: column;
  column-gap: 0.5em;
  justify-content: center;
  align-items: center;
  white-space: normal;
  cursor: pointer;
  border-radius: var(--button-border-radius);
  outline: none;
  opacity: 1;
  position: relative;
  margin: 0;
  text-decoration: none;
  transition: background-color 250ms ease-in-out, border-color 250ms ease-in-out, color 250ms ease-in-out;
  padding-left: 2.5em;
  padding-right: 2.5em;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
  font-family: nunito-sans, helvetica neue, helvetica, roboto, noto, arial, sans-serif;
  font-weight: 700;
  line-height: var(--body-line-height);
  color: #ffffff;
  background: #2D1945;
  border: var(--button-border-width) solid #2D1945;
}
.button svg {
  max-height: 1em;
  max-width: 1em;
}
.button svg path,
.button svg circle,
.button svg ellipse,
.button svg rect,
.button svg line,
.button svg polygon {
  fill: #ffffff;
  transition: fill 250ms ease-in-out;
}
.button:hover, .button:focus, .button:active {
  background-color: #150b20;
  border-color: #150b20;
  color: #ffffff;
}
.button:hover svg path,
.button:hover svg circle,
.button:hover svg ellipse,
.button:hover svg rect,
.button:hover svg line,
.button:hover svg polygon, .button:focus svg path,
.button:focus svg circle,
.button:focus svg ellipse,
.button:focus svg rect,
.button:focus svg line,
.button:focus svg polygon, .button:active svg path,
.button:active svg circle,
.button:active svg ellipse,
.button:active svg rect,
.button:active svg line,
.button:active svg polygon {
  fill: #ffffff;
}
.button:focus {
  outline-color: indigo;
  outline-style: auto;
  outline-width: 2px;
}

/* - Button icons - */
.button__icon {
  display: inline-block;
  vertical-align: top;
}
.button__icon--right {
  margin-left: 0.25em;
  margin-right: -0.25em;
}
.button__icon--left {
  margin-right: 0.25em;
  margin-left: -0.25em;
}
.button__icon svg {
  height: 1em;
  width: 1em;
}
.button__icon svg path,
.button__icon svg circle,
.button__icon svg ellipse,
.button__icon svg rect,
.button__icon svg line,
.button__icon svg polygon {
  fill: #ffffff;
  transition: fill 250ms ease-in-out;
}

/* ---- Button Colours ---- */
.button--primary {
  text-align: center;
  vertical-align: middle;
  display: inline-grid;
  grid-auto-flow: column;
  column-gap: 0.5em;
  justify-content: center;
  align-items: center;
  white-space: normal;
  cursor: pointer;
  border-radius: var(--button-border-radius);
  outline: none;
  opacity: 1;
  position: relative;
  margin: 0;
  text-decoration: none;
  transition: background-color 250ms ease-in-out, border-color 250ms ease-in-out, color 250ms ease-in-out;
  padding-left: 2.5em;
  padding-right: 2.5em;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
  font-family: nunito-sans, helvetica neue, helvetica, roboto, noto, arial, sans-serif;
  font-weight: 700;
  line-height: var(--body-line-height);
  color: #ffffff;
  background: #2D1945;
  border: var(--button-border-width) solid #2D1945;
}
.button--primary svg {
  max-height: 1em;
  max-width: 1em;
}
.button--primary svg path,
.button--primary svg circle,
.button--primary svg ellipse,
.button--primary svg rect,
.button--primary svg line,
.button--primary svg polygon {
  fill: #ffffff;
  transition: fill 250ms ease-in-out;
}
.button--primary:hover, .button--primary:focus, .button--primary:active {
  background-color: #150b20;
  border-color: #150b20;
  color: #ffffff;
}
.button--primary:hover svg path,
.button--primary:hover svg circle,
.button--primary:hover svg ellipse,
.button--primary:hover svg rect,
.button--primary:hover svg line,
.button--primary:hover svg polygon, .button--primary:focus svg path,
.button--primary:focus svg circle,
.button--primary:focus svg ellipse,
.button--primary:focus svg rect,
.button--primary:focus svg line,
.button--primary:focus svg polygon, .button--primary:active svg path,
.button--primary:active svg circle,
.button--primary:active svg ellipse,
.button--primary:active svg rect,
.button--primary:active svg line,
.button--primary:active svg polygon {
  fill: #ffffff;
}
.button--primary:focus {
  outline-color: indigo;
  outline-style: auto;
  outline-width: 2px;
}

.button--secondary {
  text-align: center;
  vertical-align: middle;
  display: inline-grid;
  grid-auto-flow: column;
  column-gap: 0.5em;
  justify-content: center;
  align-items: center;
  white-space: normal;
  cursor: pointer;
  border-radius: var(--button-border-radius);
  outline: none;
  opacity: 1;
  position: relative;
  margin: 0;
  text-decoration: none;
  transition: background-color 250ms ease-in-out, border-color 250ms ease-in-out, color 250ms ease-in-out;
  padding-left: 2.5em;
  padding-right: 2.5em;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
  font-family: nunito-sans, helvetica neue, helvetica, roboto, noto, arial, sans-serif;
  font-weight: 700;
  line-height: var(--body-line-height);
  color: #ffffff;
  background: #D05559;
  border: var(--button-border-width) solid #D05559;
}
.button--secondary svg {
  max-height: 1em;
  max-width: 1em;
}
.button--secondary svg path,
.button--secondary svg circle,
.button--secondary svg ellipse,
.button--secondary svg rect,
.button--secondary svg line,
.button--secondary svg polygon {
  fill: #ffffff;
  transition: fill 250ms ease-in-out;
}
.button--secondary:hover, .button--secondary:focus, .button--secondary:active {
  background-color: #be3439;
  border-color: #be3439;
  color: #ffffff;
}
.button--secondary:hover svg path,
.button--secondary:hover svg circle,
.button--secondary:hover svg ellipse,
.button--secondary:hover svg rect,
.button--secondary:hover svg line,
.button--secondary:hover svg polygon, .button--secondary:focus svg path,
.button--secondary:focus svg circle,
.button--secondary:focus svg ellipse,
.button--secondary:focus svg rect,
.button--secondary:focus svg line,
.button--secondary:focus svg polygon, .button--secondary:active svg path,
.button--secondary:active svg circle,
.button--secondary:active svg ellipse,
.button--secondary:active svg rect,
.button--secondary:active svg line,
.button--secondary:active svg polygon {
  fill: #ffffff;
}
.button--secondary:focus {
  outline-color: indigo;
  outline-style: auto;
  outline-width: 2px;
}

.button--alternative {
  text-align: center;
  vertical-align: middle;
  display: inline-grid;
  grid-auto-flow: column;
  column-gap: 0.5em;
  justify-content: center;
  align-items: center;
  white-space: normal;
  cursor: pointer;
  border-radius: var(--button-border-radius);
  outline: none;
  opacity: 1;
  position: relative;
  margin: 0;
  text-decoration: none;
  transition: background-color 250ms ease-in-out, border-color 250ms ease-in-out, color 250ms ease-in-out;
  padding-left: 2.5em;
  padding-right: 2.5em;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
  font-family: nunito-sans, helvetica neue, helvetica, roboto, noto, arial, sans-serif;
  font-weight: 700;
  line-height: var(--body-line-height);
  color: #ffffff;
  background: #00847F;
  border: var(--button-border-width) solid #00847F;
}
.button--alternative svg {
  max-height: 1em;
  max-width: 1em;
}
.button--alternative svg path,
.button--alternative svg circle,
.button--alternative svg ellipse,
.button--alternative svg rect,
.button--alternative svg line,
.button--alternative svg polygon {
  fill: #ffffff;
  transition: fill 250ms ease-in-out;
}
.button--alternative:hover, .button--alternative:focus, .button--alternative:active {
  background-color: #00514e;
  border-color: #00514e;
  color: #ffffff;
}
.button--alternative:hover svg path,
.button--alternative:hover svg circle,
.button--alternative:hover svg ellipse,
.button--alternative:hover svg rect,
.button--alternative:hover svg line,
.button--alternative:hover svg polygon, .button--alternative:focus svg path,
.button--alternative:focus svg circle,
.button--alternative:focus svg ellipse,
.button--alternative:focus svg rect,
.button--alternative:focus svg line,
.button--alternative:focus svg polygon, .button--alternative:active svg path,
.button--alternative:active svg circle,
.button--alternative:active svg ellipse,
.button--alternative:active svg rect,
.button--alternative:active svg line,
.button--alternative:active svg polygon {
  fill: #ffffff;
}
.button--alternative:focus {
  outline-color: indigo;
  outline-style: auto;
  outline-width: 2px;
}

.button--accent-01 {
  text-align: center;
  vertical-align: middle;
  display: inline-grid;
  grid-auto-flow: column;
  column-gap: 0.5em;
  justify-content: center;
  align-items: center;
  white-space: normal;
  cursor: pointer;
  border-radius: var(--button-border-radius);
  outline: none;
  opacity: 1;
  position: relative;
  margin: 0;
  text-decoration: none;
  transition: background-color 250ms ease-in-out, border-color 250ms ease-in-out, color 250ms ease-in-out;
  padding-left: 2.5em;
  padding-right: 2.5em;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
  font-family: nunito-sans, helvetica neue, helvetica, roboto, noto, arial, sans-serif;
  font-weight: 700;
  line-height: var(--body-line-height);
  color: #ffffff;
  background: #655DC6;
  border: var(--button-border-width) solid #655DC6;
}
.button--accent-01 svg {
  max-height: 1em;
  max-width: 1em;
}
.button--accent-01 svg path,
.button--accent-01 svg circle,
.button--accent-01 svg ellipse,
.button--accent-01 svg rect,
.button--accent-01 svg line,
.button--accent-01 svg polygon {
  fill: #ffffff;
  transition: fill 250ms ease-in-out;
}
.button--accent-01:hover, .button--accent-01:focus, .button--accent-01:active {
  background-color: #473eb2;
  border-color: #473eb2;
  color: #ffffff;
}
.button--accent-01:hover svg path,
.button--accent-01:hover svg circle,
.button--accent-01:hover svg ellipse,
.button--accent-01:hover svg rect,
.button--accent-01:hover svg line,
.button--accent-01:hover svg polygon, .button--accent-01:focus svg path,
.button--accent-01:focus svg circle,
.button--accent-01:focus svg ellipse,
.button--accent-01:focus svg rect,
.button--accent-01:focus svg line,
.button--accent-01:focus svg polygon, .button--accent-01:active svg path,
.button--accent-01:active svg circle,
.button--accent-01:active svg ellipse,
.button--accent-01:active svg rect,
.button--accent-01:active svg line,
.button--accent-01:active svg polygon {
  fill: #ffffff;
}
.button--accent-01:focus {
  outline-color: indigo;
  outline-style: auto;
  outline-width: 2px;
}

.button--accent-02 {
  text-align: center;
  vertical-align: middle;
  display: inline-grid;
  grid-auto-flow: column;
  column-gap: 0.5em;
  justify-content: center;
  align-items: center;
  white-space: normal;
  cursor: pointer;
  border-radius: var(--button-border-radius);
  outline: none;
  opacity: 1;
  position: relative;
  margin: 0;
  text-decoration: none;
  transition: background-color 250ms ease-in-out, border-color 250ms ease-in-out, color 250ms ease-in-out;
  padding-left: 2.5em;
  padding-right: 2.5em;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
  font-family: nunito-sans, helvetica neue, helvetica, roboto, noto, arial, sans-serif;
  font-weight: 700;
  line-height: var(--body-line-height);
  color: #2D1945;
  background: #FFB548;
  border: var(--button-border-width) solid #FFB548;
}
.button--accent-02 svg {
  max-height: 1em;
  max-width: 1em;
}
.button--accent-02 svg path,
.button--accent-02 svg circle,
.button--accent-02 svg ellipse,
.button--accent-02 svg rect,
.button--accent-02 svg line,
.button--accent-02 svg polygon {
  fill: #2D1945;
  transition: fill 250ms ease-in-out;
}
.button--accent-02:hover, .button--accent-02:focus, .button--accent-02:active {
  background-color: #ffa015;
  border-color: #ffa015;
  color: #2D1945;
}
.button--accent-02:hover svg path,
.button--accent-02:hover svg circle,
.button--accent-02:hover svg ellipse,
.button--accent-02:hover svg rect,
.button--accent-02:hover svg line,
.button--accent-02:hover svg polygon, .button--accent-02:focus svg path,
.button--accent-02:focus svg circle,
.button--accent-02:focus svg ellipse,
.button--accent-02:focus svg rect,
.button--accent-02:focus svg line,
.button--accent-02:focus svg polygon, .button--accent-02:active svg path,
.button--accent-02:active svg circle,
.button--accent-02:active svg ellipse,
.button--accent-02:active svg rect,
.button--accent-02:active svg line,
.button--accent-02:active svg polygon {
  fill: #2D1945;
}
.button--accent-02:focus {
  outline-color: indigo;
  outline-style: auto;
  outline-width: 2px;
}

.button--warning {
  text-align: center;
  vertical-align: middle;
  display: inline-grid;
  grid-auto-flow: column;
  column-gap: 0.5em;
  justify-content: center;
  align-items: center;
  white-space: normal;
  cursor: pointer;
  border-radius: var(--button-border-radius);
  outline: none;
  opacity: 1;
  position: relative;
  margin: 0;
  text-decoration: none;
  transition: background-color 250ms ease-in-out, border-color 250ms ease-in-out, color 250ms ease-in-out;
  padding-left: 2.5em;
  padding-right: 2.5em;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
  font-family: nunito-sans, helvetica neue, helvetica, roboto, noto, arial, sans-serif;
  font-weight: 700;
  line-height: var(--body-line-height);
  color: #ffffff;
  background: #FCB34C;
  border: var(--button-border-width) solid #FCB34C;
}
.button--warning svg {
  max-height: 1em;
  max-width: 1em;
}
.button--warning svg path,
.button--warning svg circle,
.button--warning svg ellipse,
.button--warning svg rect,
.button--warning svg line,
.button--warning svg polygon {
  fill: #ffffff;
  transition: fill 250ms ease-in-out;
}
.button--warning:hover, .button--warning:focus, .button--warning:active {
  background-color: #fb9e1a;
  border-color: #fb9e1a;
  color: #ffffff;
}
.button--warning:hover svg path,
.button--warning:hover svg circle,
.button--warning:hover svg ellipse,
.button--warning:hover svg rect,
.button--warning:hover svg line,
.button--warning:hover svg polygon, .button--warning:focus svg path,
.button--warning:focus svg circle,
.button--warning:focus svg ellipse,
.button--warning:focus svg rect,
.button--warning:focus svg line,
.button--warning:focus svg polygon, .button--warning:active svg path,
.button--warning:active svg circle,
.button--warning:active svg ellipse,
.button--warning:active svg rect,
.button--warning:active svg line,
.button--warning:active svg polygon {
  fill: #ffffff;
}
.button--warning:focus {
  outline-color: indigo;
  outline-style: auto;
  outline-width: 2px;
}

.button--neutral {
  text-align: center;
  vertical-align: middle;
  display: inline-grid;
  grid-auto-flow: column;
  column-gap: 0.5em;
  justify-content: center;
  align-items: center;
  white-space: normal;
  cursor: pointer;
  border-radius: var(--button-border-radius);
  outline: none;
  opacity: 1;
  position: relative;
  margin: 0;
  text-decoration: none;
  transition: background-color 250ms ease-in-out, border-color 250ms ease-in-out, color 250ms ease-in-out;
  padding-left: 2.5em;
  padding-right: 2.5em;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
  font-family: nunito-sans, helvetica neue, helvetica, roboto, noto, arial, sans-serif;
  font-weight: 700;
  line-height: var(--body-line-height);
  color: #3D3935;
  background: #DADADA;
  border: var(--button-border-width) solid #DADADA;
}
.button--neutral svg {
  max-height: 1em;
  max-width: 1em;
}
.button--neutral svg path,
.button--neutral svg circle,
.button--neutral svg ellipse,
.button--neutral svg rect,
.button--neutral svg line,
.button--neutral svg polygon {
  fill: #3D3935;
  transition: fill 250ms ease-in-out;
}
.button--neutral:hover, .button--neutral:focus, .button--neutral:active {
  background-color: #c1c1c1;
  border-color: #c1c1c1;
  color: #3D3935;
}
.button--neutral:hover svg path,
.button--neutral:hover svg circle,
.button--neutral:hover svg ellipse,
.button--neutral:hover svg rect,
.button--neutral:hover svg line,
.button--neutral:hover svg polygon, .button--neutral:focus svg path,
.button--neutral:focus svg circle,
.button--neutral:focus svg ellipse,
.button--neutral:focus svg rect,
.button--neutral:focus svg line,
.button--neutral:focus svg polygon, .button--neutral:active svg path,
.button--neutral:active svg circle,
.button--neutral:active svg ellipse,
.button--neutral:active svg rect,
.button--neutral:active svg line,
.button--neutral:active svg polygon {
  fill: #3D3935;
}
.button--neutral:focus {
  outline-color: indigo;
  outline-style: auto;
  outline-width: 2px;
}

.button--grey {
  text-align: center;
  vertical-align: middle;
  display: inline-grid;
  grid-auto-flow: column;
  column-gap: 0.5em;
  justify-content: center;
  align-items: center;
  white-space: normal;
  cursor: pointer;
  border-radius: var(--button-border-radius);
  outline: none;
  opacity: 1;
  position: relative;
  margin: 0;
  text-decoration: none;
  transition: background-color 250ms ease-in-out, border-color 250ms ease-in-out, color 250ms ease-in-out;
  padding-left: 2.5em;
  padding-right: 2.5em;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
  font-family: nunito-sans, helvetica neue, helvetica, roboto, noto, arial, sans-serif;
  font-weight: 700;
  line-height: var(--body-line-height);
  color: #ffffff;
  background: #636363;
  border: var(--button-border-width) solid #636363;
}
.button--grey svg {
  max-height: 1em;
  max-width: 1em;
}
.button--grey svg path,
.button--grey svg circle,
.button--grey svg ellipse,
.button--grey svg rect,
.button--grey svg line,
.button--grey svg polygon {
  fill: #ffffff;
  transition: fill 250ms ease-in-out;
}
.button--grey:hover, .button--grey:focus, .button--grey:active {
  background-color: #4a4a4a;
  border-color: #4a4a4a;
  color: #ffffff;
}
.button--grey:hover svg path,
.button--grey:hover svg circle,
.button--grey:hover svg ellipse,
.button--grey:hover svg rect,
.button--grey:hover svg line,
.button--grey:hover svg polygon, .button--grey:focus svg path,
.button--grey:focus svg circle,
.button--grey:focus svg ellipse,
.button--grey:focus svg rect,
.button--grey:focus svg line,
.button--grey:focus svg polygon, .button--grey:active svg path,
.button--grey:active svg circle,
.button--grey:active svg ellipse,
.button--grey:active svg rect,
.button--grey:active svg line,
.button--grey:active svg polygon {
  fill: #ffffff;
}
.button--grey:focus {
  outline-color: indigo;
  outline-style: auto;
  outline-width: 2px;
}

.button--positive {
  text-align: center;
  vertical-align: middle;
  display: inline-grid;
  grid-auto-flow: column;
  column-gap: 0.5em;
  justify-content: center;
  align-items: center;
  white-space: normal;
  cursor: pointer;
  border-radius: var(--button-border-radius);
  outline: none;
  opacity: 1;
  position: relative;
  margin: 0;
  text-decoration: none;
  transition: background-color 250ms ease-in-out, border-color 250ms ease-in-out, color 250ms ease-in-out;
  padding-left: 2.5em;
  padding-right: 2.5em;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
  font-family: nunito-sans, helvetica neue, helvetica, roboto, noto, arial, sans-serif;
  font-weight: 700;
  line-height: var(--body-line-height);
  color: #ffffff;
  background: #DCEBC4;
  border: var(--button-border-width) solid #DCEBC4;
}
.button--positive svg {
  max-height: 1em;
  max-width: 1em;
}
.button--positive svg path,
.button--positive svg circle,
.button--positive svg ellipse,
.button--positive svg rect,
.button--positive svg line,
.button--positive svg polygon {
  fill: #ffffff;
  transition: fill 250ms ease-in-out;
}
.button--positive:hover, .button--positive:focus, .button--positive:active {
  background-color: #c5de9e;
  border-color: #c5de9e;
  color: #ffffff;
}
.button--positive:hover svg path,
.button--positive:hover svg circle,
.button--positive:hover svg ellipse,
.button--positive:hover svg rect,
.button--positive:hover svg line,
.button--positive:hover svg polygon, .button--positive:focus svg path,
.button--positive:focus svg circle,
.button--positive:focus svg ellipse,
.button--positive:focus svg rect,
.button--positive:focus svg line,
.button--positive:focus svg polygon, .button--positive:active svg path,
.button--positive:active svg circle,
.button--positive:active svg ellipse,
.button--positive:active svg rect,
.button--positive:active svg line,
.button--positive:active svg polygon {
  fill: #ffffff;
}
.button--positive:focus {
  outline-color: indigo;
  outline-style: auto;
  outline-width: 2px;
}

.button--flat {
  text-align: center;
  vertical-align: middle;
  display: inline-grid;
  grid-auto-flow: column;
  column-gap: 0.5em;
  justify-content: center;
  align-items: center;
  white-space: normal;
  cursor: pointer;
  border-radius: var(--button-border-radius);
  outline: none;
  opacity: 1;
  position: relative;
  margin: 0;
  text-decoration: none;
  transition: background-color 250ms ease-in-out, border-color 250ms ease-in-out, color 250ms ease-in-out;
  padding-left: 2.5em;
  padding-right: 2.5em;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
  font-family: nunito-sans, helvetica neue, helvetica, roboto, noto, arial, sans-serif;
  font-weight: 700;
  line-height: var(--body-line-height);
  color: #3D3935;
  background: rgba(0, 0, 0, 0.07);
  border: var(--button-border-width) solid rgba(0, 0, 0, 0.07);
}
.button--flat svg {
  max-height: 1em;
  max-width: 1em;
}
.button--flat svg path,
.button--flat svg circle,
.button--flat svg ellipse,
.button--flat svg rect,
.button--flat svg line,
.button--flat svg polygon {
  fill: #3D3935;
  transition: fill 250ms ease-in-out;
}
.button--flat:hover, .button--flat:focus, .button--flat:active {
  background-color: rgba(0, 0, 0, 0.07);
  border-color: rgba(0, 0, 0, 0.07);
  color: #3D3935;
}
.button--flat:hover svg path,
.button--flat:hover svg circle,
.button--flat:hover svg ellipse,
.button--flat:hover svg rect,
.button--flat:hover svg line,
.button--flat:hover svg polygon, .button--flat:focus svg path,
.button--flat:focus svg circle,
.button--flat:focus svg ellipse,
.button--flat:focus svg rect,
.button--flat:focus svg line,
.button--flat:focus svg polygon, .button--flat:active svg path,
.button--flat:active svg circle,
.button--flat:active svg ellipse,
.button--flat:active svg rect,
.button--flat:active svg line,
.button--flat:active svg polygon {
  fill: #3D3935;
}
.button--flat:focus {
  outline-color: indigo;
  outline-style: auto;
  outline-width: 2px;
}
.button--flat:hover, .button--flat:focus, .button--flat:active {
  background-color: #3D3935;
  color: #ffffff;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

/* ---- Sizes ---- */
.button--tiny {
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  font-size: 66.666%;
}

/* Small */
.button--small {
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  font-size: 80%;
}

@media screen and (min-width: 35em) {
  .button--large {
    padding-left: 3em;
    padding-right: 3em;
    padding-top: 1em;
    padding-bottom: 1em;
    font-size: 150%;
  }
}

/* ---- Button Variations ---- */
.button--block {
  display: block;
  width: 100%;
}

.button[disabled],
.button--disabled {
  border: none;
  box-shadow: none;
  cursor: default;
  opacity: 0.4;
}
.button[disabled]:hover, .button[disabled]:focus, .button[disabled]:active,
.button--disabled:hover,
.button--disabled:focus,
.button--disabled:active {
  background-color: inherit;
  box-shadow: none;
  color: inherit;
  opacity: 0.4;
}

/* Firefox: Get rid of the inner focus border */
.button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/* ---- Images and Icons ---- */
.icon {
  display: inline-block;
  vertical-align: middle;
}

.icon svg {
  width: 16px;
  height: 16px;
}

.icon--24px svg {
  width: 24px;
  height: 24px;
}

.icon--32px svg {
  width: 32px;
  height: 32px;
}

.icon--40px svg {
  width: 40px;
  height: 40px;
}

.icon--48px svg {
  width: 48px;
  height: 48px;
}

.icon--52px svg {
  width: 52px;
  height: 52px;
}

.icon--60px svg {
  width: 60px;
  height: 60px;
}

.icon--68px svg {
  width: 68px;
  height: 68px;
}

/* Icon system colours */
.icon--primary svg path {
  fill: #2D1945;
}

.icon--secondary svg path {
  fill: #D05559;
}

.icon--alternative svg path {
  fill: #00847F;
}

.icon--white svg path {
  fill: #ffffff;
}

.icon--accent-01 svg path {
  fill: #655DC6;
}

.icon--accent-02 svg path {
  fill: #FFB548;
}

/* ---- Video ---- */
.tinymce-media-iframe,
.widget-Video {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  clear: both;
}

.tinymce-media-iframe iframe,
.widget-Video iframe,
.widget-Video object,
.widget-Video embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.tinymce-media-iframe {
  margin: 2rem 0;
}

iframe {
  border: 0;
}

/* ---- Tables ---- */
table {
  border-collapse: collapse;
  border: 1px solid #7f7f7f;
}

th,
td {
  padding: 5px 10px;
  border: 1px solid #7f7f7f;
}

th,
thead td {
  background: #DADADA;
}

.table--content-standard {
  width: 100%;
  margin: 2rem 0;
}

.table--content-standard caption {
  text-align: left;
  padding-bottom: 13px;
}

.table--content-standard th,
.table--content-standard thead td {
  font-weight: bold;
  background-color: #DADADA;
  font-size: 1.7rem;
}

.table--content-standard td {
  background-color: #ffffff;
}

.table--content-standard th,
.table--content-standard td {
  border: 1px solid #9c9c9c;
  padding: 12px 15px;
  text-align: left;
}

.table--content-small {
  font-size: 1.3rem;
}

.table--content-small th,
.table--content-small td {
  padding: 5px 10px;
}

.table--content-small th,
.table--content-small thead td {
  font-size: 1.4rem;
}

@media only screen and (max-width: 600px) {
  .table--responsive {
    font-size: 1.4rem;
  }

  .table--responsive,
.table--responsive tbody,
.table--responsive tr {
    display: block;
    border: none;
  }

  .table--responsive thead,
.table--responsive th {
    display: none;
  }

  .table--responsive tr {
    display: table;
    width: 100%;
  }

  .table--responsive tr.table--responsive__first-row {
    display: none;
  }

  .table--responsive td {
    display: table-row;
    padding: 0;
  }

  .table--responsive td[data-title]:before {
    content: attr(data-title);
    font-weight: bold;
    background-color: #F0F0F0;
    border-right: 1px solid #d7d7d7;
  }

  .table--responsive td[data-title] {
    border-left: 1px solid #d7d7d7;
    border-right: 1px solid #d7d7d7;
    border-top: 1px solid #d7d7d7;
  }

  .table--responsive tbody tr {
    margin-bottom: 20px;
    border-bottom: 1px solid #d7d7d7;
  }

  .table--responsive td[data-title]:before,
.table--responsive__content {
    padding: 5px 15px;
    display: table-cell;
  }
}
/* ---- Helpers ---- */
.clear {
  height: 0;
  clear: both;
  display: block;
}

.-clearfix:before, .-clearfix:after {
  content: " ";
  display: table;
}
.-clearfix:after {
  clear: both;
}

.-ir {
  background-color: transparent;
  border: 0;
  overflow: hidden;
}

.-ir:before {
  content: "";
  display: block;
  width: 0;
  height: 150%;
}

/* ---- Hidden ---- */
.-hidden {
  display: none !important;
  visibility: hidden;
}

/* ---- Invisible ---- */
.-invisible {
  visibility: hidden;
}

/* ---- Visibility Hidden ---- */
.-vis-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.-vis-hidden.focusable:active,
.-vis-hidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

/* Angle Brackets */
.-r-arrow-after:after {
  content: " >";
}

.-r-arrow-before:before {
  content: "> ";
}

.-l-arrow-after:after {
  content: " <";
}

.-l-arrow-before:before {
  content: "< ";
}

/* Ellipsis */
.ellipsis-2:after {
  content: "..";
}

.ellipsis-3:after {
  content: "...";
}

/* ------------------------------------------------------
**** MOLECULES
------------------------------------------------------ */
/* ---- Text ---- */
blockquote {
  margin: 1.5rem 0;
  padding: 20px 25px 20px;
  background: #f6f6f6;
  font-style: italic;
  position: relative;
  clear: both;
  border-left: 4px solid #00847F;
}

blockquote *:first-child {
  margin-top: 0;
}

blockquote *:last-child {
  margin-bottom: 0;
}

@media only screen and (min-width: 62em) {
  /* 992px */
  .blockquote--left {
    float: left;
    width: 45%;
    margin-left: 0px;
    margin-right: 20px;
    clear: right;
  }

  .blockquote--right {
    float: right;
    width: 45%;
    margin-left: 20px;
    margin-right: 0px;
    clear: left;
  }
}
.highlight,
.highlight--right,
.highlight--left {
  margin: 0 0 1.5rem;
  padding: 30px;
  background: #f6f6f6;
  clear: both;
}

.highlight--error {
  background: #E9C2B6;
}

/* BP medium */
@media only screen and (min-width: 62em) {
  /* 992px */
  .highlight--left {
    float: left;
    width: 45%;
    margin-right: 20px;
    clear: right;
  }

  .highlight--right {
    float: right;
    width: 45%;
    margin-left: 20px;
    clear: left;
  }
}
.highlight > *:last-child,
.highlight--right > *:last-child,
.highlight--left > *:last-child {
  margin-bottom: 0;
}

.js .expando {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.js .expando p:last-of-type {
  margin-bottom: 0;
}

/* - Open button - */
.expando-open {
  -webkit-appearance: none;
  border: none;
  outline: none;
  background: none;
  padding: 6px 0 3px 40px;
  font-family: nunito-sans, helvetica neue, helvetica, roboto, noto, arial, sans-serif;
  color: #655DC6;
  position: relative;
  cursor: pointer;
  display: block;
  margin: 0 0 12px;
  font-size: inherit;
  line-height: inherit;
  transition: color 250ms ease-in-out;
}

.expando-open__icon {
  position: absolute;
  top: 50%;
  left: 0;
  background-color: #F5f5f5;
  width: 26px;
  height: 26px;
  transform: translate(0, -50%);
}

.expando-open__icon:before,
.expando-open__icon:after {
  content: " ";
  width: 14px;
  height: 2px;
  background-color: #636363;
  display: block;
  position: absolute;
  top: 12px;
  left: 0;
  right: 0;
  margin: 0 auto;
  transition: all 250ms ease-in-out;
}

.expando-open__icon:after {
  transform: rotate(90deg);
}

/* Hover */
.expando-open:hover,
.expando-open:focus {
  color: #473eb2;
  text-decoration: underline;
}

.expando-open:hover .expando-open__icon {
  background-color: #DADADA;
}

.expando-open:hover .expando-open__icon:before,
.expando-open:hover .expando-open__icon:after {
  background-color: #2b2b2b;
}

/* - Close button - */
.expando__close {
  -webkit-appearance: none;
  border: none;
  outline: none;
  background: none;
  text-indent: -9999px;
  padding: 0;
  height: 26px;
  width: 26px;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

.expando__close:before,
.expando__close:after {
  content: " ";
  width: 14px;
  height: 2px;
  background-color: #636363;
  display: block;
  position: absolute;
  top: 12px;
  left: 0;
  right: 0;
  margin: 0 auto;
  transition: transform 200ms ease;
}

.expando__close:before {
  transform: rotate(-45deg);
}

.expando__close:after {
  transform: rotate(45deg);
}

/* Hover */
.expando__close:hover:before,
.expando__close:hover:after {
  background-color: #2b2b2b;
}

/* - Expanded - */
.expando.expando--expanded {
  clip: auto;
  height: auto;
  margin: 20px 0 30px;
  overflow: visible;
  position: relative;
  width: auto;
  padding: 25px 45px;
  background-color: #F5f5f5;
  animation: fadeInDown 200ms ease;
  animation-fill-mode: forwards;
}

.expando-open--expanded .expando-open__icon:before {
  transform: rotate(-45deg);
}

.expando-open--expanded .expando-open__icon:after {
  transform: rotate(45deg);
}

.messages,
ul.messages {
  list-style: none;
  margin: 1em 0;
  padding: 0;
}
.messages li,
ul.messages li {
  margin: 0 0 0.5em;
  padding: 8px 24px;
  text-align: left;
  border-radius: 40px;
  color: #0f0f0f !important;
  font-family: nunito-sans, helvetica neue, helvetica, roboto, noto, arial, sans-serif;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: normal;
}
.messages .error,
ul.messages .error {
  background: #E9C2B6;
}
.messages .confirm,
ul.messages .confirm {
  background: #DCEBC4;
}
.messages .warning,
ul.messages .warning {
  background: #FFE1BB;
}
.messages a,
ul.messages a {
  color: #FFF;
}

.attention-pill {
  display: inline-grid;
  grid-auto-flow: column;
  column-gap: 10px;
  align-items: center;
  background-color: #FED9A9;
  padding: 10px 12px 10px 10px;
  border-radius: 60px;
  margin-bottom: 0;
}
.attention-pill__icon {
  display: block;
  background-color: #FFB548;
  width: 40px;
  height: 40px;
  border-radius: 40px;
}
.attention-pill__copy {
  color: #3D3935;
  margin-bottom: 0;
  font-size: var(--centi-font-size);
}

:root {
  --tooltip-padding: 10px;
}

/* ---- Tooltips ---- */
.tooltip {
  position: relative;
}

.tooltip__title {
  display: inline-block;
  position: relative;
  z-index: 1;
  opacity: 1;
  font: inherit;
  transition: opacity 100ms ease-in-out;
}
.tooltip__title a {
  color: inherit;
}
.tooltip__title a:hover, .tooltip__title a:focus {
  color: #3D3935;
  text-decoration: none;
}

.tooltip__content {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #e7e7e7;
  border: 1px solid #DADADA;
  padding: 0;
  font-size: var(--body-font-size);
  border-radius: 4px;
  margin-top: 7px;
  text-align: left;
  color: #636363;
  z-index: 100;
  font-weight: 400;
  white-space: normal;
  text-transform: none;
  font-size: var(--body-font-size);
  display: none;
  max-width: 270px;
  min-width: 120px;
}
@media screen and (min-width: 48em) {
  .tooltip__content {
    min-width: 160px;
  }
}
@media screen and (min-width: 75em) {
  .tooltip__content {
    max-width: 400px;
    min-width: 280px;
  }
}
.tooltip__content p {
  font-size: 75%;
  margin-bottom: 0.25em;
}
.tooltip__content > *:last-child {
  margin-bottom: 0;
}
.tooltip__content small {
  font-size: 1.1rem;
}
.tooltip__content:before, .tooltip__content:after {
  content: " ";
  position: absolute;
  bottom: 100%;
  left: 14px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 5px 5px 5px;
  border-color: transparent transparent #e7e7e7 transparent;
  z-index: 1;
}
.tooltip__content:after {
  left: 13px;
  border-width: 0 6px 6px 6px;
  border-color: transparent transparent #DADADA transparent;
  z-index: 0;
}

/* Center align */
.tooltip--center .tooltip__content {
  left: 50%;
  margin-right: -50%;
  transform: translateX(-50%);
}

.tooltip--center .tooltip__content:before,
.tooltip--center .tooltip__content:after {
  left: 0;
  right: 0;
  margin: 0 auto;
}

/* Right align */
.tooltip--right .tooltip__content {
  right: 0;
  left: auto;
}

.tooltip--right .tooltip__content:before {
  right: 14px;
  left: auto;
}

/* Up */
.tooltip--up .tooltip__content {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 7px;
}

.tooltip--up .tooltip__content:before {
  border-width: 5px 5px 0 5px;
  border-color: #ffffff transparent transparent transparent;
  top: 100%;
}

/* Tooltip animations for hover state */
/* Fade in */
.tooltip:hover .tooltip__content,
.tooltip__title:hover + .tooltip__content {
  display: block;
  animation: fadeIn 200ms ease;
  animation-fill-mode: forwards;
}

/* Transition up */
.tooltip--transition-up .tooltip__content {
  -webkit-animation-name: fadeInUpMargin;
  animation-name: fadeInUpMargin;
}

/* Info icon */
.tooltip__icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background: #9c9c9c;
  color: #636363;
  width: 1em;
  height: 1em;
  cursor: pointer;
  text-align: center;
  -webkit-appearance: none;
  border: none;
  outline: none;
  padding: 0;
  margin: 0 0.2em;
}

.tooltip--icon .tooltip__icon {
  display: inline-block;
}

.tooltip__icon svg {
  display: block;
  width: 0.66em;
  height: 0.66em;
}
.tooltip__icon svg path {
  fill: #ffffff;
}

.tooltip--icon .tooltip__title {
  border-bottom: none;
  margin-right: 7px;
}

.tooltip--icon .tooltip__content {
  left: -8px;
  right: auto;
}

.tooltip.tooltip--icon.tooltip--center .tooltip__content {
  right: auto;
  left: 50%;
}

.tooltip--icon.tooltip--right .tooltip__content {
  right: -8px;
  left: auto;
}

/* Disabled */
.tooltip--disabled .tooltip__content {
  display: none;
}

.tooltip__header {
  background-color: #e7e7e7;
  border-bottom: 1px solid #DADADA;
  padding: var(--tooltip-padding);
}
.tooltip__header .tooltip__header__title {
  font-weight: 700;
  color: #2b2b2b;
  font-size: var(--body-font-size);
}

.tooltip__body {
  padding: var(--tooltip-padding);
}

.tooltip__footer {
  padding: var(--tooltip-padding);
}

.tooltip__body + .tooltip__footer {
  border-top: 1px solid #DADADA;
}

/* ---- Lists ---- */
.sidebar-linklist {
  margin: 0;
  padding: 0;
}

.sidebar-linklist__item {
  border-bottom: 1px solid #d6d6d6;
  position: relative;
}

.sidebar-linklist__item__button {
  display: block;
  background: none;
  border: none;
  padding: 10px 18px 10px 1.5em;
}

.sidebar-linklist__item__button:before {
  content: ">";
  position: absolute;
  left: 0;
  color: #000;
  top: calc(10px + 0.1em);
  -webkit-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transform-origin: center;
  transform-origin: center;
}

.sidebar-linklist__item--expanded .sidebar-linklist__item__button:before,
.sidebar-linklist__item:hover .sidebar-linklist__item__button:before {
  left: 0.5em;
}

.sidebar-linklist__item--expanded .sidebar-linklist__item__button:before {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.sidebar-linklist__item__sublist {
  list-style: none;
  margin: 0;
  padding: 0;
}

.sidebar-linklist__item__sublist__item {
  list-style: none;
}

.sidebar-linklist__item__sublist__item__link:before {
  content: "– ";
  color: #000;
}

.sidebar-linklist__item__sublist__item__link {
  display: block;
  text-decoration: none;
  padding: 0.25em 0.25em 0.25em 1.5em;
  -webkit-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}

.sidebar-linklist__item__sublist__item__link:hover,
.sidebar-linklist__item__sublist__item__link:focus,
.sidebar-linklist__item__sublist__item__link:active {
  background: rgba(0, 0, 0, 0.05);
}

.social-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-auto-flow: column;
  gap: 24px;
  justify-content: flex-start;
}
.social-list--right {
  justify-content: flex-end;
}
.social-list--center {
  justify-content: center;
}
.social-list__item {
  display: inline-block;
}
.social-list__item a {
  color: inherit;
  display: block;
}
.social-list svg * {
  fill: currentColor;
}
.social-list--primary svg * {
  fill: rgba(45, 25, 69, 0.7);
}
.social-list--circle a {
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  padding: 8px;
  background-color: currentColor;
  transition: background-color 250ms ease-in-out;
}
.social-list--circle svg * {
  fill: #2D1945;
}
.social-list--circle.social-list--primary a {
  background-color: #2D1945;
}
.social-list--circle.social-list--primary a:hover, .social-list--circle.social-list--primary a:focus, .social-list--circle.social-list--primary a:active {
  background-color: #D05559;
}
.social-list--circle.social-list--primary svg * {
  fill: #ffffff;
}

.widget-ChildrenGallery .children-gallery-list {
  list-style-type: none;
  margin: 20px -10px 10px;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}
.widget-ChildrenGallery .children-gallery-list .children-gallery-list-item {
  position: relative;
  margin: 0 10px 10px;
  flex: 1 1 auto;
  width: 100%;
  flex-basis: 100%;
  max-width: 100%;
}
.widget-ChildrenGallery .children-gallery-list .children-gallery-list-item__img-wrap {
  overflow: hidden;
  max-height: 260px;
  aspect-ratio: 16/9;
}
.widget-ChildrenGallery .children-gallery-list .children-gallery-list-item__img-wrap img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.widget-ChildrenGallery .children-gallery-list--3 .children-gallery-list-item, .widget-ChildrenGallery .children-gallery-list--4 .children-gallery-list-item, .widget-ChildrenGallery .children-gallery-list--5 .children-gallery-list-item {
  width: 50%;
  flex-basis: 50%;
  max-width: 50%;
}
@media screen and (min-width: 35em) {
  .widget-ChildrenGallery .children-gallery-list--3 .children-gallery-list-item, .widget-ChildrenGallery .children-gallery-list--5 .children-gallery-list-item {
    width: 33%;
    flex-basis: 33%;
    max-width: 33%;
  }
}
@media screen and (min-width: 48em) {
  .widget-ChildrenGallery .children-gallery-list--4 .children-gallery-list-item {
    width: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }
}
@media screen and (min-width: 62em) {
  .widget-ChildrenGallery .children-gallery-list--5 .children-gallery-list-item {
    width: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }
}
.widget-ChildrenGallery .children-gallery-list-item-link {
  display: block;
  text-decoration: none;
  background-color: #00847F;
  color: #ffffff;
  font-weight: 700;
  height: 100%;
}
.widget-ChildrenGallery .children-gallery-list-item-link:hover, .widget-ChildrenGallery .children-gallery-list-item-link:focus, .widget-ChildrenGallery .children-gallery-list-item-link:active {
  background-color: #2D1945;
}
.widget-ChildrenGallery .children-gallery-list-item-link:hover .children-gallery-list-item-image-placeholder:after, .widget-ChildrenGallery .children-gallery-list-item-link:focus .children-gallery-list-item-image-placeholder:after, .widget-ChildrenGallery .children-gallery-list-item-link:active .children-gallery-list-item-image-placeholder:after {
  background-color: #2D1945;
}
.widget-ChildrenGallery .children-gallery-list-item-image-placeholder {
  padding-top: 86.666666667%;
  position: relative;
  background: url(../images/qaams-artwork-borderless.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0.5;
  transition: opacity 250ms ease-in-out, background-color 250ms ease-in-out;
}
.widget-ChildrenGallery .children-gallery-list-item-image-placeholder:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  mix-blend-mode: color;
  background-color: #00847F;
  transition: opacity 250ms ease-in-out, background-color 250ms ease-in-out;
}
.widget-ChildrenGallery .children-gallery-list-item-link:hover .children-gallery-list-item-image {
  opacity: 0.9;
}
.widget-ChildrenGallery .children-gallery-list-item-title {
  padding: 0.75em 20px;
  margin-bottom: 0;
  line-height: 1.15;
}
.widget-ChildrenGallery .children-gallery-list-item-anchor {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
@supports (display: grid) {
  .widget-ChildrenGallery .children-gallery-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-gap: 8px;
    justify-items: center;
    margin-left: 0;
    margin-right: 0;
  }
  @media screen and (min-width: 30em) {
    .widget-ChildrenGallery .children-gallery-list {
      grid-gap: 16px;
      grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    }
  }
  .widget-ChildrenGallery .children-gallery-list .children-gallery-list-item {
    width: 100%;
    max-width: 100%;
    margin: 0;
  }
  @media screen and (min-width: 48em) {
    .widget-ChildrenGallery .children-gallery-list--3 {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  @media screen and (min-width: 48em) {
    .widget-ChildrenGallery .children-gallery-list--2 {
      grid-template-columns: repeat(2, 1fr);
    }
    .widget-ChildrenGallery .children-gallery-list-item-title {
      font-size: 2.2rem;
    }
  }
  @media screen and (min-width: 75em) {
    .widget-ChildrenGallery .children-gallery-list--4 {
      grid-template-columns: repeat(4, 1fr);
    }
    .widget-ChildrenGallery .children-gallery-list--5 {
      grid-template-columns: repeat(5, 1fr);
    }
  }
}

.widget-FileList {
  position: relative;
  background: #ffffff;
  padding: 20px;
  margin-bottom: 1em;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  padding-left: calc(1.70833333vw + 40px);
}
@media screen and (min-width: 23.75em) {
  .widget-FileList {
    /* 380px */
    padding: 30px;
    padding-left: calc(1.70833333vw + 50px);
  }
}
.widget-FileList:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  width: calc(1.70833333vw + 20px);
  background: url(../images/qaams-artwork.jpg);
  background-position: center 38%;
  background-size: auto 100%;
  background-repeat: no-repeat;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.widget-FileList ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.widget-FileList li {
  margin-bottom: 8px;
}

.widget-FileList li:last-child {
  margin-bottom: 0;
}

.widget-FileList .document {
  min-height: 24px;
  padding-left: 32px;
  background: url(../images/icon-system/icon_document.svg) no-repeat;
}

.widget-FileList .document-pdf {
  background-image: url(../images/icon-system/icon_pdf.svg);
}

.widget-FileList .document-png,
.widget-FileList .document-gif,
.widget-FileList .document-jpg {
  background-image: url(../images/icon-system/icon_image.svg);
}

.widget-FileList .document-rtf,
.widget-FileList .document-txt,
.widget-FileList .document-doc,
.widget-FileList .document-docx {
  background-image: url(../images/icon-system/icon_word-doc.svg);
}

.widget-FileList .document-csv,
.widget-FileList .document-xls,
.widget-FileList .document-xlsx {
  background-image: url(../images/icon-system/icon_spreadsheet.svg);
}

.widget-FileList .document-mp3,
.widget-FileList .document-aac,
.widget-FileList .document-oga {
  background-image: url(../images/icon-system/icon_sound.svg);
}

.widget-FileList .document-mp4,
.widget-FileList .document-mpeg,
.widget-FileList .document-mpg,
.widget-FileList .document-webm,
.widget-FileList .document-ogv,
.widget-FileList .document-aac,
.widget-FileList .document-avi,
.widget-FileList .document-mov,
.widget-FileList .document-wmv {
  background-image: url(../images/icon-system/icon_video.svg);
}

.widget-FileList a {
  display: inline-block;
  text-decoration: none;
  color: #2D1945;
  transition: all 200ms ease-in-out;
}

.widget-FileList a:hover,
.widget-FileList a:focus,
.widget-FileList a:active {
  text-decoration: underline;
}

.block-list {
  list-style: none;
  padding: 0;
  margin: 0 0 20px;
}

.block-list a {
  display: block;
  text-decoration: none;
  letter-spacing: 0.04687em;
  text-transform: uppercase;
  font-weight: 700;
  padding: 0.75em 0;
}

.tick-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.tick-list li {
  margin-bottom: 8px;
}

.tick-list li {
  min-height: 24px;
  padding-left: 32px;
  background: url(../../images/icon-system/icon_form_tick-sm.svg) no-repeat;
}

/* ---- Navigation ---- */
.breadcrumb {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: var(--centi-font-size);
}
.breadcrumb > li {
  display: inline-block;
}
.breadcrumb > li:before {
  content: " • ";
  opacity: 0.6;
}
.breadcrumb > li:first-child:before {
  content: none;
}
.breadcrumb > li a {
  color: inherit;
  opacity: 1;
}
.breadcrumb > li a:hover, .breadcrumb > li a:focus, .breadcrumb > li a:active {
  opacity: 0.8;
}
@media screen and (max-width: 47.9375em) {
  .breadcrumb > li {
    /* 767px */
    display: none;
    margin-left: 0;
  }
  .breadcrumb > li:first-child, .breadcrumb > li:nth-last-child(2) {
    display: inline-block;
  }
  .breadcrumb > li:nth-last-child(2):before {
    background: none;
    content: " < ";
    position: unset;
    font-weight: bold;
    position: relative;
    left: -1px;
    margin-left: 0px;
    top: 0;
  }
}

@media screen and (max-width: 61.25em) {
  /* 980px */
  /* code for the blog posts because the breadcrumbs are nonsensical */
  .blog-post-controller .breadcrumb > li {
    display: none;
    margin-left: 0;
  }
  .blog-post-controller .breadcrumb > li:nth-last-child(5) {
    display: inline-block;
  }
  .blog-post-controller .breadcrumb > li:nth-last-child(2) {
    display: none;
  }
  .blog-post-controller .breadcrumb > li:nth-last-child(5):before {
    background: none;
    content: "< ";
    position: unset;
    font-weight: bold;
    position: relative;
    left: -1px;
    margin-left: 0px;
    top: 0;
  }
}
:root {
  --pagination-height: 48px;
}

.search-paginate {
  text-align: center;
  padding-top: var(--section);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.search-paginate > * {
  flex: 0 0 auto;
}
.search-paginate .page-prev,
.search-paginate .page-next {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  text-align: center;
  border-radius: 2px;
  border: 1px solid transparent;
  height: var(--pagination-height);
  width: var(--pagination-height);
  text-indent: -9999px;
  background-position: center;
  background-repeat: no-repeat;
  transition: background-color 250ms ease-in-out;
}
.search-paginate .page-prev:hover, .search-paginate .page-prev:focus, .search-paginate .page-prev:active,
.search-paginate .page-next:hover,
.search-paginate .page-next:focus,
.search-paginate .page-next:active {
  background-color: #DADADA;
}
.search-paginate .page-prev {
  margin-right: 1em;
  background-image: url(../images/icon_chevron-left.svg);
}
.search-paginate .page-next {
  margin-left: 1em;
  background-image: url(../images/icon_chevron-right.svg);
}
.search-paginate .page {
  display: block;
  color: #7f7f7f;
  margin-bottom: 0;
  text-transform: uppercase;
  font-weight: 700;
  font-size: var(--body-font-size);
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--pagination-height);
  height: var(--pagination-height);
  border: 1px solid #DADADA;
  background: transparent;
  color: #00847F;
  margin-left: -1px;
  transition: background-color 250ms ease-in-out, color 250ms ease-in-out, border-color 250ms ease-in-out;
}
.search-paginate .page:hover, .search-paginate .page:focus {
  background: #DADADA;
  color: #00847F;
}
.search-paginate .page.on {
  background: #00847F;
  border-color: #00847F;
  color: #ffffff;
}
.search-paginate .page:first-of-type {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  margin-left: 0;
}
.search-paginate .page-prev + .page {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  margin-left: 0;
}
.search-paginate a:last-of-type[class~=page],
.search-paginate a:nth-last-of-type(2)[class$=page] {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

:root {
  --menu-transition: 350ms ease;
}

/*
* frankenMenu v1.0 // Karmabunny Web Design // built by Luke Underwood
*/
/** Desktop and Mobile styling
===================================*/
#frankenmenu-list,
#frankenmenu-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: block;
}

#frankenmenu-list a {
  text-decoration: none;
  display: block;
  font-size: 1.6rem;
}

/** Desktop menu only
===================================*/
@media screen and (min-width: 62em) {
  #frankenmenu {
    z-index: 1000;
  }

  #frankenmenu-list {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
  }

  #mobile-header {
    display: none;
  }

  /* ---- All level menu items ---- */
  #frankenmenu-list .menu-item {
    position: relative;
  }

  #frankenmenu-list .sub-menu {
    display: none;
    min-width: 200px;
    position: absolute;
    -webkit-box-shadow: 0 0 16px 1px rgba(0, 0, 0, 0.4);
    box-shadow: 0 0 16px 1px rgba(0, 0, 0, 0.4);
    z-index: 1;
    min-width: 250px;
    max-width: 350px;
    text-align: left;
    top: 0;
    left: 100%;
  }

  /* ---- First level menu ---- */
  #frankenmenu-list .menu-item-depth1 {
    text-align: center;
    flex: 1 1 auto;
    margin-left: calc((var(--default-container) - 236px) * 0.038);
  }
  #frankenmenu-list .menu-item-depth1:first-child {
    margin-left: 0;
  }

  .faux-menu-item-link,
#frankenmenu-list .menu-item-depth1 > a {
    position: relative;
    display: block;
    color: #ffffff;
    font-size: calc(var(--body-font-size) * 1.12);
    line-height: 32px;
    padding: 4px 0;
    font-family: brandon-grotesque, sans-serif;
    font-weight: 500;
  }
  .faux-menu-item-link:after,
#frankenmenu-list .menu-item-depth1 > a:after {
    content: "";
    position: absolute;
    left: 50%;
    right: 50%;
    top: 100%;
    height: 1px;
    background-color: transparent;
    transition: left 250ms ease-in-out, right 250ms ease-in-out, background-color 250ms ease-in-out;
  }

  /* Hover */
  .faux-menu-item-link:hover,
.faux-menu-item-link:focus,
#frankenmenu-list .menu-item-depth1 > a:hover,
#frankenmenu-list .menu-item-depth1 > a:focus,
#frankenmenu-list .menu-item-depth1.frankenhover > a,
#frankenmenu-list .menu-item-depth1.frankenhold > a {
    color: #FFB548;
  }
  .faux-menu-item-link:hover:after,
.faux-menu-item-link:focus:after,
#frankenmenu-list .menu-item-depth1 > a:hover:after,
#frankenmenu-list .menu-item-depth1 > a:focus:after,
#frankenmenu-list .menu-item-depth1.frankenhover > a:after,
#frankenmenu-list .menu-item-depth1.frankenhold > a:after {
    left: 0;
    right: 0;
    background-color: #FFB548;
  }

  .faux-menu-item-link {
    text-decoration: none;
  }

  /* Current item */
  .faux-menu-item-link--current,
#frankenmenu-list .menu-item-depth1.menu-current-item > a {
    color: #ffffff;
  }
  .faux-menu-item-link--current:after,
#frankenmenu-list .menu-item-depth1.menu-current-item > a:after {
    left: 0;
    right: 0;
    background-color: #ffffff;
  }

  .menu-home-page {
    display: none;
  }

  /* Ancestor item */
  #frankenmenu-list .menu-current-item-ancestor a {
    color: #ffffff;
  }
  #frankenmenu-list .menu-current-item-ancestor a:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    right: auto;
    transform: translateX(-50%);
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: #FFF transparent transparent transparent;
  }
  #frankenmenu-list .menu-current-item-ancestor a:hover:before, #frankenmenu-list .menu-current-item-ancestor a:focus:before, #frankenmenu-list .menu-current-item-ancestor a:active:before {
    border-color: #FFB548 transparent transparent transparent;
  }

  /* ---- Submenus ---- */
  #frankenmenu-list .sub-menu a {
    border-top: #F5f5f5;
    padding: 0.75em 1em;
    font-size: 1.5rem;
    background-color: #ffffff;
    color: #3D3935;
    line-height: normal;
    transition: all 200ms ease;
    font-weight: 600;
  }

  #frankenmenu-list .sub-menu .menu-item:first-child a {
    border-top: none;
  }

  #frankenmenu-list .sub-menu .menu-item > a:hover,
#frankenmenu-list .sub-menu .menu-item > a:focus,
#frankenmenu-list .sub-menu .menu-item.frankenhover > a,
#frankenmenu-list .sub-menu .menu-item.frankenhold > a {
    background-color: #FFB548;
    color: #2D1945;
  }

  /* Sub menu - Current item */
  #frankenmenu-list .sub-menu .menu-item.menu-current-item > a {
    color: #ffffff;
    background-color: #655DC6;
  }

  #frankenmenu-list .frankenhold > .sub-menu {
    display: block !important;
    opacity: 1 !important;
  }

  /* ---- First level submenus ---- */
  #frankenmenu-list .sub-menu-depth1 {
    top: 100%;
    left: auto;
  }

  /* ---- Right aligned submenus ---- */
  #frankenmenu-list .sub-menu.submenu-right-align,
#frankenmenu-list .sub-menu.submenu-right-align .sub-menu {
    left: auto;
    right: 100%;
  }

  #frankenmenu-list .sub-menu-depth1.submenu-right-align {
    left: auto;
    right: 0;
  }

  /* ---- Sub-navigation ---- */
  #frankenmenu-list .menu-item-subnav {
    display: none;
  }

  #frankenmenu-subnav {
    float: right;
  }

  #frankenmenu-subnav-list,
#frankenmenu-subnav-list ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  #frankenmenu-subnav-list .menu-item-depth1 {
    display: inline-block;
  }

  #frankenmenu-subnav-list .menu-item-depth1 > a {
    text-decoration: none;
    padding: 5px 5px;
    display: block;
    color: inherit;
  }

  #frankenmenu-subnav-list .menu-item-depth1 > a:hover {
    text-decoration: underline;
  }

  #frankenmenu-subnav-list .sub-menu {
    display: none;
  }

  /* ---- Moved elements ---- */
  .frankenmoved {
    display: none;
  }

  /* ---- Megamenu ---- */
  #frankenmenu-list .mega-menu {
    left: 0;
    right: 0;
    background-color: #ffffff;
    text-align: left;
    top: 100%;
    position: absolute;
    border-bottom: 4px solid #DADADA;
    z-index: 51;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    width: 1px;
  }

  /* Remove relative positioning for mega menu */
  .frankenmenu-mega #frankenmenu-list .menu-item {
    position: static;
  }

  #frankenmenu-list .menu-item.frankenhover .mega-menu,
#frankenmenu-list .menu-item.frankenhold .mega-menu {
    padding: 40px 0;
    width: auto;
    height: auto;
    clip: auto;
    overflow: visible;
    margin: auto;
  }

  #frankenmenu-list .menu-item.frankenhold .mega-menu {
    display: block !important;
    height: auto !important;
  }

  /* Megamenu preview */
  #frankenmenu-list .mega-menu-preview {
    float: left;
    color: #ffffff;
    padding: 18px var(--body-font-size);
    background-color: #636363;
    width: 22%;
    margin-right: 3%;
  }

  #frankenmenu-list .mega-menu-preview-image {
    height: 1var --body-font-size;
    background: url(../images/mega-menu-image-fallback.jpg) no-repeat center center;
    background-size: cover;
  }

  #frankenmenu-list .mega-menu-preview-text {
    margin-bottom: 0;
    line-height: 1.2;
  }

  /* Megamenu columns */
  #frankenmenu-list .mega-menu .mega-menu-columns {
    float: left;
    width: 75%;
  }

  #frankenmenu-list .mega-menu-column {
    width: 31.33333333%;
    float: left;
    margin-right: 3%;
  }

  #frankenmenu-list .mega-menu-column:last-child {
    margin-right: 0;
  }

  /* Megamenu submenus */
  #frankenmenu-list .mega-menu-submenu .menu-item {
    border-top: 1px solid #DADADA;
  }

  #frankenmenu-list .mega-menu-submenu .menu-item:last-child {
    border-bottom: 1px solid #DADADA;
  }

  #frankenmenu-list .mega-menu-submenu a {
    color: #000000;
    padding: 5px 0;
  }

  /* ---- Specialty Menu Items ---- */
  #frankenmenu-list .menu-item.menu-login {
    display: none;
  }
}
@media screen and (min-width: 75em) {
  #frankenmenu-list .menu-item-depth1 > a {
    font-size: calc(var(--body-font-size) * 1.25);
  }

  .menu-home-page {
    display: block;
  }

  .faux-menu-item-link--home {
    display: none;
  }
}
@media screen and (min-width: 87.5em) {
  #frankenmenu-list .menu-item-depth1 {
    margin-left: calc((var(--default-container) - 236px) * 0.045);
  }

  #frankenmenu-list .menu-item-depth1 > a {
    font-size: 2.4rem;
  }
}
@media screen and (min-width: 100em) {
  #frankenmenu-list .menu-item-depth1 {
    margin-left: 64px;
  }
}
/** Mobile menu only
===================================*/
@media screen and (max-width: 61.9375em) {
  /* Prevents font-boosting of menu items on Android */
  #frankenmenu * {
    max-height: 999999px;
  }

  #wrap {
    margin-top: 40px;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background-color: #ffffff;
  }

  #frankenmenu {
    top: 0;
    position: fixed;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
    background-color: #F5f5f5;
    width: 70%;
    z-index: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    padding-top: 64px;
  }

  #frankenmenu .container {
    position: relative;
    padding: 0;
    max-width: none;
    width: 100%;
    margin: 0;
    opacity: 0;
  }

  /* ---- Mobile header ---- */
  #mobile-header {
    background-color: #2D1945;
    height: 40px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
  }

  #mobile-menu-button {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    outline: none;
    border: none;
    padding: 0;
    text-indent: -9999px;
    width: 40px;
    height: 36px;
    margin-top: 2px;
    position: relative;
    background: none;
  }

  .mobile-menu-button-top,
.mobile-menu-button-middle,
.mobile-menu-button-bottom {
    height: 3px;
    background-color: #ffffff;
    left: 6px;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-duration: 400ms;
    animation-duration: 400ms;
    position: absolute;
    display: block;
    right: 6px;
  }

  .mobile-menu-button-top {
    top: 8px;
  }

  .mobile-menu-button-middle {
    top: 17px;
  }

  .mobile-menu-button-bottom {
    top: 26px;
  }

  /* Animations active */
  .frankenmenu-mob-menu-animations .mobile-menu-button-top {
    -webkit-animation-name: mobileMenuBtnTopClose;
    animation-name: mobileMenuBtnTopClose;
  }

  .frankenmenu-mob-menu-animations .mobile-menu-button-middle {
    -webkit-animation-name: mobileMenuBtnMiddleClose;
    animation-name: mobileMenuBtnMiddleClose;
  }

  .frankenmenu-mob-menu-animations .mobile-menu-button-bottom {
    -webkit-animation-name: mobileMenuBtnBottomClose;
    animation-name: mobileMenuBtnBottomClose;
  }

  /* Close button */
  .frankenmenu-mob-menu-visible #mobile-menu-button .mobile-menu-button-top {
    -webkit-animation-name: mobileMenuBtnTopOpen;
    animation-name: mobileMenuBtnTopOpen;
  }

  .frankenmenu-mob-menu-visible #mobile-menu-button .mobile-menu-button-middle {
    -webkit-animation-name: mobileMenuBtnMiddleOpen;
    animation-name: mobileMenuBtnMiddleOpen;
  }

  .frankenmenu-mob-menu-visible #mobile-menu-button .mobile-menu-button-bottom {
    -webkit-animation-name: mobileMenuBtnBottomOpen;
    animation-name: mobileMenuBtnBottomOpen;
  }

  @-webkit-keyframes mobileMenuBtnTopClose {
    0% {
      -webkit-transform: translate(0, 9px) rotate(45deg);
      transform: translate(0, 9px) rotate(45deg);
    }
    50%, 70% {
      -webkit-transform: translate(0, 9px);
      transform: translate(0, 9px);
    }
    100% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
  }
  @keyframes mobileMenuBtnTopClose {
    0% {
      -webkit-transform: translate(0, 9px) rotate(45deg);
      transform: translate(0, 9px) rotate(45deg);
    }
    50%, 70% {
      -webkit-transform: translate(0, 9px);
      transform: translate(0, 9px);
    }
    100% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
  }
  @-webkit-keyframes mobileMenuBtnTopOpen {
    0% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
    50%, 70% {
      -webkit-transform: translate(0, 9px);
      transform: translate(0, 9px);
    }
    100% {
      -webkit-transform: translate(0, 9px) rotate(45deg);
      transform: translate(0, 9px) rotate(45deg);
    }
  }
  @keyframes mobileMenuBtnTopOpen {
    0% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
    50%, 70% {
      -webkit-transform: translate(0, 9px);
      transform: translate(0, 9px);
    }
    100% {
      -webkit-transform: translate(0, 9px) rotate(45deg);
      transform: translate(0, 9px) rotate(45deg);
    }
  }
  @-webkit-keyframes mobileMenuBtnBottomClose {
    0% {
      -webkit-transform: translate(0, -9px) rotate(-45deg);
      transform: translate(0, -9px) rotate(-45deg);
    }
    50%, 70% {
      -webkit-transform: translate(0, -9px);
      transform: translate(0, -9px);
    }
    100% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
  }
  @keyframes mobileMenuBtnBottomClose {
    0% {
      -webkit-transform: translate(0, -9px) rotate(-45deg);
      transform: translate(0, -9px) rotate(-45deg);
    }
    50%, 70% {
      -webkit-transform: translate(0, -9px);
      transform: translate(0, -9px);
    }
    100% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
  }
  @-webkit-keyframes mobileMenuBtnBottomOpen {
    0% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
    50%, 70% {
      -webkit-transform: translate(0, -9px);
      transform: translate(0, -9px);
    }
    100% {
      -webkit-transform: translate(0, -9px) rotate(-45deg);
      transform: translate(0, -9px) rotate(-45deg);
    }
  }
  @keyframes mobileMenuBtnBottomOpen {
    0% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
    50%, 70% {
      -webkit-transform: translate(0, -9px);
      transform: translate(0, -9px);
    }
    100% {
      -webkit-transform: translate(0, -9px) rotate(-45deg);
      transform: translate(0, -9px) rotate(-45deg);
    }
  }
  @-webkit-keyframes mobileMenuBtnMiddleClose {
    0% {
      opacity: 0;
    }
    50%, 70%, 100% {
      opacity: 1;
    }
  }
  @keyframes mobileMenuBtnMiddleClose {
    0% {
      opacity: 0;
    }
    50%, 70%, 100% {
      opacity: 1;
    }
  }
  @-webkit-keyframes mobileMenuBtnMiddleOpen {
    0% {
      opacity: 1;
    }
    50%, 70%, 100% {
      opacity: 0;
    }
  }
  @keyframes mobileMenuBtnMiddleOpen {
    0% {
      opacity: 1;
    }
    50%, 70%, 100% {
      opacity: 0;
    }
  }
  /* ---- All level menu items ---- */
  #frankenmenu-list, #frankenmenu-list ul {
    width: 100%;
  }

  #frankenmenu-list .menu-item {
    position: relative;
    border-bottom: 1px solid #F5f5f5;
    float: none;
    width: 100%;
  }

  #frankenmenu-list a {
    min-height: 40px;
    width: 100%;
    padding: 0.6em 56px 0.6em var(--body-font-size);
    color: #3D3935;
    background-color: #F5f5f5;
  }

  #frankenmenu-list .menu-item.menu-current-item > a {
    background-color: #C1BDDE;
    color: #2D1945;
  }

  /* ---- First level menu ---- */
  #frankenmenu-list .menu-item-depth1 > a {
    font-size: 1.5rem;
  }

  /* ---- Submenus ---- */
  #frankenmenu-list .sub-menu {
    display: none;
  }

  #frankenmenu-list .menu-current-item-ancestor > .sub-menu {
    display: block;
  }

  /* Depth differences */
  #frankenmenu-list .sub-menu .menu-item > a {
    padding-left: calc(var(--body-font-size) * 2);
    font-size: 1.4rem;
  }

  #frankenmenu-list .sub-menu-depth2 .menu-item > a {
    padding-left: calc(var(--body-font-size) * 3);
  }

  /* ---- Ancestor ---- */
  #frankenmenu-list .menu-current-item-ancestor > a {
    color: #ffffff;
    background-color: #655DC6;
  }
  #frankenmenu-list .menu-current-item-ancestor .submenu-toggle:before,
#frankenmenu-list .menu-current-item-ancestor .submenu-toggle:after {
    background-color: #2D1945;
  }

  /* Expand buttons */
  .submenu-toggle {
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    border: none;
    width: 55px;
    background: none;
    height: 40px;
    display: block;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    padding: 0;
  }

  .submenu-toggle:before,
.submenu-toggle:after {
    content: " ";
    width: 12px;
    height: 2px;
    display: block;
    position: absolute;
    background-color: #636363;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 22px;
    transition: all 400ms ease;
  }

  .submenu-toggle:before {
    -webkit-transform: rotate(45deg) translateX(-5px);
    -ms-transform: rotate(45deg) translateX(-5px);
    transform: rotate(45deg) translateX(-5px);
  }

  .submenu-toggle:after {
    -webkit-transform: rotate(-45deg) translateX(5px);
    -ms-transform: rotate(-45deg) translateX(5px);
    transform: rotate(-45deg) translateX(5px);
  }

  .menu-item-submenu-open > .submenu-toggle:before,
.menu-item-submenu-open > .submenu-toggle:after {
    top: 15px;
  }

  .menu-item-submenu-open > .submenu-toggle:before {
    -webkit-transform: rotate(-45deg) translateX(-5px);
    -ms-transform: rotate(-45deg) translateX(-5px);
    transform: rotate(-45deg) translateX(-5px);
  }

  .menu-item-submenu-open > .submenu-toggle:after {
    -webkit-transform: rotate(45deg) translateX(5px);
    -ms-transform: rotate(45deg) translateX(5px);
    transform: rotate(45deg) translateX(5px);
  }

  /* ---- Sub-navigation ---- */
  #frankenmenu-subnav-list {
    display: none;
  }

  /* ---- Menu positions ---- */
  /* Left */
  .frankenmenu-mobile-pos-left #frankenmenu {
    left: 0;
  }

  .frankenmenu-mobile-pos-left #frankenmenu .container {
    -webkit-transform: translate3d(-10%, 0, 0) scale(0.9);
    transform: translate3d(-10%, 0, 0) scale(0.9);
  }

  .frankenmenu-mobile-pos-left.frankenmenu-mob-menu-visible #wrap {
    -webkit-transform: translate3d(70%, 0, 0);
    transform: translate3d(70%, 0, 0);
  }

  /* Right */
  .frankenmenu-mobile-pos-right #frankenmenu {
    right: 0;
  }

  .frankenmenu-mobile-pos-right #frankenmenu .container {
    -webkit-transform: translate3d(10%, 0, 0) scale(0.9);
    transform: translate3d(10%, 0, 0) scale(0.9);
  }

  .frankenmenu-mobile-pos-right.frankenmenu-mob-menu-visible #wrap {
    -webkit-transform: translate3d(-70%, 0, 0);
    transform: translate3d(-70%, 0, 0);
  }

  .frankenmenu-mobile-pos-right #mobile-menu-button {
    float: right;
  }

  /* ---- Transitions ---- */
  #frankenmenu {
    transition: left var(--menu-transition), right var(--menu-transition);
  }

  #frankenmenu .container {
    transition: transform var(--menu-transition), opacity var(--menu-transition);
  }

  .js #frankenmenu {
    opacity: 0;
  }

  /* Menu open */
  .frankenmenu-mob-menu-visible #frankenmenu {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .frankenmenu-mob-menu-visible #frankenmenu .container {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0) scale(1);
    transform: translate3d(0, 0, 0) scale(1);
  }

  /* ---- Moved elements ---- */
  .frankenmove {
    display: none;
  }

  /* ---- Megamenu ---- */
  .mega-menu-preview {
    display: none;
  }

  .mega-menu-preview-image {
    background-image: none !important;
  }

  .mega-menu-column-title {
    color: #ffffff;
    font-size: 1.6rem;
    padding: 3px var(--body-font-size);
    margin: 0;
    background: rgba(0, 0, 0, 0.3);
  }

  #frankenmenu-list .mega-menu {
    display: none;
  }

  #frankenmenu-list .mega-menu ul {
    display: block;
  }

  /* ---- Specialty Menu Items ---- */
  #frankenmenu-list .menu-item.menu-login a {
    background-color: #FFB548;
    color: #2D1945;
    font-family: nunito-sans, helvetica neue, helvetica, roboto, noto, arial, sans-serif;
    font-weight: 700;
    line-height: var(--body-line-height);
    display: inline-grid;
    grid-auto-flow: column;
    column-gap: 0.5em;
    align-items: center;
    justify-content: start;
  }
  #frankenmenu-list .menu-item.menu-login a svg {
    height: 1em;
    width: 1em;
  }
  #frankenmenu-list .menu-item.menu-login a svg path,
#frankenmenu-list .menu-item.menu-login a svg circle,
#frankenmenu-list .menu-item.menu-login a svg ellipse,
#frankenmenu-list .menu-item.menu-login a svg rect,
#frankenmenu-list .menu-item.menu-login a svg line,
#frankenmenu-list .menu-item.menu-login a svg polygon {
    fill: currentColor;
  }
}
.widget-RelatedLinks ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.widget-RelatedLinks ul.depth1 {
  background: url(../images/qaams-artwork-borderless.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.widget-RelatedLinks li {
  /* - Depth 1 - */
  /* On */
  /* - Depth 2 - */
  /* On */
}
.widget-RelatedLinks li a {
  display: block;
  line-height: normal;
  text-decoration: none;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}
.widget-RelatedLinks li a span {
  color: #3D3935;
  display: block;
  padding: 1em 64px 1em 24px;
  transform: none;
  background-color: #ffffff;
  border-top: 1px solid #F5f5f5;
  transition: color 250ms ease-in-out, opacity 250ms ease-in-out, background-color 250ms ease-in-out, width 250ms ease-in-out, transform 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.widget-RelatedLinks li a:hover span, .widget-RelatedLinks li a:focus span, .widget-RelatedLinks li a:active span {
  transform: translateX(56px);
}
.widget-RelatedLinks li.depth1 {
  position: relative;
}
.widget-RelatedLinks li.depth1.on > a span {
  position: relative;
  background-color: #655DC6;
  color: #ffffff;
  border-color: #655DC6;
}
.widget-RelatedLinks li ul.depth2 {
  display: none;
}
.widget-RelatedLinks li.on ul.depth2 {
  display: block;
}
.widget-RelatedLinks li.ancestor ul.depth2 {
  display: block;
}
.widget-RelatedLinks li.depth2 {
  font-size: var(--centi-font-size);
}
.widget-RelatedLinks li.depth2 a span {
  padding: 0.75em 64px 0.75em 32px;
}
.widget-RelatedLinks li.depth2 a:hover span, .widget-RelatedLinks li.depth2 a:focus span, .widget-RelatedLinks li.depth2 a:active span {
  transform: translateX(38px);
}
.widget-RelatedLinks li.depth2.on > a span {
  color: #D05559;
  font-weight: bold;
}
.widget-RelatedLinks li.depth3 a span {
  padding-left: 48px;
}

/* ---- Plugins / third-party styles ---- */
/* ---- Magnific Popup ---- */
.mfp-image-holder .mfp-content {
  max-width: 1000px;
}

.mfp-ajax-holder .mfp-content {
  background-color: #fff;
  margin: 0 auto;
  padding: 50px;
  max-width: 800px;
}

/* ---- Blocks ---- */
.date-card {
  background: #2D1945;
  color: #ffffff;
  text-transform: uppercase;
  text-align: center;
  padding: 16px;
  max-width: 94px;
  line-height: 1;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
}
.date-card__day {
  font-size: 3.8rem;
  line-height: 1;
  font-weight: bold;
}

.tile {
  background: #ffffff;
}

.tile__inner {
  height: 100%;
}

.tile__img img {
  width: 100%;
}

.tile__content {
  padding: 20px 25px;
}

:root {
  --banner-height: 400px;
}

@media screen and (min-width: 48em) {
  :root {
    --banner-height: 50vw;
  }
}
@media screen and (min-width: 62em) {
  :root {
    --banner-height: 32.875vw;
  }
}
@media screen and (min-width: 120em) {
  :root {
    --banner-height: 631px;
  }
}
.hero-banner {
  margin-top: calc(2.333333vw + 32px);
  position: relative;
  background: #D05559;
  color: #ffffff;
}
@media screen and (min-width: 150em) {
  .hero-banner {
    margin-top: calc((2400px * 0.02333333) + 32px);
  }
}
.hero-banner:before, .hero-banner:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
  background-repeat: no-repeat;
}
.hero-banner:before {
  top: -0.5px;
  height: 0.75vw;
  background-image: url(../images/top-n-tails/banner-negative-curve-top.svg);
  background-size: cover;
  background-position: top;
  background-size: 100%;
}
@media screen and (min-width: 150em) {
  .hero-banner:before {
    width: 2400px;
    height: calc(0.0075 * 2400px);
  }
}
.hero-banner:after {
  bottom: -0.5px;
  height: 0.8333333333vw;
  background-image: url(../images/top-n-tails/banner-negative-curve-bottom.svg);
  background-size: cover;
  background-position: center bottom;
}
@media screen and (min-width: 150em) {
  .hero-banner:after {
    width: 2400px;
    height: calc(0.008333333333 * 2400px);
  }
}
.hero-banner__inner {
  position: relative;
  height: var(--banner-height);
  z-index: 0;
  overflow: hidden;
}
.hero-banner__inner:before, .hero-banner__inner:after {
  content: "";
  position: absolute;
  border-radius: 100%;
  height: calc(var(--banner-height) * 1.9790874525);
  width: calc(var(--banner-height) * 1.9790874525);
  top: calc(var(--banner-height) * -0.1920152091);
  right: 57.625vw;
}
@media screen and (min-width: 150em) {
  .hero-banner__inner:before, .hero-banner__inner:after {
    right: calc((2400px/2) + (2400px/2) * 0.1525);
  }
}
.hero-banner__inner:before {
  background: url(../images/qaams-artwork.jpg);
  background-position: 50% 60%;
  background-size: 102%;
  background-repeat: no-repeat;
  opacity: 0.5;
}
.hero-banner__inner:after {
  mix-blend-mode: color;
  background-color: #CF454A;
}
.hero-banner__inner__circle:before, .hero-banner__inner__circle:after {
  content: "";
  position: absolute;
  border-radius: 100%;
  height: calc(var(--banner-height) * 1.9790874525);
  width: calc(var(--banner-height) * 1.9790874525);
  bottom: calc(var(--banner-height) * 0.4486692015);
  left: 79.8125vw;
}
@media screen and (min-width: 150em) {
  .hero-banner__inner__circle:before, .hero-banner__inner__circle:after {
    left: calc((2400px/2) + (2400px/2) * 0.59625);
  }
}
.hero-banner__inner__circle:before {
  background: url(../images/qaams-artwork.jpg);
  background-position: 0 0;
  background-size: 100%;
  background-repeat: no-repeat;
  opacity: 0.5;
}
.hero-banner__inner__circle:after {
  mix-blend-mode: color;
  background-color: #CF454A;
}

.hero-banner__img {
  position: absolute;
  border-radius: 100%;
  z-index: 2;
  right: 44.5vw;
  top: calc(var(--banner-height) * 0.45);
  width: calc(var(--banner-height) * 0.66);
  height: calc(var(--banner-height) * 0.66);
}
@media screen and (min-width: 35em) {
  .hero-banner__img {
    top: calc(var(--banner-height) * 0.3);
    right: 54.5vw;
    width: calc(var(--banner-height) * 0.75);
    height: calc(var(--banner-height) * 0.75);
  }
}
@media screen and (min-width: 48em) {
  .hero-banner__img {
    top: calc(var(--banner-height) * 0.1102661597);
    right: 54.5vw;
    width: calc(var(--banner-height) * 0.9467680608);
    height: calc(var(--banner-height) * 0.9467680608);
  }
}
@media screen and (min-width: 150em) {
  .hero-banner__img {
    right: calc((2400px/2) + (2400px/2) * 0.09);
  }
}

.hero-banner__heading {
  font-family: brandon-grotesque, sans-serif;
  font-size: 3.6rem;
  max-width: fit-content;
  line-height: 1;
  position: absolute;
  z-index: 1;
  top: 56px;
  left: auto;
  right: var(--default-container-gutter);
}
@media screen and (min-width: 22.5em) {
  .hero-banner__heading {
    font-size: var(--mega-font-size);
  }
}
@media screen and (min-width: 35em) {
  .hero-banner__heading {
    top: var(--section-large);
    right: auto;
    left: 40vw;
  }
}
@media screen and (min-width: 39em) {
  .hero-banner__heading {
    /* 624px */
    left: 43.75vw;
  }
}
@media screen and (min-width: 48em) {
  .hero-banner__heading {
    top: calc(var(--banner-height) * 0.2243346008);
    left: 48.75vw;
  }
}
@media screen and (min-width: 150em) {
  .hero-banner__heading {
    left: calc((2400px/2) * 0.975);
  }
}
.hero-banner__heading .line {
  display: block;
}
.hero-banner__heading .line.line--01, .hero-banner__heading .line.line--04 {
  font-size: 0.5714285714em;
  display: block;
}
.hero-banner__heading .line.line--01 {
  margin-bottom: 0.25em;
}
.hero-banner__heading .line.line--04 {
  text-align: right;
  margin-top: 0.2em;
}
.hero-banner__heading .line.line--03 {
  font-weight: 700;
}
.hero-banner__heading .line.line--02 {
  display: flex;
}
.hero-banner__heading .line.line--02 span:after {
  content: " ";
}
.hero-banner__heading .line.line--02 > span:first-child {
  display: block;
  line-height: 1;
  align-self: flex-start;
  font-size: 0.4285714286em;
  padding-left: 1.1111111111em;
}
.hero-banner__heading .line.line--02 > span:nth-child(2) {
  font-weight: 700;
}
.hero-banner__heading .line.line--02 > span:last-child {
  font-weight: 400;
}

.image-gallery {
  margin: 0.5em;
}

.image-gallery-thumb {
  width: 100%;
  display: inline-block;
  float: left;
}

.image-gallery-caption {
  margin-top: calc(8px/2);
}

.image-gallery-popup {
  display: block;
}

.image-gallery-thumb img {
  width: 100%;
}

.image-gallery--captions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
}

@supports (display: grid) {
  .image-gallery {
    margin: 0;
  }

  .image-gallery-thumb {
    width: auto;
    margin: 0;
  }

  .image-gallery {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  }

  .image-gallery.-clearfix:before,
.image-gallery.-clearfix:after {
    content: none;
  }
}
/* BP Smaller */
@media screen and (min-width: 35em) {
  /* 560px */
  .image-gallery-thumb {
    width: calc(50% - 1em);
    margin: 0.5em;
  }

  @supports (display: grid) {
    .image-gallery {
      margin: 0;
    }

    .image-gallery-thumb {
      width: auto;
      margin: 0;
    }

    .image-gallery {
      display: grid;
      grid-gap: 16px;
      grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    }

    .image-gallery.-clearfix:before,
.image-gallery.-clearfix:after {
      content: none;
    }
  }
}
/* BP small */
@media screen and (min-width: 48em) {
  /* 768px */
  .image-gallery-thumb {
    width: calc(33% - 1em);
    margin: 0.5em;
  }

  @supports (display: grid) {
    .image-gallery {
      margin: 0;
    }

    .image-gallery-thumb {
      width: auto;
      margin: 0;
    }

    .image-gallery {
      display: grid;
      grid-gap: 16px;
      grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    }

    .image-gallery.-clearfix:before,
.image-gallery.-clearfix:after {
      content: none;
    }
  }
}
/* BP medium */
@media only screen and (min-width: 62em) {
  /* 992px */
  .image-gallery-thumb {
    width: calc(25% - 1em);
    margin: 0.5em;
  }

  @supports (display: grid) {
    .image-gallery {
      margin: 0;
    }

    .image-gallery-thumb {
      width: auto;
      margin: 0;
    }

    .image-gallery {
      display: grid;
      grid-gap: 16px;
      grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    }

    .image-gallery.-clearfix:before,
.image-gallery.-clearfix:after {
      content: none;
    }
  }
}
.media-object__img {
  float: left;
  width: 100%;
  height: 100%;
}

.media-object__img img {
  width: 100%;
}

.media-object__content {
  width: 100%;
  float: left;
  margin-top: 20px;
}

.media-object {
  padding: 20px;
  width: 100%;
  float: left;
}

.media-object:after,
.media-object:before {
  clear: both;
}

.media-object__details {
  font-weight: bold;
  color: #7f7f7f;
}

.media-object__date:after {
  content: " | ";
}

@media screen and (min-width: 48em) {
  /* 768px */
  .media-object {
    padding: 0;
  }

  .media-object__img {
    width: 33%;
  }

  .media-object__content {
    width: 66%;
    margin-top: 0;
    padding-left: 20px;
  }
}
.card {
  background: #fff;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.card:hover {
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.33);
}

.card a:focus {
  text-decoration: underline;
}

.card:focus-within {
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.33);
}

.card:focus-within a:focus {
  text-decoration: none;
}

.card__content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  order: 1;
}

.card__content > * + * {
  margin-top: 8px;
}

.card__content :last-child {
  margin-top: auto;
}

.card__content :nth-last-child(2) {
  margin-bottom: 8px;
}

.card__img {
  height: 200px;
}

.card__img img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.card-lnd-hvr {
  display: block;
  position: relative;
  color: inherit;
  text-decoration: none;
  border: 1px solid #DADADA;
  margin-bottom: 40px;
}
@media screen and (min-width: 35em) {
  .card-lnd-hvr {
    /* 560px */
    margin-bottom: 0;
  }
}
@media screen and (min-width: 48em) {
  .card-lnd-hvr {
    /* 768px */
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
  }
}
.card-lnd-hvr__img img {
  width: 100%;
}
.card-lnd-hvr__img--placeholder {
  padding-top: 56.25%;
  background-color: rgba(0, 0, 0, 0.15);
  background-image: url(../images/logo.svg);
}
.card-lnd-hvr:hover {
  color: inherit;
}

.card-lnd-hvr__content {
  padding: 25px 25px 0 25px;
  flex: 1 1 auto;
}
.card-lnd-hvr__content__heading {
  position: relative;
  padding-top: 10px;
}
.card-lnd-hvr__content__heading:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  height: 8px;
  width: 40px;
  background: #D05559;
}
.card-lnd-hvr__content__cta {
  padding-top: 12px;
  padding-left: 25px;
  margin-bottom: -20px;
}
@media screen and (min-width: 35em) {
  .card-lnd-hvr__content__cta {
    /* 560px */
    margin-bottom: 0;
    padding: 15px 25px 25px 25px;
  }
}
.card-lnd-hvr__content__text {
  display: none;
}
@media screen and (min-width: 48em) {
  .card-lnd-hvr__content__text {
    /* 768px */
    display: block;
  }
}

@media screen and (min-width: 75em) {
  /* 1200px */
  .card-lnd-hvr {
    border: none;
    position: relative;
    transition: all 250ms ease-in-out;
  }
  .card-lnd-hvr__img {
    position: relative;
    background-color: #000000;
  }
  .card-lnd-hvr__img img {
    opacity: 0.85;
  }
  .card-lnd-hvr__img:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: 0, 0, 0;
    background: -moz-linear-gradient(16.6deg, rgba(0, 0, 0, 0.66) 0%, rgba(0, 0, 0, 0) 33%);
    background: -webkit-linear-gradient(16.6deg, rgba(0, 0, 0, 0.66) 0%, rgba(0, 0, 0, 0) 33%);
    background: linear-gradient(16.6deg, rgba(0, 0, 0, 0.66) 0%, rgba(0, 0, 0, 0) 33%);
  }
  .card-lnd-hvr__content {
    background-color: rgba(0, 0, 0, 0);
    color: #ffffff;
    position: absolute;
    bottom: 66px;
    left: 0;
    right: 0;
    top: calc(100% - 155px);
    overflow: hidden;
    transition: all 250ms ease-in-out;
  }
  @supports (display: flex) {
    .card-lnd-hvr__content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      animation-fill-mode: both;
      animation-duration: 50ms;
      animation-delay: 150ms;
      animation-name: changeFlexValueBack;
    }
  }
  .card-lnd-hvr__content__heading {
    transition: all 250ms ease-in-out;
    color: #ffffff;
    font-size: 2.8rem;
  }
  .card-lnd-hvr__content__text {
    opacity: 0;
    transition: all 250ms ease-in-out;
    color: #3D3935;
  }
  .card-lnd-hvr__content__cta {
    padding-top: 0;
    background-color: rgba(255, 255, 255, 0);
    transition: all 250ms ease-in-out;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 66px;
  }

  .card-lnd-hvr:hover {
    background-color: #ffffff;
  }
  .card-lnd-hvr:hover .card-lnd-hvr__content {
    background-color: white;
    opacity: 1;
    top: 0;
  }
  @supports (display: flex) {
    .card-lnd-hvr:hover .card-lnd-hvr__content {
      animation-fill-mode: forwards;
      animation-duration: 50ms;
      animation-delay: 150ms;
      animation-name: changeFlexValue;
      /* is this madness of genius? At very least unorthodox. */
    }
  }
  .card-lnd-hvr:hover .card-lnd-hvr__content__heading, .card-lnd-hvr:hover .card-lnd-hvr__content__text {
    opacity: 1;
    color: #3D3935;
  }
  .card-lnd-hvr:hover .card-lnd-hvr__content__cta {
    background-color: white;
  }
  .card-lnd-hvr:hover .card-lnd-hvr__content .button {
    padding-left: 32px;
    color: #ffffff;
  }
}
@keyframes changeFlexValue {
  0% {
    justify-content: flex-start;
  }
  100% {
    justify-content: center;
  }
}
@keyframes changeFlexValueBack {
  0% {
    justify-content: center;
  }
  100% {
    justify-content: flex-start;
  }
}
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir=rtl] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat;
}

.slick-slider {
  margin: 0;
  padding: 0;
  min-height: 100px;
}

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 40px;
  height: 40px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 40px;
  line-height: 1;
  opacity: 0.75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}

[dir=rtl] .slick-prev {
  right: -25px;
  left: auto;
}

.slick-prev:before {
  content: "←";
}

[dir=rtl] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir=rtl] .slick-next {
  right: auto;
  left: -25px;
}

.slick-next:before {
  content: "→";
}

[dir=rtl] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -55px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 45px;
  height: 45px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
  overflow: hidden;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 20px;
  height: 20px;
  content: "";
  opacity: 0.25;
  background: #000000;
  border-radius: 20px;
  transition: all 250ms ease-in-out;
}

.slick-dots li.slick-active button:before {
  opacity: 0.75;
  background: #000000;
}

.slider-clean {
  position: relative;
}

[aria-label=gallery]:focus {
  outline: 4px solid DodgerBlue;
  outline-offset: -4px;
  /* compensates for 2px border */
}

.slider__buttons {
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: absolute;
  bottom: 16px;
  right: 0;
  left: 0;
  height: auto;
  text-align: center;
}

.slider__button {
  display: inline-block;
  text-align: center;
  padding: 0;
  margin: 0 calc(8px/2);
}

.slider__button[type=button] {
  -webkit-appearance: unset;
}

.slider__button a {
  display: block;
  font-size: var(--milli-font-size);
  line-height: 2;
  border-radius: 50%;
  height: 2em;
  width: 2em;
  background: rgba(0, 0, 0, 0.66);
  color: #ffffff;
  text-decoration: none;
}

.slider__gallery {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100vw;
  height: auto;
  display: flex;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -webkit-scroll-snap-type: mandatory;
  -ms-scroll-snap-type: mandatory;
  scroll-snap-type: mandatory;
  -webkit-scroll-snap-points-y: repeat(100vw);
  -ms-scroll-snap-points-y: repeat(100vw);
  scroll-snap-points-y: repeat(100vw);
  -webkit-scroll-snap-type: x mandatory;
  -ms-scroll-snap-type: x mandatory;
  scroll-snap-type: x mandatory;
}

.slider__item {
  display: inline-block;
  scroll-snap-align: start;
  float: left;
  width: 100vw;
  flex-shrink: 0;
  height: 100%;
  scroll-behavior: smooth;
}

.slider__item img {
  object-fit: cover;
}

@supports (scroll-snap-type: mandatory) {
  .slider__buttons {
    display: none;
  }
}
:root {
  --image-circle-height: calc(var(--default-container));
}

@media screen and (min-width: 35em) {
  :root {
    --image-circle-height: calc((var(--default-container) - 24px) / 2);
  }
}
@media screen and (min-width: 48em) {
  :root {
    --image-circle-height: calc(((var(--default-container) - (var(--container-padding)*2)) - 24px) / 2);
  }
}
@media screen and (min-width: 62em) {
  :root {
    --image-circle-height: calc(((((var(--default-container) - (var(--container-padding)*2)) - 72px) / 2) - 24px) / 2);
  }
}
@media screen and (min-width: 62em) {
  .testing-machines__container {
    padding-left: 0;
    padding-right: 0;
  }
}
@media screen and (min-width: 75em) {
  .testing-machines__container {
    padding-left: var(--container-padding);
    padding-right: var(--container-padding);
  }
}
.testing-machines__columns {
  display: grid;
  grid-template-columns: 1fr;
  gap: 56px;
}
@media screen and (min-width: 62em) {
  .testing-machines__columns {
    grid-template-columns: 1fr 1fr;
    gap: 72px;
  }
}
@media screen and (min-width: 35em) {
  .testing-machines__item {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    align-items: center;
  }
}
@media screen and (min-width: 62em) {
  .testing-machines__item {
    align-items: end;
  }
}
.testing-machines__item__picture {
  position: relative;
  display: block;
  width: 100%;
}
.testing-machines__item__picture__image {
  display: block;
  border-radius: 100%;
  background-color: #655DC6;
  width: 100%;
  height: var(--image-circle-height);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}
@supports (aspect-ratio: 1) {
  .testing-machines__item__picture__image {
    aspect-ratio: 1;
    height: auto;
  }
}
.testing-machines__item__picture__bubble {
  position: absolute;
  bottom: 3.1415926535%;
  right: 3.1415926535%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: max(60px, var(--image-circle-height) * 0.2068965517);
  height: max(60px, var(--image-circle-height) * 0.2068965517);
  border-radius: 60px;
  background-color: #00847F;
  color: #ffffff;
  text-transform: uppercase;
  margin: 0;
  font-weight: 800;
}
.testing-machines__item__heading {
  font-size: var(--h3-font-size);
}
@media screen and (min-width: 48em) {
  .testing-machines__item__heading {
    font-size: var(--h2-font-size);
  }
}
@media screen and (min-width: 87.5em) {
  .testing-machines__item__heading {
    font-size: 4rem;
  }
}
.testing-machines__item__description {
  color: #636363;
}
@media screen and (min-width: 35em) {
  .testing-machines__item__description {
    font-size: 2rem;
  }
}
@media screen and (min-width: 62em) {
  .testing-machines__item__description {
    font-size: var(--body-font-size);
  }
}
@media screen and (min-width: 87.5em) {
  .testing-machines__item__description {
    font-size: 2.4rem;
  }
}
@media screen and (min-width: 62em) {
  .testing-machines__item__cta {
    padding-left: 1.5em;
    padding-right: 1.5em;
  }
}
@media screen and (min-width: 75em) {
  .testing-machines__item__cta {
    padding-left: 2.5em;
    padding-right: 2.5em;
  }
}

.testing-machines--dashboard .wedge-section__content {
  padding-bottom: 64px;
  margin-bottom: -32px;
}
.testing-machines--dashboard .wedge-section__content {
  padding-bottom: 48px;
}
.testing-machines__columns--one-item {
  grid-template-columns: unset;
  max-width: 580px;
  margin: auto;
}

.calendar-columns {
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  padding-left: 40px;
  padding-right: 40px;
}
@media screen and (min-width: 35em) {
  .calendar-columns {
    padding-left: 10vw;
    padding-right: 10vw;
  }
}
@media screen and (min-width: 48em) {
  .calendar-columns {
    gap: 56px;
    grid-template-columns: 1fr 1fr;
    padding-left: min(8vw, var(--container-padding));
    padding-right: min(8vw, var(--container-padding));
  }
}
@media screen and (min-width: 62em) {
  .calendar-columns {
    column-gap: 72px;
  }
}
@media screen and (min-width: 75em) {
  .calendar-columns {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media screen and (min-width: 87.5em) {
  .calendar-columns {
    gap: 98px;
  }
}
@media screen and (min-width: 100em) {
  .calendar-columns {
    padding-left: 0;
    padding-right: 0;
  }
}
@media screen and (min-width: 48em) {
  .calendar-columns .calendar-item:last-child {
    grid-column: span 2;
  }
}
@media screen and (min-width: 75em) {
  .calendar-columns .calendar-item:last-child {
    grid-column: span 1;
  }
}

.calendar-item {
  background-color: #FFF5E9;
  padding-top: 24px;
  border-radius: 2px;
}
.calendar-item__date {
  display: inline-grid;
  grid-auto-flow: column;
  column-gap: 10px;
  align-items: center;
  background-color: #FED9A9;
  padding: 10px 12px 10px 10px;
  border-radius: 60px;
  margin-bottom: 0;
  padding-right: 24px;
  display: inline-grid;
  margin-left: -29px;
}
.calendar-item__date__icon {
  display: block;
  background-color: #FFB548;
  width: 40px;
  height: 40px;
  border-radius: 40px;
}
.calendar-item__date__copy {
  color: #3D3935;
  margin-bottom: 0;
  font-size: var(--h4-font-size);
  color: #2D1945;
  font-weight: 500;
}
@media screen and (min-width: 48em) {
  .calendar-item__date__copy {
    font-size: var(--h3-font-size);
  }
}
@media screen and (min-width: 87.5em) {
  .calendar-item__date {
    margin-left: -58px;
  }
}
.calendar-item__content {
  padding: 24px 24px 32px;
}
.calendar-item__heading {
  font-size: var(--h3-font-size);
  color: #2D1945;
  line-height: 1;
  margin-bottom: 0.25em;
}
@media screen and (min-width: 48em) {
  .calendar-item__heading {
    font-size: var(--h2-font-size);
  }
}
.calendar-item__description {
  margin-top: calc(var(--body-font-size) / 2);
}
.calendar-item__cta {
  color: #D78000;
  text-transform: uppercase;
  font-weight: 800;
  text-decoration: none;
  display: inline-grid;
  grid-auto-flow: column;
  column-gap: 0.5em;
  align-items: center;
}
.calendar-item__cta path,
.calendar-item__cta circle,
.calendar-item__cta ellipse,
.calendar-item__cta rect,
.calendar-item__cta line,
.calendar-item__cta polygon {
  fill: currentColor;
  transition: fill 250ms ease-in-out;
}

.qa-reminder {
  background-color: #EFEEF7;
}
.qa-reminder .calendar-item__date {
  display: inline-grid;
  grid-auto-flow: column;
  column-gap: 10px;
  align-items: center;
  background-color: #C1BDDE;
  padding: 10px 12px 10px 10px;
  border-radius: 60px;
  margin-bottom: 0;
  padding-right: 24px;
  display: inline-grid;
}
.qa-reminder .calendar-item__date__icon {
  display: block;
  background-color: #655DC6;
  width: 40px;
  height: 40px;
  border-radius: 40px;
}
.qa-reminder .calendar-item__date__copy {
  color: #3D3935;
  margin-bottom: 0;
  font-size: var(--h4-font-size);
  color: #2D1945;
  font-weight: 500;
}
@media screen and (min-width: 48em) {
  .qa-reminder .calendar-item__date__copy {
    font-size: var(--h3-font-size);
  }
}
.qa-reminder .calendar-item__cta {
  color: #655DC6;
}

.qc-reminder {
  background-color: #EEF7F7;
}
.qc-reminder .calendar-item__date {
  display: inline-grid;
  grid-auto-flow: column;
  column-gap: 10px;
  align-items: center;
  background-color: #ABD8D6;
  padding: 10px 12px 10px 10px;
  border-radius: 60px;
  margin-bottom: 0;
  padding-right: 24px;
  display: inline-grid;
}
.qc-reminder .calendar-item__date__icon {
  display: block;
  background-color: #00847F;
  width: 40px;
  height: 40px;
  border-radius: 40px;
}
.qc-reminder .calendar-item__date__copy {
  color: #3D3935;
  margin-bottom: 0;
  font-size: var(--h4-font-size);
  color: #2D1945;
  font-weight: 500;
}
@media screen and (min-width: 48em) {
  .qc-reminder .calendar-item__date__copy {
    font-size: var(--h3-font-size);
  }
}
.qc-reminder .calendar-item__cta {
  color: #00847F;
}

.article {
  display: grid;
  grid-template-columns: 1fr;
  gap: 56px;
  align-items: center;
}
@media screen and (min-width: 62em) {
  .article {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 72px;
  }
}
.article__picture img {
  border-radius: 100%;
}
.article__copy p {
  margin-bottom: 1em;
}
@media screen and (min-width: 35em) {
  .article__copy {
    font-size: 2rem;
  }
}
@media screen and (min-width: 62em) {
  .article__copy {
    font-size: var(--body-font-size);
  }
}
@media screen and (min-width: 75em) {
  .article__copy {
    font-size: 2rem;
  }
}
@media screen and (min-width: 87.5em) {
  .article__copy {
    font-size: 2.4rem;
  }
}

.section--program-partners {
  padding-top: calc(var(--section-small) * 1.5);
  padding-bottom: var(--section-large);
}

@media screen and (min-width: 75em) {
  .program-partners {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: end;
  }
}
.program-partners__title {
  color: #2D1945;
  font-weight: 500;
  font-size: var(--h1-font-size);
  line-height: 1.05;
  margin-bottom: 1em;
  text-align: center;
}
@media screen and (min-width: 48em) {
  .program-partners__title {
    font-size: 4.8rem;
  }
}
@media screen and (min-width: 87.5em) {
  .program-partners__title {
    font-size: 5.6rem;
  }
}
@media screen and (min-width: 35em) {
  .program-partners__title {
    text-align: left;
  }
}
@media screen and (min-width: 75em) {
  .program-partners__title {
    margin-bottom: 0;
  }
}
.program-partners__list {
  padding-left: 80px;
  padding-right: 80px;
}
@media screen and (min-width: 35em) {
  .program-partners__list {
    padding-left: 0;
    padding-right: 0;
    display: grid;
    grid-template-columns: repeat(3, auto);
  }
}
.program-partners .partner-item {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-top: 24px;
  padding-bottom: 24px;
  /* svg ratio sizing */
}
@media screen and (min-width: 35em) {
  .program-partners .partner-item {
    padding-top: 0;
    padding-bottom: 0;
  }
}
@media screen and (min-width: 75em) {
  .program-partners .partner-item {
    padding-left: calc(var(--default-container) * 0.026666667);
    padding-right: calc(var(--default-container) * 0.026666667);
  }
}
@media screen and (min-width: 87.5em) {
  .program-partners .partner-item {
    padding-left: calc(var(--default-container) * 0.0416666667);
    padding-right: calc(var(--default-container) * 0.0416666667);
  }
}
.program-partners .partner-item:after {
  content: "";
  position: absolute;
  height: 8px;
  left: 0;
  right: 0;
  bottom: -4px;
  background-image: url(../images/decal_lined-separator_horizontal.svg);
  background-repeat: repeat-x;
  background-size: auto 100%;
}
@media screen and (min-width: 35em) {
  .program-partners .partner-item:after {
    width: 8px;
    height: auto;
    top: 0;
    bottom: 0;
    left: auto;
    right: -4px;
    background-image: url(../images/decal_lined-separator_vertical.svg);
    background-repeat: repeat-y;
    background-size: 100% auto;
  }
}
.program-partners .partner-item:first-child {
  padding-top: 0;
  padding-left: 0;
}
.program-partners .partner-item:last-child {
  padding-bottom: 0;
  padding-right: 0;
}
.program-partners .partner-item:last-child:after {
  content: none;
}
@media screen and (min-width: 35em) {
  .program-partners .partner-item svg {
    height: calc(var(--default-container) * 0.125);
  }
}
@media screen and (min-width: 62em) {
  .program-partners .partner-item svg {
    height: auto;
  }
}
@media screen and (min-width: 35em) {
  .program-partners .partner-item:first-child svg {
    width: calc(var(--default-container) * 0.125 * 1.48);
  }
}
@media screen and (min-width: 62em) {
  .program-partners .partner-item:first-child svg {
    width: auto;
  }
}
@media screen and (min-width: 35em) {
  .program-partners .partner-item[title*=Flinders] svg {
    width: calc(var(--default-container) * 0.125 * 2.26);
  }
}
@media screen and (min-width: 62em) {
  .program-partners .partner-item[title*=Flinders] svg {
    width: auto;
  }
}
@media screen and (min-width: 35em) {
  .program-partners .partner-item:last-child svg {
    width: calc(var(--default-container) * 0.125 * 2.54);
  }
}
@media screen and (min-width: 62em) {
  .program-partners .partner-item:last-child svg {
    width: auto;
  }
}

.sidebar .widget {
  background-color: #ffffff;
  position: relative;
}
@media screen and (min-width: 62em) {
  .sidebar .widget {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
    margin-top: calc((var(--section) + 1.83333vw) * -1);
  }
}
.sidebar .widget .widget-title {
  padding: calc(24px + var(--body-font-size)) 24px var(--body-font-size);
  text-transform: uppercase;
  font-size: var(--body-font-size);
  font-weight: 700;
  color: #2D1945;
}

.sidebar .widget-RichText {
  padding: calc(16px + var(--body-font-size)) 32px var(--body-font-size) 24px;
}

/* ---- Forms ---- */
.field-element {
  margin-bottom: var(--body-font-size);
}

.field-element--mb0 {
  margin-bottom: 0;
}

/* ---- Placeholders ---- */
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #9c9c9c;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #9c9c9c;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: #9c9c9c;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #9c9c9c;
}

.field-element--dropdown--placeholder .field-input select {
  color: #9c9c9c;
}

/* ---- Auto-fill color ---- */
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  background-color: #DADADA;
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0px 1000px #DADADA inset;
}

input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  outline-color: indigo;
  outline-style: auto;
  outline-width: 2px;
  background-color: #DADADA;
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0px 80px #DADADA inset;
  transition: background-color 5000s ease-in-out 0s;
}

/* All form elements with preceding icon */
.field-element--datepicker .field-input .textbox,
.field-element--daterangepicker .field-input .textbox,
.field-element--datetimepicker .field-input .textbox,
.field-element--datetimerangepicker .field-input .textbox,
.field-element--timepicker .field-input .textbox {
  padding-left: 40px;
}

.field-element--money .field-input .textbox {
  padding-left: 52px;
}

/* Clearfix all field-elements */
.field-element:before,
.field-element:after {
  content: " ";
  display: table;
}

.field-element:after {
  clear: both;
}

/* ---- form colour variants ---- */
/* -- default - grey -- */
.field-input input,
.field-input textarea,
.field-input select {
  border: var(--field-default-border);
  background-color: var(--field-default-background);
  border-radius: 4px;
}

.field-input input:focus,
.field-input textarea:focus,
.field-input select:focus {
  outline-color: indigo;
  outline-style: auto;
  outline-width: 2px;
}

/* -- white -- */
.field-element--grey .field-input input,
.field-element--grey .field-input textarea,
.field-element--grey .field-input select {
  background-color: #F5f5f5;
}

/* ---- Disabled ---- */
.field-element--disabled,
.fieldset-input.fieldset-input--disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.field-element--disabled .textbox[disabled],
.field-element--disabled select[disabled],
.field-element--disabled button[disabled],
.field-element--disabled input[type=checkbox] + label,
.fieldset-input.fieldset-input--disabled input[type=checkbox] + label,
.field-element--disabled input[type=radio] + label,
.fieldset-input.fieldset-input--disabled input[type=radio] + label {
  cursor: not-allowed;
}

/* ---- Labels ---- */
.field-label,
.fieldset__legend {
  display: inline-block;
  font-weight: 700;
  line-height: normal;
  font-family: nunito-sans, helvetica neue, helvetica, roboto, noto, arial, sans-serif;
  padding: 2px 20px 2px 12px;
  background-color: #E4E4E4;
  color: #3D3935;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  position: relative;
  z-index: 1;
  margin-right: 4%;
}
.field-label + .field-input,
.field-label + .field-element__input-set,
.fieldset__legend + .field-input,
.fieldset__legend + .field-element__input-set {
  margin-top: -13px;
}

.field-element--small .field-label,
.field-element--small .fieldset__legend {
  font-size: var(--centi-font-size);
}

.field-label.field-label--xsm {
  font-size: var(--milli-font-size);
}

/* Hidden labels */
.field-element--hidden-label .field-label,
.field-element--hidden-label .fieldset__legend {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.field-element--hidden-label .field-label.focusable:active, .field-element--hidden-label .field-label.focusable:focus,
.field-element--hidden-label .fieldset__legend.focusable:active,
.field-element--hidden-label .fieldset__legend.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

/* ---- Required ---- */
.field-label__required {
  text-indent: -9999px;
  line-height: 0;
  display: inline-block;
}

.field-label__required:after {
  content: "*";
  text-indent: 0;
  display: block;
  line-height: inherit;
}

/* ---- Field helpers ---- */
.field-helper {
  font-weight: 400;
  font-size: var(--milli-font-size);
  margin-bottom: 1em;
}

.field-label .field-helper {
  margin-top: 0;
  margin-bottom: 0.3em;
}

/* ---- Field errors ---- */
.field-element.field-element--error .field-input .textbox,
.field-element.field-element--error .field-input select {
  background-color: white;
}

.field-element.field-element--error input {
  border-color: #FFE1BB !important;
}
.field-element .site-messages {
  margin: 0;
}
.field-element .site-messages__item {
  border: 0px;
}
.field-element .errors {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}
.field-element .errors li {
  margin: 0 0 8px;
  padding: 8px 24px;
  text-align: left;
  border-radius: 40px;
  color: #0f0f0f !important;
  font-family: nunito-sans, helvetica neue, helvetica, roboto, noto, arial, sans-serif;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: normal;
  background: #E9C2B6;
}
.field-element .errors li a {
  color: inherit;
}

.field-element--error .field-label {
  border-left: 4px solid #BD202E;
}

.field-error {
  margin-top: 0.5em;
}

.field-error__list {
  list-style-type: none;
  padding-left: 0;
}

.field-error__list__item {
  margin: 0 0 8px;
  padding: 8px 24px;
  text-align: left;
  border-radius: 40px;
  color: #0f0f0f !important;
  font-family: nunito-sans, helvetica neue, helvetica, roboto, noto, arial, sans-serif;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: normal;
  background: #E9C2B6;
}
.field-error__list__item a {
  color: inherit;
}

/* ---- form style extenders ---- */
.field-element--totalselector__dropdown:before, .daterangepicker.opensright:before {
  position: absolute;
  top: -8px;
  left: 16px;
  display: inline-block;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #9c9c9c;
  border-left: 8px solid transparent;
  border-bottom-color: #9c9c9c;
  content: "";
}

.field-element--totalselector__dropdown:after, .daterangepicker.opensright:after {
  position: absolute;
  top: -7px;
  left: 17px;
  display: inline-block;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #fff;
  border-left: 7px solid transparent;
  content: "";
}

.field-element--colorpicker .textbox.colorpicker {
  padding: calc( 8px/4) 8px;
  height: 40px;
  width: 48px;
}

.field-element--datepicker .field-input,
.field-element--daterangepicker .field-input {
  position: relative;
}

.field-element--datepicker .field-input:before,
.field-element--daterangepicker .field-input:before {
  content: " ";
  display: block;
  position: absolute;
  left: calc( 8px/4);
  top: 0;
  width: 40px;
  height: calc(var(--field-padding-vertical) * 2 + var(--body-line-height) * 1em);
  background-image: url(../images/icon-system/icon_form_datepicker.svg);
  background-repeat: no-repeat;
  background-size: 24px;
  background-position: center center;
  z-index: 1;
}

/* Container Appearance */
.daterangepicker {
  position: absolute;
  background: #fff;
  top: 100px;
  left: 20px;
  padding: 4px;
  margin-top: 2px;
  border-radius: 4px;
  width: 278px;
}

.daterangepicker.opensleft:before {
  position: absolute;
  top: -7px;
  right: 9px;
  display: inline-block;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #9c9c9c;
  border-left: 7px solid transparent;
  border-bottom-color: #9c9c9c;
  content: "";
}

.daterangepicker.opensleft:after {
  position: absolute;
  top: -6px;
  right: 10px;
  display: inline-block;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #ffffff;
  border-left: 6px solid transparent;
  content: "";
}

.daterangepicker.openscenter:before {
  position: absolute;
  top: -7px;
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #9c9c9c;
  border-left: 7px solid transparent;
  border-bottom-color: #9c9c9c;
  content: "";
}

.daterangepicker.openscenter:after {
  position: absolute;
  top: -6px;
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #ffffff;
  border-left: 6px solid transparent;
  content: "";
}

.daterangepicker.dropup {
  margin-top: -5px;
}

.daterangepicker.dropup:before {
  top: initial;
  bottom: -7px;
  border-bottom: initial;
  border-top: 7px solid #9c9c9c;
}

.daterangepicker.dropup:after {
  top: initial;
  bottom: -6px;
  border-bottom: initial;
  border-top: 6px solid #ffffff;
}

.daterangepicker.dropdown-menu {
  max-width: none;
  z-index: 3000;
  display: none;
  border: 1px solid #9c9c9c;
}

.daterangepicker.single .ranges, .daterangepicker.single .calendar {
  float: none;
}

.daterangepicker .calendar {
  display: none;
  max-width: 270px;
  margin: 4px;
}

.daterangepicker.show-calendar .calendar {
  display: block;
}

.daterangepicker .calendar.single .calendar-table {
  border: none;
}

/* Calendars */
.daterangepicker .calendar th, .daterangepicker .calendar td {
  white-space: nowrap;
  text-align: center;
  min-width: 32px;
  border: 1px solid #DADADA;
}

.daterangepicker .calendar-table {
  border: 1px solid #b8b8b8;
  padding: 4px;
  border-radius: 4px;
  background: #ffffff;
}

.daterangepicker table {
  width: 100%;
  margin: 0;
  border: none;
}

.daterangepicker td,
.daterangepicker th {
  text-align: center;
  width: 20px;
  height: 20px;
  white-space: nowrap;
  padding: 2px;
}

.daterangepicker td,
.daterangepicker th.available {
  cursor: pointer;
}

.daterangepicker td.off,
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date,
.daterangepicker td.off.end-date {
  color: #636363;
  background: #F5f5f5;
  border-color: #DADADA;
}

.daterangepicker td.off.in-range {
  background-color: #F5f5f5;
}

.daterangepicker td.off.active {
  background-color: #7f7f7f;
  color: #ffffff;
}

.daterangepicker td.disabled,
.daterangepicker option.disabled {
  color: #636363;
  cursor: not-allowed;
  text-decoration: line-through;
}

.daterangepicker td.available:hover,
.daterangepicker th.available:hover {
  background: #ecb9bb;
}

.daterangepicker td.in-range {
  background: #f1cdce;
}

.daterangepicker td.active, .daterangepicker td.active:hover {
  background-color: #D05559;
  color: #ffffff;
}

.daterangepicker td.week, .daterangepicker th.week {
  font-size: 80%;
  color: #9c9c9c;
}

.daterangepicker select.monthselect,
.daterangepicker select.yearselect {
  font-size: 12px;
  padding: 1px;
  height: auto;
  margin: 0;
  cursor: default;
}

.daterangepicker select.monthselect::-ms-expand,
.daterangepicker select.yearselect::-ms-expand {
  display: none;
}

.daterangepicker select.yearselect,
.daterangepicker select.monthselect,
.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.secondselect,
.daterangepicker select.ampmselect {
  margin-bottom: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 62px;
  background-color: #ffffff;
  outline: none;
  border-radius: 4px;
  font-size: 1.4rem;
  line-height: 1.2;
  padding: 5px 23px 5px 8px;
  border: 1px solid #9c9c9c;
  background-image: url(../images/icon-system/icon_form_arrow-down.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center right;
}

.daterangepicker select.monthselect {
  margin-right: 2%;
  width: 40%;
}

.daterangepicker select.yearselect {
  width: 44%;
}

.daterangepicker th.month {
  width: auto;
}

/* Previous/Next buttons */
.daterangepicker th.prev,
.daterangepicker th.next {
  padding: 1px 2px;
}

.daterangepicker th.prev .daterangepicker-prev-icon,
.daterangepicker th.next .daterangepicker-next-icon {
  width: 12px;
  height: 16px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto;
  display: block;
  margin: 0 auto;
}

.daterangepicker th.next .daterangepicker-next-icon {
  background-image: url(../images/icon-system/icon_form_arrow-right.svg);
}

.daterangepicker th.prev .daterangepicker-prev-icon {
  background-image: url(../images/icon-system/icon_form_arrow-left.svg);
}

.daterangepicker th.prev,
.daterangepicker th.next,
.daterangepicker th.month,
.daterangepicker .calendar-table thead tr:first-child th {
  border: none;
  background: none;
}

.daterangepicker th.prev:hover,
.daterangepicker th.next:hover {
  background: none;
  opacity: 0.8;
}

.daterangepicker .calendar-table thead tr:nth-child(2) th {
  background-color: #F5f5f5;
}

/* Text Input Above Each Calendar */
.daterangepicker .input-mini {
  border: 1px solid #9c9c9c;
  border-radius: 4px;
  color: #474747;
  display: block;
  height: 32px;
  line-height: 32px;
  vertical-align: middle;
  margin: 0 0 calc(8px/2) 0;
  padding: 0 16px;
  width: 100%;
}

.daterangepicker .input-mini.active {
  border: 1px solid #655DC6;
  background-color: #F5f5f5;
}

.daterangepicker .input-mini:focus {
  outline: 4px auto #655DC6;
  outline-offset: -2px;
}

.daterangepicker .daterangepicker_input i {
  position: absolute;
  left: 8px;
  top: 7px;
  color: #474747;
}

.daterangepicker .daterangepicker_input {
  position: relative;
}

/* Time Picker */
.daterangepicker .calendar-time {
  text-align: center;
  margin: 5px auto;
  line-height: 30px;
  position: relative;
}

.daterangepicker .calendar-time select.disabled {
  color: #9c9c9c;
  cursor: not-allowed;
}

/* Predefined Ranges */
.daterangepicker .ranges {
  font-size: 11px;
  float: none;
  margin: 4px;
  text-align: left;
}

.daterangepicker .applyBtn {
  background-color: #655DC6;
  color: #ffffff;
}

.daterangepicker .applyBtn:hover,
.daterangepicker .applyBtn:focus {
  background-color: #473eb2;
  color: #ffffff;
}

.daterangepicker .cancelBtn {
  background-color: #b8b8b8;
  color: #2b2b2b;
}

.daterangepicker .cancelBtn:hover,
.daterangepicker .cancelBtn:focus {
  background-color: #9f9f9f;
  color: #2b2b2b;
}

.daterangepicker .ranges ul {
  list-style: none;
  margin: 0 auto 12px;
  padding: 0;
  width: 100%;
}

.daterangepicker .ranges li {
  font-size: 1.3rem;
  background: #DADADA;
  border: 1px solid #F5f5f5;
  color: #2b2b2b;
  padding: 4px 12px 3px;
  margin-bottom: 7px;
  border-radius: 5px;
  cursor: pointer;
  line-height: 1.2;
}

.daterangepicker .ranges li.active, .daterangepicker .ranges li:hover {
  background: #D05559;
  border: 1px solid #D05559;
  color: #ffffff;
}

@supports (display: grid) {
  .range_inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 8px;
  }
}
/* BP Smaller */
@media screen and (min-width: 35em) {
  /* 560px */
  .daterangepicker {
    width: auto;
  }

  .daterangepicker .ranges ul {
    width: 160px;
  }

  .daterangepicker.single .ranges ul {
    width: 100%;
  }

  .daterangepicker .calendar.left .calendar-table {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .daterangepicker .calendar.right .calendar-table {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .daterangepicker .calendar.left {
    clear: left;
    margin-right: 0;
  }

  .daterangepicker.single .calendar.left {
    clear: none;
  }

  .daterangepicker.single .ranges,
.daterangepicker.single .calendar {
    float: left;
  }

  .daterangepicker .calendar.right {
    margin-left: 0;
  }

  .daterangepicker .left .daterangepicker_input {
    padding-right: 12px;
  }

  .daterangepicker .calendar.left .calendar-table {
    padding-right: 12px;
  }

  .daterangepicker .ranges,
.daterangepicker .calendar {
    float: left;
  }
}
/* BP small */
@media screen and (min-width: 48em) {
  /* 768px */
  .daterangepicker .ranges {
    width: auto;
    float: left;
  }

  .daterangepicker .calendar.left {
    clear: none;
  }
}
/* - Date/time picker - */
.field-element--datetimepicker .field-input,
.field-element--datetimerangepicker .field-input {
  position: relative;
}

.field-element--datetimepicker .field-input:before,
.field-element--datetimerangepicker .field-input:before {
  content: " ";
  display: block;
  position: absolute;
  left: calc( 8px/4);
  top: 0;
  width: 40px;
  height: calc(var(--field-padding-vertical) * 2 + var(--body-line-height) * 1em);
  background-image: url(../images/icon-system/icon_form_datetimepicker.svg);
  background-repeat: no-repeat;
  background-size: 24px;
  background-position: center center;
  z-index: 1;
}

/* fieldset styles */
fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

.field-element--checkboxlist--columns .field-element__input-set {
  columns: 3;
}

.field-element__input-set {
  display: block;
  width: 100%;
  outline: none;
  -webkit-appearance: none;
  line-height: var(--body-line-height);
  padding: calc(var(--field-padding-vertical) * 1.25) var(--field-padding-horizontal) calc(var(--field-padding-vertical) * 0.75);
  border: var(--field-default-border);
  border-radius: 4px;
}

/* Hide checkbox and radio field element) */
input[type=checkbox],
input[type=radio] {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
input[type=checkbox].focusable:active, input[type=checkbox].focusable:focus,
input[type=radio].focusable:active,
input[type=radio].focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

input[type=checkbox] + label,
input[type=radio] + label,
.field-element--small input[type=checkbox] + label,
.field-element--small input[type=radio] + label {
  padding-left: 32px;
  position: relative;
  cursor: pointer;
  display: inline-block;
}

/* -- Field set pseudo elements -- */
input[type=checkbox] + label:before,
input[type=radio] + label:before,
.field-element--small input[type=checkbox] + label:before,
.field-element--small input[type=radio] + label:before {
  content: " ";
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 12px;
  position: relative;
  top: calc( 8px/2);
  border: var(--field-default-border);
  background-color: var(--field-default-background);
  border-radius: 2px;
  margin-left: -32px;
}

/* Radio button (pseudo element) */
input[type=radio] + label:before,
.field-element--small input[type=radio] + label:before {
  border-radius: 12px;
}

/* -- Field set pseudo elements : checked -- */
input[type=checkbox]:checked + label:after,
input[type=radio]:checked + label:after,
.field-element--small input[type=checkbox]:checked + label:after,
.field-element--small input[type=radio]:checked + label:after {
  content: " ";
  position: absolute;
  left: 1px;
  top: 6px;
}

/* Checkbox box checked (pseudo element) */
input[type=checkbox]:checked + label:before,
.field-element--small input[type=checkbox]:checked + label:before {
  background-color: #2D1945;
  border-color: #2D1945;
}

input[type=checkbox]:checked + label:after,
.field-element--small input[type=checkbox]:checked + label:after {
  content: " ";
  background-image: url(../images/icon-system/icon_form_tick-md-white.svg);
  background-position: left top;
  background-repeat: no-repeat;
  background-size: 90%;
  width: 24px;
  height: 24px;
}

/* Radio dot (pseudo element) */
input[type=radio]:checked + label:before,
.field-element--small input[type=radio]:checked + label:before {
  background-color: #2D1945;
  border-color: #2D1945;
}

input[type=radio]:checked + label:after,
.field-element--small input[type=radio]:checked + label:after {
  left: 7px;
  top: 11px;
  width: 9.6px;
  height: 9.6px;
  border-radius: 4.8px;
  background: #ffffff;
}

/* White */
.field-element--white input[type=checkbox] + label:before,
.field-element--white input[type=radio] + label:before {
  background-color: #ffffff;
}

/* input focus state */
.fieldset--multiradio:focus-within {
  outline-color: indigo;
  outline-style: auto;
  outline-width: 2px;
}

input[type=checkbox]:focus + label {
  outline-color: indigo;
  outline-style: auto;
  outline-width: 2px;
  outline-offset: -3px;
}

.field-element--money .field-input {
  position: relative;
}

.field-element--money .field-input .money-symbol:before {
  content: "¤";
  /* Generic currency sign */
  position: absolute;
  display: block;
  left: calc( 8px/4);
  top: calc(50% - 14px);
  color: #474747;
  border-right: 1px solid #9c9c9c;
  font-weight: bold;
  font-size: calc(var(--body-font-size) * 1.2);
  width: 40px;
  text-align: center;
}

.field-element--money .field-input .money-symbol--dollar:before {
  content: "$";
  /* Dollars */
}

.field-element--money .field-input .money-symbol--pound:before {
  content: "£";
  /* Pounds */
}

.field-element--money .field-input .money-symbol--yen:before {
  content: "¥";
  /* Yen */
}

.field-element--money .field-input .money-symbol--indian_rupee:before {
  content: "₹";
  /* Indian rupee */
}

.field-element--range .field-input .textbox {
  background: none;
  padding: 0;
  border-radius: 0;
  border: none;
  background: none !important;
}

.field-element--range .field-input input[type=range] + div {
  color: #474747;
  font-weight: bold;
}

.field-element--range .field-input input[type=range]:focus + div {
  color: #655DC6;
  font-weight: bold;
}

input[type=range] {
  -webkit-appearance: none;
  width: 100%;
  background: transparent;
  /* Otherwise white in Chrome */
  margin: 0;
}

input[type=range]:focus {
  outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  box-shadow: none;
  background: #9c9c9c;
  border-radius: calc( 8px/2);
  border: none;
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  box-shadow: 1px 1px 2px #2e2e2e;
  border: none;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  background: #474747;
  cursor: pointer;
  margin-top: -14px;
  -webkit-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: #9c9c9c;
}

input[type=range]:focus::-webkit-slider-thumb {
  background: #655DC6;
  box-shadow: 2px 2px 4px #474747;
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: calc( 8px/2);
  cursor: pointer;
  box-shadow: none;
  background: #b8b8b8;
  border-radius: calc( 8px/2);
  border: none;
}

input[type=range]::-moz-range-thumb {
  box-shadow: 1px 1px 2px #2e2e2e;
  border: none;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  background: #474747;
  cursor: pointer;
}

input[type=range]::-ms-track {
  width: 100%;
  height: calc( 8px/2);
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 18px 0;
  color: transparent;
}

input[type=range]::-ms-fill-lower {
  background: #b8b8b8;
  border: none;
  border-radius: calc( 8px/2);
  box-shadow: none;
}

input[type=range]::-ms-fill-upper {
  background: #b8b8b8;
  border: none;
  border-radius: calc( 8px/2);
  box-shadow: none;
}

input[type=range]::-ms-thumb {
  box-shadow: 1px 1px 2px #2e2e2e;
  border: none;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  background: #474747;
  cursor: pointer;
}

input[type=range]:focus::-ms-fill-lower {
  background: #b8b8b8;
}

input[type=range]:focus::-ms-fill-upper {
  background: #b8b8b8;
}

/* IE hack fix -
if not IE, apply these styles
has nothing to do with (display:block)
and everything to do with IE not supporting @supports */
@supports (display: block) {
  input[type=range] {
    margin: 12.8px 0;
  }
}
.field-input select {
  -moz-appearance: none;
  background-image: url(../images/icon-system/icon_form_arrow-down.svg), url(../images/form_bg-fill.svg);
  background-repeat: no-repeat, repeat-Y;
  background-position: center right 8px, center right;
  background-size: 24px, 40px;
  padding-right: 52px;
}

.field-input select[multiple] {
  background-image: none;
  padding-right: 20px;
}

.field-input select::-ms-expand {
  display: none;
}

/* Multiple selects */
.field-element--select--multiple select {
  padding: 8px;
  background-image: none;
}

.field-element--select--multiple option {
  padding: 8px;
}

.field-element--range .field-input .textbox {
  background: none;
  padding: 0;
  border-radius: 0;
  border: none;
  background: none !important;
}

.field-element--range .field-input input[type=range] + div {
  color: #474747;
  font-weight: bold;
}

.field-element--range .field-input input[type=range]:focus + div {
  color: #655DC6;
  font-weight: bold;
}

input[type=range] {
  -webkit-appearance: none;
  width: 100%;
  background: transparent;
  /* Otherwise white in Chrome */
  margin: 0;
}

input[type=range]:focus {
  outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  box-shadow: none;
  background: #9c9c9c;
  border-radius: calc( 8px/2);
  border: none;
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  box-shadow: 1px 1px 2px #2e2e2e;
  border: none;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  background: #474747;
  cursor: pointer;
  margin-top: -14px;
  -webkit-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: #9c9c9c;
}

input[type=range]:focus::-webkit-slider-thumb {
  background: #655DC6;
  box-shadow: 2px 2px 4px #474747;
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: calc( 8px/2);
  cursor: pointer;
  box-shadow: none;
  background: #b8b8b8;
  border-radius: calc( 8px/2);
  border: none;
}

input[type=range]::-moz-range-thumb {
  box-shadow: 1px 1px 2px #2e2e2e;
  border: none;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  background: #474747;
  cursor: pointer;
}

input[type=range]::-ms-track {
  width: 100%;
  height: calc( 8px/2);
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 18px 0;
  color: transparent;
}

input[type=range]::-ms-fill-lower {
  background: #b8b8b8;
  border: none;
  border-radius: calc( 8px/2);
  box-shadow: none;
}

input[type=range]::-ms-fill-upper {
  background: #b8b8b8;
  border: none;
  border-radius: calc( 8px/2);
  box-shadow: none;
}

input[type=range]::-ms-thumb {
  box-shadow: 1px 1px 2px #2e2e2e;
  border: none;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  background: #474747;
  cursor: pointer;
}

input[type=range]:focus::-ms-fill-lower {
  background: #b8b8b8;
}

input[type=range]:focus::-ms-fill-upper {
  background: #b8b8b8;
}

/* IE hack fix -
if not IE, apply these styles
has nothing to do with (display:block)
and everything to do with IE not supporting @supports */
@supports (display: block) {
  input[type=range] {
    margin: 12.8px 0;
  }
}
.field-input .textbox,
.field-input select,
.field-element--fileselector .fs-preview-wrapper {
  display: block;
  width: 100%;
  outline: none;
  -webkit-appearance: none;
  line-height: var(--body-line-height);
  padding: calc(var(--field-padding-vertical) * 1.25) var(--field-padding-horizontal) calc(var(--field-padding-vertical) * 0.75);
}

/* time picker */
.field-element--timepicker .field-input {
  position: relative;
}

.field-element--timepicker .field-input:before {
  content: " ";
  display: block;
  position: absolute;
  left: calc( 8px/4);
  top: calc(50% - 12px);
  width: 40px;
  background-image: url(../images/icon-system/icon_form_timepicker.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  border-right: 1px solid #9c9c9c;
}

/* Timepicker modal */
.ui-timepicker {
  display: block;
  position: absolute;
  height: 200px;
  overflow: auto;
  z-index: 9999 !important;
}

.ui-timepicker-standard {
  background-color: #ffffff;
  border: 1px solid #9c9c9c;
  display: block;
  margin: 4px 0 0;
  padding: 6px;
  list-style: none outside none;
  border-radius: 4px;
}

.ui-timepicker-standard a {
  color: #2b2b2b;
}

.ui-timepicker-standard .ui-state-hover {
  background-color: #DADADA;
}

.ui-timepicker-standard .ui-menu-item {
  clear: left;
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;
}

.ui-timepicker-standard .ui-menu-item a {
  display: block;
  padding: 6px 12px;
  line-height: 1.5;
  text-decoration: none;
  border-radius: 4px;
}

.ui-timepicker-hidden {
  display: none;
}

.field-element--totalselector {
  position: relative;
}

.field-element--totalselector .field-input .total-selector__output {
  cursor: pointer;
  background-image: url(../images/icon-system/icon_form_arrow-down.svg), url(../images/form_bg-fill.svg);
  background-repeat: no-repeat, repeat-Y;
  background-position: center right 8px, center right;
  background-size: 24px, 40px;
  padding-right: 52px;
}

.field-element--totalselector__dropdown {
  position: absolute;
  background: #ffffff;
  top: 100%;
  left: 0;
  right: 0;
  padding: 20px 24px;
  margin-top: calc( 8px/4);
  border-radius: calc( 8px/2);
  z-index: 800;
  border: 1px solid #9c9c9c;
  display: none;
  white-space: nowrap;
}

.field-element--totalselector.field-element--totalselector--active .field-element--totalselector__dropdown {
  display: block;
}

.total-selector__dropdown__field {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 0 0 16px;
}

.total-selector__dropdown__field:last-child {
  margin-bottom: 0;
}

.total-selector__dropdown__field__labels {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.total-selector__dropdown__field__buttons {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.total-selector__dropdown__field__button {
  -webkit-appearance: none;
  border: 1px solid #7f7f7f;
  border-radius: 4px;
  outline: none;
  background: none;
  padding: 0;
  height: 32px;
  width: 32px;
  color: #636363;
  cursor: pointer;
  font-size: 1.4rem;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: center center;
}

.total-selector__dropdown__field__button.total-selector__dropdown__field__button--decrease {
  background-image: url(../images/icon-system/icon_form_minus.svg);
  background-position: left 8px -1 center;
}

.total-selector__dropdown__field__button.total-selector__dropdown__field__button--increase {
  background-image: url(../images/icon-system/icon_form_plus.svg);
  background-position: right 8px -1 center;
}

.total-selector__dropdown__field__button:focus {
  color: #636363;
  border-color: #636363;
  background-color: #F5f5f5;
}

.total-selector__dropdown__field__button:hover {
  color: #636363;
  border-color: #636363;
}

/* Min / max */
.total-selector__dropdown__field__button.total-selector__dropdown__field__button--min,
.total-selector__dropdown__field__button.total-selector__dropdown__field__button--max {
  opacity: 0.5;
  pointer-events: none;
  color: #636363;
  border-color: #b8b8b8;
}

.total-selector__dropdown__field__total {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding: 4px 6px;
  width: 50px;
  text-align: center;
  font-size: 1.8rem;
}

.total-selector__dropdown__field__labels p {
  display: block;
  margin-right: 8px;
}

.total-selector__dropdown__field__labels__title {
  margin: 0;
  font-size: 1.6rem;
}

.total-selector__dropdown__field__labels__helper {
  margin: 0;
  font-size: var(--milli-font-size);
}

.field-element--totalselector__fields {
  display: none;
}

/* Done button */
.total-selector__dropdown__close__button-wrap {
  text-align: right;
  padding-top: 10px;
}

/* BP Extra small */
@media only screen and (min-width: 37.5em) {
  /* 600px */
  .field-element--totalselector__dropdown {
    right: auto;
    min-width: 320px;
  }

  .total-selector__dropdown__field__labels__helper {
    font-size: 1.5rem;
    line-height: 1;
  }

  .total-selector__dropdown__field {
    margin: 0 0 24px;
  }
}
/* BP Small */
@media only screen and (min-width: 48.063em) {
  /* 769px */
  /* Don't really need this close button above tablet */
  .total-selector__dropdown__close__button-wrap {
    display: none;
  }
}
.field-element--upload .field-input input[type=file] {
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: transparent;
}

input[type=file]::-webkit-file-upload-button {
  background: #000;
  padding: 12px 16px;
  border: 1px solid #9c9c9c;
  border-radius: 4px;
  background-color: #F5f5f5;
  transition: all 250ms ease-in-out;
  outline: none;
}

input[type=file]:hover::-webkit-file-upload-button {
  background-color: #DADADA;
  border: 1px solid #838383;
}

input[type=file]:focus::-webkit-file-upload-button {
  background-color: #DADADA;
  outline: none;
}

input[type=file]:active::-webkit-file-upload-button {
  outline: none;
  background-color: #b8b8b8;
}

input[type=file]:focus {
  outline-color: indigo;
  outline-style: auto;
  outline-width: 2px;
}

input[type=file i]:focus {
  outline-offset: 0;
}

/* ---- white ---- */
.field-element--white input[type=file]::-webkit-file-upload-button {
  background-color: #ffffff;
}

.field-element--white input[type=file]:hover::-webkit-file-upload-button {
  background-color: #DADADA;
  border: 1px solid #838383;
}

.field-element--white input[type=file]:focus::-webkit-file-upload-button {
  background-color: #DADADA;
}

.field-element--white input[type=file]:active::-webkit-file-upload-button {
  background-color: #b8b8b8;
}

.submit-bar {
  text-align: right;
  background: #DADADA;
  padding: 16px;
  margin: 16px 0;
}

.sidebar .submit-bar {
  padding: 12px 0;
}

.submit-bar a {
  margin-right: 20px;
}

.field-element .button[type=submit],
form.row .button[type=submit] {
  margin-top: 13px;
}

.site-search-form {
  margin: 0 0 40px;
}

.site-search-form--header {
  margin: 0;
}

.search-result {
  border-top: 1px solid #9c9c9c;
  margin: 20px 0;
  padding: 20px 0;
}

.search-result p:last-child {
  margin-bottom: 0;
}

.g-recaptcha {
  padding-bottom: 15px;
}

/* ---- Clearable fields ---- */
.field-clearable__wrap {
  position: relative;
}

.field-clearable__clear {
  outline: none;
  border: none;
  background: none;
  padding: 0;
  color: #D4D7DE;
  position: absolute;
  right: 6px;
  top: 9px;
  top: calc(50% - 11px);
  background-image: url(../../../media/images/form-icons.svg);
  background-repeat: no-repeat;
  background-position: top -1152px left 3px;
  width: 22px;
  height: 22px;
}

.field-clearable__clear:hover {
  background-position: top -1208px left 3px;
}

.field-element--disabled .field-clearable__clear {
  display: none;
}

.field-element--upload .field-input input[type=file] {
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: transparent;
}

input[type=file]::-webkit-file-upload-button {
  padding: calc( 8px/2) 8px;
  border: 1px solid #9c9c9c;
  border-radius: 4px;
  background-color: #F5f5f5;
  transition: all 250ms ease-in-out;
  outline: none;
}

input[type=file]:hover::-webkit-file-upload-button {
  background-color: #DADADA;
  border: 1px solid #838383;
}

input[type=file]:focus::-webkit-file-upload-button {
  background-color: #DADADA;
  outline: none;
}

input[type=file]:active::-webkit-file-upload-button {
  outline: none;
  background-color: #b8b8b8;
}

input[type=file]:focus {
  outline-color: indigo;
  outline-style: auto;
  outline-width: 2px;
}

input[type=file i]:focus {
  outline-offset: 0;
}

/* ---- white ---- */
.field-element--white input[type=file]::-webkit-file-upload-button {
  background-color: #ffffff;
}

.field-element--white input[type=file]:hover::-webkit-file-upload-button {
  background-color: #DADADA;
  border: 1px solid #838383;
}

.field-element--white input[type=file]:focus::-webkit-file-upload-button {
  background-color: #DADADA;
}

.field-element--white input[type=file]:active::-webkit-file-upload-button {
  background-color: #b8b8b8;
}

.field-element--upload-inline {
  background-color: #F5f5f5;
  padding: 16px 16px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
}
.field-element--upload-inline label {
  display: block;
  cursor: pointer;
  font-weight: bold;
}
.field-element--upload-inline label:after {
  content: "select";
  font-weight: normal;
  background: #D05559;
  color: #ffffff;
  padding: calc(8px/2) 16px;
  border-radius: 4px;
  margin: 0 0 0 16px;
  transition: all 250ms ease-in-out;
}
.field-element--upload-inline label:hover:after {
  content: "select";
  background-color: #aa2f33;
}
.field-element--upload-inline input:focus + label,
.field-element--upload-inline input:active + label {
  outline-color: indigo;
  outline-style: auto;
  outline-width: 2px;
}
.field-element--upload-inline input[type=file] {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}

/* ------------------------------------------------------
**** LAYOUTS
------------------------------------------------------ */
/* ---- Global ---- */
.header {
  background: #2D1945;
  padding: 16px 0 20px;
  position: relative;
  margin-bottom: calc(1.70833333vw + 20px);
}
@media screen and (min-width: 150em) {
  .header {
    max-width: 2400px;
    margin: 0 auto calc((0.0170833333 * 2400px) + 20px);
  }
}
@media screen and (max-width: 61.9375em) {
  .header {
    padding-top: 0;
    padding-bottom: 24px;
  }
}
@media screen and (max-width: 61.9375em) {
  .header .row {
    justify-content: center;
  }
}
@media screen and (max-width: 61.9375em) {
  .header .row .col-xs-grow {
    display: none;
  }
}
.header__artwork {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  height: calc(1.70833333vw + 20px);
  background: url(../images/qaams-artwork.jpg);
  background-position: center 38%;
  background-size: 102%;
  background-repeat: no-repeat;
}
@media screen and (min-width: 150em) {
  .header__artwork {
    height: calc((0.0170833333 * 2400px) + 20px);
  }
}
.header__artwork .header-block-colour-curve {
  margin-top: -0.5px;
  display: block;
  width: 100vw;
  height: 0.875vw;
}
@media screen and (min-width: 150em) {
  .header__artwork .header-block-colour-curve {
    width: 2400px;
    height: calc(0.00875 * 2400px);
  }
}
.header__artwork .header-artwork-negative-curve {
  margin-top: 21px;
  display: block;
  width: 100vw;
  height: 0.8333333333vw;
}
@media screen and (min-width: 150em) {
  .header__artwork .header-artwork-negative-curve {
    width: 2400px;
    height: calc(0.008333333333 * 2400px);
  }
}
.header .header__logo {
  display: block;
}
.header__rhs-column {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.header__rhs-column__nav-btns {
  text-decoration: none;
  display: grid;
  gap: 16px;
  grid-auto-flow: column;
  align-items: center;
}
.header__login-button {
  margin-top: 24px;
  margin-bottom: 24px;
}
@media screen and (max-width: 61.9375em) {
  .header__login-button {
    display: none;
  }
}

.header__search .field-element {
  margin-bottom: 0;
}

#footer {
  position: relative;
  background-color: #2D1945;
  color: #ffffff;
  margin-top: calc(1.5416666667vw + 20px);
}
#footer a {
  color: inherit;
}
#footer a:hover, #footer a:focus, #footer a:active {
  color: inherit;
  opacity: 0.75;
}
@media screen and (min-width: 150em) {
  #footer {
    max-width: 2400px;
    margin-left: auto;
    margin-right: auto;
  }
}

.footer {
  position: relative;
  overflow: hidden;
  padding: var(--section-medium) 0;
}
.footer:before, .footer:after {
  content: "";
  position: absolute;
  border-radius: 100%;
  height: 1024px;
  width: 1024px;
  top: 50%;
  transform: translateY(-50%);
  right: calc(var(--default-container-gutter) + var(--default-container) * 0.4791666667);
}
@media screen and (min-width: 62em) {
  .footer:before, .footer:after {
    height: calc(var(--default-container) * 0.7229166667);
    width: calc(var(--default-container) * 0.7229166667);
    top: var(--section);
    right: calc(var(--default-container-gutter) + var(--default-container) * 0.4791666667);
    transform: none;
  }
}
.footer:before {
  background: url(../images/qaams-artwork.jpg);
  background-position: 50% 60%;
  background-size: 102%;
  background-repeat: no-repeat;
  opacity: 0.1;
}
.footer:after {
  mix-blend-mode: color;
  background-color: #2D1945;
}
.footer__artwork {
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  height: calc(1.5416666667vw + 20px);
  background: url(../images/qaams-artwork.jpg);
  background-position: center 30.5%;
  background-size: 102%;
  background-repeat: no-repeat;
}
@media screen and (min-width: 150em) {
  .footer__artwork {
    height: calc((0.015416666667 * 2400px) + 20px);
  }
}
.footer__artwork .footer-artwork-negative-curve-top {
  margin-top: -0.5px;
  display: block;
  width: 100vw;
  height: 0.7916666667vw;
  margin-bottom: 21px;
}
@media screen and (min-width: 150em) {
  .footer__artwork .footer-artwork-negative-curve-top {
    width: 2400px;
    height: calc(0.007916666667 * 2400px);
  }
}
.footer__artwork .footer-curve {
  display: block;
  width: 100vw;
  height: 0.75vw;
}
@media screen and (min-width: 150em) {
  .footer__artwork .footer-curve {
    width: 2400px;
    height: calc(0.0075 * 2400px);
  }
}
.footer .container {
  position: relative;
  z-index: 1;
}
@media screen and (min-width: 48em) {
  .footer .container {
    padding-left: var(--container-padding);
    padding-right: var(--container-padding);
  }
}
@media screen and (min-width: 75em) {
  .footer .container {
    padding-left: 0;
    padding-right: 0;
  }
}
@media screen and (min-width: 87.5em) {
  .footer .container {
    padding-left: var(--container-padding);
    padding-right: var(--container-padding);
  }
}
.footer > .container:first-child {
  display: grid;
  grid-template-rows: repeat(4, auto);
  gap: 56px;
}
@media screen and (min-width: 62em) {
  .footer > .container:first-child {
    grid-template-columns: 2fr 3fr;
    grid-template-rows: repeat(2, auto);
    grid-auto-flow: column;
    gap: 56px 80px;
  }
}
@media screen and (min-width: 75em) {
  .footer > .container:first-child {
    gap: 80px;
    grid-template-columns: 9fr 7fr;
    grid-template-rows: repeat(2, auto);
  }
}
@media screen and (min-width: 35em) {
  .footer__quicklinks {
    columns: 2;
  }
}
@media screen and (min-width: 62em) {
  .footer__quicklinks {
    columns: 1;
    border-top: 3px solid #C1BDDE;
    border-bottom: 3px solid #C1BDDE;
    padding-top: var(--section);
    padding-bottom: var(--section);
    margin-top: 56px;
  }
}
@media screen and (min-width: 75em) {
  .footer__quicklinks {
    columns: 2;
  }
}
.footer__quicklinks .block-list {
  margin-bottom: 0;
}
.footer__socials {
  grid-row: 4;
}
@media screen and (min-width: 62em) {
  .footer__socials {
    grid-row: auto;
  }
}
.footer__acknowledgement {
  position: relative;
  border-top: 3px solid #C1BDDE;
  border-bottom: 3px solid #C1BDDE;
  padding-top: 80px;
  padding-bottom: var(--section-small);
  margin-top: 56px;
  z-index: 1;
}
@media screen and (min-width: 48em) {
  .footer__acknowledgement {
    padding-top: var(--section);
  }
}
.footer__acknowledgement__header-icon, .footer__acknowledgement__footer-icon {
  content: "";
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  background-color: #655DC6;
  border: 12px solid #2D1945;
  border-radius: 100%;
}
.footer__acknowledgement__header-icon {
  top: -66px;
  width: 132px;
  height: 132px;
}
.footer__acknowledgement__footer-icon {
  bottom: -32px;
  width: 64px;
  height: 64px;
}
.footer__acknowledgement__flags {
  text-align: center;
}
.footer__acknowledgement__flags svg {
  width: 135px;
  height: auto;
}
.footer__acknowledgement h2:nth-of-type(1) {
  text-align: center;
  margin-bottom: 1em;
  font-size: var(--h3-font-size);
}
.footer__warning {
  display: inline-grid;
  grid-auto-flow: column;
  column-gap: 10px;
  align-items: center;
  background-color: #C1BDDE;
  padding: 10px 12px 10px 10px;
  border-radius: 60px;
  margin-bottom: 0;
  position: relative;
  z-index: 1;
}
.footer__warning__icon {
  display: block;
  background-color: #655DC6;
  width: 40px;
  height: 40px;
  border-radius: 40px;
}
.footer__warning__copy {
  color: #3D3935;
  margin-bottom: 0;
  font-size: var(--centi-font-size);
}
.footer__footer {
  padding-top: var(--section);
  font-size: var(--centi-font-size);
}
@media screen and (min-width: 35em) {
  .footer__footer > .container {
    display: flex;
  }
}
.footer .footer__text {
  flex: 1 1 auto;
  margin: 0;
}
.footer .footer__text > * {
  display: block;
  margin-bottom: 1em;
}
@media screen and (min-width: 62em) {
  .footer .footer__text > * {
    display: inline-block;
    margin-left: calc((var(--default-container) - 236px) * 0.0465116279);
    margin-bottom: 0;
  }
}
@media screen and (min-width: 100em) {
  .footer .footer__text > * {
    margin-left: 56px;
  }
}
.footer .footer__text > *:first-child {
  margin-left: 0;
}
.footer .footer__text > *:last-child {
  margin-bottom: 0;
}
.footer .footer__text a {
  text-decoration: none;
  letter-spacing: 0.04687em;
  text-transform: uppercase;
  font-weight: 700;
}
.footer .website-attribution {
  align-self: flex-end;
  flex: 0 0 auto;
  margin: 0;
}
.footer .website-attribution__link {
  display: block;
  transition: filter 250ms ease;
}
.footer .website-attribution svg {
  width: 20px;
  height: auto;
}

.login-form {
  padding-top: 56px;
}
@media screen and (min-width: 48em) {
  .login-form {
    padding-top: var(--section-small);
  }
}
@media screen and (min-width: 62em) {
  .login-form {
    padding-top: 24px;
  }
}
@media screen and (min-width: 75em) {
  .login-form {
    padding-top: 16px;
  }
}
@media screen and (min-width: 87.5em) {
  .login-form {
    padding-top: 24px;
  }
}
@media screen and (min-width: 100em) {
  .login-form {
    padding-top: var(--section-small);
  }
}
@media screen and (min-width: 75em) {
  .login-form > .container {
    display: grid;
    grid-template-columns: 380px 1fr;
    align-items: end;
    gap: 24px;
  }
}
@media screen and (min-width: 87.5em) {
  .login-form > .container {
    gap: 32px;
    grid-template-columns: 2fr 5fr;
  }
}
@media screen and (min-width: 100em) {
  .login-form > .container {
    padding-right: var(--container-padding);
    padding-left: var(--container-padding);
  }
}
.login-form .login-form__preamble {
  margin-bottom: 1em;
}
@media screen and (min-width: 48em) {
  .login-form .login-form__preamble {
    display: flex;
    flex-direction: row;
    gap: 24px;
    margin-bottom: 0;
  }
}
@media screen and (min-width: 75em) {
  .login-form .login-form__preamble {
    display: block;
  }
}
.login-form .login-form__title {
  flex: 1 0 auto;
  margin-bottom: 12px;
  color: #2D1945;
  font-size: 3.2rem;
}
.login-form .attention-pill {
  flex: 1 1 auto;
  padding-right: 20px;
}
@media screen and (min-width: 48em) {
  .login-form .attention-pill {
    max-width: 380px;
    display: inline-flex;
  }
}
@media screen and (min-width: 75em) {
  .login-form .attention-pill {
    display: flex;
    padding-right: 12px;
    max-width: unset;
  }
}
.login-form form {
  display: grid;
  grid-template-columns: 1fr;
  align-items: end;
}
@media screen and (min-width: 22.75em) {
  .login-form form {
    gap: 1em;
  }
}
@media screen and (min-width: 35em) {
  .login-form form {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (min-width: 48em) {
  .login-form form {
    grid-template-columns: 1fr 1fr auto;
  }
}
@media screen and (min-width: 75em) {
  .login-form form {
    gap: 24px;
  }
}
@media screen and (min-width: 87.5em) {
  .login-form form {
    gap: 32px;
  }
}
@media screen and (min-width: 22.75em) {
  .login-form form .field-element {
    margin-bottom: 0;
  }
}
.login-form form .field-element--password {
  margin-bottom: 0;
}
.login-form form .field-element--checkboxboollist {
  display: none;
}
.login-form form .submit-bar {
  position: relative;
  background: none;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
}
@media screen and (min-width: 35em) {
  .login-form form .submit-bar {
    grid-column: span 2;
  }
}
@media screen and (min-width: 48em) {
  .login-form form .submit-bar {
    grid-column: auto;
  }
}
.login-form form .submit-bar [href*="/user/forgotten_password"] {
  font-size: var(--centi-font-size);
  margin-bottom: 24px;
  display: block;
  margin-right: 0;
}
@media screen and (min-width: 22.75em) {
  .login-form form .submit-bar [href*="/user/forgotten_password"] {
    display: inline-block;
    margin-right: 20px;
    margin-bottom: 0;
  }
}
@media screen and (min-width: 48em) {
  .login-form form .submit-bar [href*="/user/forgotten_password"] {
    position: absolute;
    top: calc(100% + 1em);
    right: 0;
    white-space: nowrap;
    margin-right: 0;
  }
}
@media screen and (min-width: 75em) {
  .login-form form .submit-bar [href*="/user/forgotten_password"] {
    top: 0;
  }
}
.login-form form .submit-bar button[type=submit] {
  display: block;
  width: 100%;
  background-color: #FFB548;
  color: #2D1945;
  padding-left: 3em;
  padding-right: 3em;
  padding-top: 1em;
  padding-bottom: 1em;
}
@media screen and (min-width: 22.75em) {
  .login-form form .submit-bar button[type=submit] {
    display: inline-grid;
    width: auto;
  }
}
@media screen and (min-width: 48em) {
  .login-form form .submit-bar button[type=submit] {
    margin-top: 32px;
  }
}
@media screen and (min-width: 62em) {
  .login-form form .submit-bar button[type=submit] {
    margin-top: 16px;
  }
}
@media screen and (min-width: 75em) {
  .login-form form .submit-bar button[type=submit] {
    margin-top: 50px;
  }
}

.sidebar {
  border-top: 1px solid #E4E4E4;
}
@media screen and (min-width: 62em) {
  .sidebar {
    border-top: none;
  }
}

.tile-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.tile-list:before, .tile-list:after {
  content: " ";
  display: table;
}
.tile-list:after {
  clear: both;
}
.tile-list__item {
  margin-bottom: 25px;
}

@media screen and (min-width: 48em) {
  /*  768px */
  .tile-list {
    margin: 0 -30px;
  }
  .tile-list__item {
    width: 33.333%;
    padding: 15px 30px;
    float: left;
  }
}
/* ---- top-heavy-list ---- */
/* To display a list of products nicely */
.top-heavy-list {
  width: 100%;
  list-style: none;
  padding: 20px 0 30px;
  margin: 0 -10px;
}
.top-heavy-list:before, .top-heavy-list:after {
  content: " ";
  display: table;
}
.top-heavy-list:after {
  clear: both;
}
.top-heavy-list > .top-heavy-list {
  padding: 0;
  margin-left: 0;
  margin-right: 0;
}
.top-heavy-list + .top-heavy-list {
  padding-top: 0;
}
.top-heavy-list__item {
  padding: 10px;
}

@media screen and (min-width: 35em) {
  /* 560px */
  .top-heavy-list__item {
    padding: 10px;
    width: 50%;
    float: left;
  }
  .top-heavy-list__item:nth-child(2n+1) {
    clear: left;
  }
}
@media screen and (min-width: 62em) {
  /* 992px */
  .top-heavy-list__item {
    width: 33.3333%;
  }
  .top-heavy-list__item:nth-child(2n+1) {
    clear: none;
  }
  .top-heavy-list__item:nth-child(3n+1) {
    clear: left;
  }
}
@media screen and (min-width: 87.5em) {
  /* 1400px */
  .top-heavy-list__item {
    width: 25%;
  }
  .mainbar--narrow .top-heavy-list__item {
    width: 33.33333333%;
  }
  .top-heavy-list__item:nth-child(3n+1) {
    clear: none;
  }
  .top-heavy-list__item:nth-child(4n+1) {
    clear: left;
  }
}
/* Flex code */
@supports (display: flex) {
  @media screen and (min-width: 35em) {
    /* 560px */
    .top-heavy-list {
      display: flex;
      flex-flow: row wrap;
      margin-left: -10px;
      margin-right: -10px;
      width: calc(100% + 20px) .top-heavy-list __item;
      width-margin-right: auto;
      width-margin-left: auto;
      width-padding: 0 10px 20px;
      width-flex-basis: 50%;
      width-min-width: 50%;
      width-width: auto !important;
    }
    .top-heavy-list--five .top-heavy-list__item, .top-heavy-list__item:nth-child(-n+3) {
      flex-grow: 1;
    }
  }
  @media screen and (max-width: 991px) {
    /* 991px */
    .top-heavy-list--three .top-heavy-list__item:first-child, .top-heavy-list--five .top-heavy-list__item:first-child, .top-heavy-list--seven .top-heavy-list__item:first-child, .top-heavy-list--odd .top-heavy-list__item:first-child {
      flex-basis: 100%;
    }
  }
  @media screen and (min-width: 62em) {
    /* 992px */
    .top-heavy-list {
      margin-left: -2%;
      margin-right: -2%;
      /*  grid with sidebar, stays only 3 columns  */
    }
    .top-heavy-list .top-heavy-list__item {
      padding: 2%;
      flex-basis: 25%;
      min-width: 25%;
    }
    .top-heavy-list--odd > .top-heavy-list__item, .mainbar--narrow .top-heavy-list > .top-heavy-list__item, .top-heavy-list--six > .top-heavy-list__item, .top-heavy-list--seven > .top-heavy-list__item:nth-child(-n+3) {
      flex-basis: 33.33333333%;
      min-width: 33.33333333%;
    }
    .top-heavy-list--five > .top-heavy-list__item:nth-child(-n+2) {
      flex-basis: 50%;
    }
    .top-heavy-list--five > .top-heavy-list__item:nth-child(n+3) {
      flex-basis: 20%;
    }
    .mainbar--narrow .top-heavy-list--four > .top-heavy-list__item:nth-child(-n+4), .mainbar--narrow .top-heavy-list--seven > .top-heavy-list__item:nth-child(-n+4) {
      flex-basis: 50%;
    }
    .mainbar--narrow .top-heavy-list--five > .top-heavy-list__item:nth-child(-n+2), .mainbar--narrow .top-heavy-list--even > .top-heavy-list__item:nth-child(-n+2) {
      flex-basis: 50%;
    }
  }
}
.media-object__list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.media-object__list:before, .media-object__list:after {
  content: " ";
  display: table;
}
.media-object__list:after {
  clear: both;
}

.media-object__item {
  padding: 10px 0;
}

.media-object__list:after {
  clear: both;
}

.card__list {
  padding: 0;
  margin: 0;
  list-style: none;
}

@supports (display: grid) {
  .card__list {
    display: grid;
    grid-gap: 32px;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  }

  .card__item + .card__item {
    margin-top: 0;
  }
}
.card-lnd-hvr {
  padding: 0;
  margin: 0;
  list-style: none;
}
.card-lnd-hvr:before, .card-lnd-hvr:after {
  content: " ";
  display: table;
}
.card-lnd-hvr:after {
  clear: both;
}
.card-lnd-hvr__item {
  margin-bottom: 25px;
}

@media screen and (min-width: 48em) {
  /* 768px */
  .card-lnd-hvr {
    margin: 0 -15px;
    display: flex;
    flex-flow: row wrap;
  }
  .card-lnd-hvr__item {
    margin-bottom: 30px;
    width: 50%;
    padding: 0 15px 0;
    float: left;
  }
  .card-lnd-hvr__item:nth-child(2n+1) {
    clear: left;
  }
}
@media screen and (min-width: 75em) {
  /* 1200px */
  .card-lnd-hvr {
    margin: 0;
    box-shadow: 0 0 32px rgba(0, 0, 0, 0.2);
  }
  .card-lnd-hvr__item {
    margin-bottom: 0;
    padding: 0;
  }
}
.video-list {
  list-style-type: none;
  padding-left: 0;
  display: grid;
  gap: 12px;
}
@media screen and (min-width: 30em) {
  .video-list {
    gap: 32px;
    grid-template-columns: repeat(auto-fit, minmax(195px, 1fr));
  }
}
.video-list__item__img-link {
  display: block;
  position: relative;
}
.video-list__item__img-link::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: url(../images/icon-system/icon_youtube.svg) no-repeat center center;
  background-size: 20% auto;
  opacity: 0;
  transition: background 250ms ease-in-out, opacity 250ms ease-in-out;
}
.video-list__item__img-link:hover::after {
  background-size: 25% auto;
  opacity: 1;
}
.video-list__item__button {
  text-align: center;
  vertical-align: middle;
  display: inline-grid;
  grid-auto-flow: column;
  column-gap: 0.5em;
  justify-content: center;
  align-items: center;
  white-space: normal;
  cursor: pointer;
  border-radius: var(--button-border-radius);
  outline: none;
  opacity: 1;
  position: relative;
  margin: 0;
  text-decoration: none;
  transition: background-color 250ms ease-in-out, border-color 250ms ease-in-out, color 250ms ease-in-out;
  padding-left: 2.5em;
  padding-right: 2.5em;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
  font-family: nunito-sans, helvetica neue, helvetica, roboto, noto, arial, sans-serif;
  font-weight: 700;
  line-height: var(--body-line-height);
  color: #ffffff;
  background: #00847F;
  border: var(--button-border-width) solid #00847F;
  margin-top: 8px;
  display: block;
  font-size: 80%;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  line-height: 1.12;
}
.video-list__item__button svg {
  max-height: 1em;
  max-width: 1em;
}
.video-list__item__button svg path,
.video-list__item__button svg circle,
.video-list__item__button svg ellipse,
.video-list__item__button svg rect,
.video-list__item__button svg line,
.video-list__item__button svg polygon {
  fill: #ffffff;
  transition: fill 250ms ease-in-out;
}
.video-list__item__button:hover, .video-list__item__button:focus, .video-list__item__button:active {
  background-color: #00514e;
  border-color: #00514e;
  color: #ffffff;
}
.video-list__item__button:hover svg path,
.video-list__item__button:hover svg circle,
.video-list__item__button:hover svg ellipse,
.video-list__item__button:hover svg rect,
.video-list__item__button:hover svg line,
.video-list__item__button:hover svg polygon, .video-list__item__button:focus svg path,
.video-list__item__button:focus svg circle,
.video-list__item__button:focus svg ellipse,
.video-list__item__button:focus svg rect,
.video-list__item__button:focus svg line,
.video-list__item__button:focus svg polygon, .video-list__item__button:active svg path,
.video-list__item__button:active svg circle,
.video-list__item__button:active svg ellipse,
.video-list__item__button:active svg rect,
.video-list__item__button:active svg line,
.video-list__item__button:active svg polygon {
  fill: #ffffff;
}
.video-list__item__button:focus {
  outline-color: indigo;
  outline-style: auto;
  outline-width: 2px;
}

/* ------------------------------------------------------
**** MODULES
------------------------------------------------------ */
/*
 * NOTE: [modules:01]
 * DO NOT PUT core module css here
 * the following are for bespoke skin styles that are ADDITIONAL to core module css
 */
:root {
  --test-type-identifier-diameter: 56px;
  --test-initial-diameter: 40px;
}

@media screen and (min-width: 48em) {
  :root {
    --test-type-identifier-diameter: 80px;
    --test-initial-diameter: 56px;
  }
}
/* ------------------------------
Result value validation field styles
--------------------------------- */
[class*=Qaams-Widgets] .field-label {
  border-left: 4px solid rgba(0, 0, 0, 0.15);
}

.field-element--result-action .field-label {
  border-left: 4px solid #BD202E;
}

.field-element--result-warning .field-label {
  border-left: 4px solid #FCB34C;
}

.field-element--result-target .field-label {
  border-left: 4px solid #A2CF5F;
}

.field-input .result-warning,
.field-input .result-target,
.field-input .result-action {
  background-repeat: no-repeat;
  background-position: center right 8px;
  background-size: 32px;
  padding-right: 40px;
}

.field-input .result-warning {
  background-image: url(../images/icon-system/icon_warning.svg);
}

.field-input .result-target {
  background-image: url(../images/icon-system/icon_tick.svg);
}

.field-input .result-action {
  background-image: url(../images/icon-system/icon_cross.svg);
}

/* ----------------------------
Result value validation site messages
------------------------------ */
.test-utility-messages {
  font-size: 1.4rem;
  font-weight: 600;
  border-bottom: 2px solid transparent;
  margin-bottom: calc(var(--paragraph-break) * 1.5);
}
.test-utility-messages ul.messages {
  margin-bottom: 0;
}
.test-utility-messages p {
  padding: 8px 24px;
  margin-bottom: 0.5em;
}
.test-utility-messages.error {
  border-bottom-color: #BD202E;
}
.test-utility-messages.error p {
  background-image: url(../images/icon-system/icon_information.svg);
  background-repeat: no-repeat;
  background-position: center left 24px;
  background-size: 24px;
  padding-left: 56px;
}
.test-utility-messages.warning {
  border-bottom-color: #FCB34C;
}

/* ----------------------------
Page header test type identifier
------------------------------ */
.test-type-identifier {
  position: absolute;
  bottom: calc(var(--test-type-identifier-diameter) / -2);
  right: var(--default-container-gutter);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--test-type-identifier-diameter);
  height: var(--test-type-identifier-diameter);
  width: var(--test-type-identifier-diameter);
  z-index: 1;
}
.test-type-identifier:before {
  content: attr(test-type);
  display: block;
  font-family: brandon-grotesque, sans-serif;
  font-weight: 700;
  font-size: 3rem;
}
.test-type-identifier[test-type=QC] {
  background-color: #00847F;
  color: #ffffff;
}
.test-type-identifier[test-type=QA] {
  background-color: #655DC6;
  color: #ffffff;
}

/* ----------------------------
Test form section header
------------------------------ */
.test-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: calc(var(--paragraph-break) * 2);
  margin-bottom: var(--paragraph-break);
}
@media screen and (min-width: 22.5em) {
  .test-header {
    align-items: baseline;
  }
}
@media screen and (min-width: 35em) {
  .test-header {
    align-items: flex-end;
  }
}
.test-header [data-test-lot] {
  margin-bottom: 0;
  flex: 0 0 auto;
}
@media screen and (max-width: 22.4375em) {
  .test-header [data-test-lot] {
    font-weight: 700;
    opacity: 0.5;
    flex-basis: 100%;
    padding-left: calc(var(--test-initial-diameter) + 0.66em);
    margin-top: -0.8em;
  }
}
@media screen and (min-width: 22.5em) {
  .test-header [data-test-lot] {
    font-size: 1.3rem;
  }
}
@media screen and (min-width: 35em) {
  .test-header [data-test-lot] {
    font-size: 1em;
  }
}

.test-heading {
  flex: 1 1 auto;
  font-family: nunito-sans, helvetica neue, helvetica, roboto, noto, arial, sans-serif;
  font-weight: 800;
  margin-bottom: 0;
}
@media screen and (max-width: 34.9375em) {
  .test-heading {
    font-size: 1.25em;
  }
}
.test-heading--grid {
  display: grid;
  grid-auto-flow: column;
  column-gap: 0.5em;
  justify-content: start;
  align-items: center;
}
.test-heading [data-test-initial] {
  display: inline-grid;
  grid-auto-flow: column;
  column-gap: 0.5em;
  justify-content: start;
  align-items: center;
}
.test-heading [data-test-initial]:before {
  content: attr(data-test-initial);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--test-initial-diameter);
  height: var(--test-initial-diameter);
  width: var(--test-initial-diameter);
  text-transform: uppercase;
}
.test-heading [data-test-initial=U]:before {
  background-color: #FFB548;
  color: #ffffff;
}
.test-heading [data-test-initial=H]:before {
  background-color: #D05559;
  color: #ffffff;
}
.test-heading [data-test-indicator] {
  display: inline-grid;
  grid-auto-flow: column;
  column-gap: 0.25em;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}
.test-heading [data-test-indicator]:before {
  content: "";
  background: center center/auto 100% no-repeat transparent;
  height: 1.25em;
  width: 1.25em;
}
.test-heading [data-test-indicator=low]:before {
  background-image: url(../images/icon_low.svg);
}
.test-heading [data-test-indicator=high]:before {
  background-image: url(../images/icon_high.svg);
}
.test-heading__light {
  font-weight: 300;
}

.test-subheading {
  margin-top: 0.25em;
}

/* ------------------------------
Use visual identifiers for the field type.
--------------------------------- */
.field-element--albumin input,
.field-element--creatinine input,
.field-element--normal input,
.field-element--abnormal input {
  background-repeat: no-repeat;
  background-position: center right 8px;
  background-size: 32px;
  padding-right: 40px;
}

.field-element--albumin input {
  background-image: url(../images/icon_albumin.svg);
}

.field-element--creatinine input {
  background-image: url(../images/icon_creatinine.svg);
}

.field-element--albumin input {
  background-image: url(../images/icon_albumin.svg);
}

.field-element--normal input {
  background-image: url(../images/icon_normal.svg);
}

.field-element--abnormal input {
  background-image: url(../images/icon_abnormal.svg);
}

/* ----- Team members ----- */
.team-members-list {
  margin-top: 24px;
  list-style-type: none;
  padding-left: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: 16px;
}
@media screen and (min-width: 48em) {
  .team-members-list {
    margin-top: 32px;
  }
}
.team-members-list__item {
  text-align: center;
}
.team-members-list__item a {
  font-size: 0.9em;
  line-height: 1.12;
  display: inline-block;
  margin: auto;
  max-width: 222px;
}

.team-member__img {
  margin-bottom: 8px;
  display: block;
  border-radius: 100%;
  margin-left: auto;
  margin-right: auto;
  aspect-ratio: 1;
}
.team-member__name {
  font-size: var(--h3-font-size);
  color: inherit;
  color: #2D1945;
  margin: 0 0 8px;
}
.team-member__position {
  font-size: var(--h4-font-size);
  color: inherit;
}

/* ----- Publications ----- */
.publication-list {
  list-style-type: none;
  padding-left: 0;
}

.publication-grid {
  list-style-type: none;
  padding-left: 0;
  display: grid;
  gap: 12px;
}
@media screen and (min-width: 30em) {
  .publication-grid {
    gap: 32px;
    grid-template-columns: repeat(auto-fit, minmax(195px, 1fr));
  }
}
.publication-grid__item {
  display: flex;
}
@media screen and (min-width: 48em) {
  .publication-grid__item:first-child:last-child {
    max-width: calc(40% - 32px);
  }
}
.publication-grid__item .publication {
  margin: 0;
}

.publication {
  display: flex;
  flex-flow: column nowrap;
  gap: 12px;
}
@media screen and (min-width: 30em) {
  .publication {
    gap: 16px;
  }
}
.publication--detailed {
  display: grid;
  justify-content: start;
  gap: 12px;
  padding-bottom: 16px;
  margin-bottom: 32px;
  border-bottom: 3px solid #C1BDDE;
}
@media screen and (min-width: 30em) {
  .publication--detailed {
    gap: 24px;
    grid-auto-flow: column;
  }
}
.publication__img {
  width: 100px;
}
@media screen and (min-width: 30em) {
  .publication__img {
    width: auto;
  }
}
.publication__img img {
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
}
.publication--detailed .publication__img {
  width: 100px;
}
@media screen and (min-width: 48em) {
  .publication--detailed .publication__img {
    width: 140px;
  }
}
@media screen and (min-width: 30em) {
  .publication--detailed .publication__img {
    padding-bottom: 16px;
  }
}
.publication__details {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
}
.publication__details__year {
  margin: 0;
}
.publication__details__name {
  color: #2D1945;
  margin-top: 0;
  font-size: 2rem;
  flex: 1 0 auto;
}
.publication__details__publication {
  margin-bottom: 8px;
  font-weight: 700;
}
.publication--detailed .publication__details__name {
  margin-bottom: 12px;
}

/* ----- Sponsors ----- */
.sponsors-list {
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
}
@media screen and (min-width: 30em) {
  .sponsors-list {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
}
.sponsors-list::after, .sponsors-list::before {
  content: unset;
}
.sponsors-list__item {
  width: unset;
  min-height: unset;
  margin: 0;
  border: 1px solid #DADADA;
  padding: 8px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  background-color: #ffffff;
}
.sponsors-list__item-link {
  text-decoration: none;
  display: grid;
  align-content: space-between;
  height: 100%;
  color: inherit;
  background-color: #ffffff;
  position: relative;
  z-index: 3;
}
.sponsors-list__item-link:before {
  content: "";
  transition: all 250ms ease-in-out;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  background-color: #DADADA;
  z-index: -1;
}
.sponsors-list__item-link:hover:before, .sponsors-list__item-link:focus:before, .sponsors-list__item-link:active:before {
  top: -8px;
  bottom: -8px;
  left: -8px;
  right: -8px;
  opacity: 1;
}
.sponsors-list__img-wrap {
  padding: 8px;
  display: grid;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1;
  background-color: #ffffff;
}
.sponsors-list__img-wrap__vert-center {
  aspect-ratio: 1;
}
.sponsors-list__img-wrap img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.sponsors-list .sponsors-list-item-name {
  margin-top: 8px;
  font-size: 1.4rem;
  line-height: 1.12;
}

.sponsors-list-item-name {
  display: none;
}

/* ------------------------------------------------------
**** PAGES
------------------------------------------------------ */
:root {
  --inner-scaffold-gap: 4.8888888vw;
}

@media screen and (min-width: 48em) {
  :root {
    --inner-scaffold-gap: 5.11111vw;
  }
}
@media screen and (min-width: 100em) {
  :root {
    --inner-scaffold-gap: 80px;
  }
}
.inner-scaffold {
  display: grid;
  gap: var(--inner-scaffold-gap);
}
@media screen and (min-width: 62em) {
  .inner-scaffold {
    grid-template-columns: 1fr 2fr;
  }
}
@media screen and (min-width: 62em) {
  .inner-scaffold .mainbar {
    grid-column: 2;
    grid-row: 1;
  }
}
@media screen and (min-width: 62em) {
  .inner-scaffold .sidebar {
    grid-column: 1;
    grid-row: 1;
  }
}

/* ------------------------------------------------------
**** Print styles
------------------------------------------------------ */
@media print {
  * {
    background: transparent !important;
    color: #000 !important;
    /* Black prints faster: h5bp.com/s */
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a,
a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  /* Don't show links for images, or javascript/internal links */
  .ir a:after,
a[href^="javascript:"]:after,
a[href^="#"]:after {
    content: "";
  }

  pre,
blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
    /* h5bp.com/t */
  }

  tr,
img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  @page {
    margin: 0.5cm;
  }
  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
h3 {
    page-break-after: avoid;
  }
}