/* -----------------------------
File Path
-------------------------------- */
$file-path: ''; // not used


/* -----------------------------
Spacing
-------------------------------- */
$spacing:             8px;

$spacing--xxsmall:            calc(#{$spacing}/4);

$spacing--xsmall:             calc(#{$spacing}/2);

$spacing--small:              $spacing;

$spacing--medium:             $spacing*2;

$spacing--large:              $spacing*3;

$spacing--xlarge:             $spacing*4;

$spacing--xxlarge:            $spacing*8;


/* -----------------------------
style variables
-------------------------------- */
$standard-radius:            4px; // note: button radius set manually

$form-spacing:            8px;


/* -----------------------------
Header
-------------------------------- */

$header-transition: 350ms ease;


/* -----------------------------
Page
-------------------------------- */
$max-page-width: 2400px;
