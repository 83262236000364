:root {
    --inner-scaffold-gap: 4.8888888vw;
}

@include screen-768 {
    :root {
        --inner-scaffold-gap: 5.11111vw;
    }
}

@include screen-1600 {
    :root {
        --inner-scaffold-gap: 80px;
    }
}

.inner-scaffold {
    display: grid;
    gap: var(--inner-scaffold-gap);

    @include screen-992 {
        grid-template-columns: 1fr 2fr;
    }

    .mainbar {
        @include screen-992 {
            grid-column: 2;
            grid-row: 1;
        }
    }

    .sidebar {
        @include screen-992 {
            grid-column: 1;
            grid-row: 1;
        }
    }
}
