.breadcrumb {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: var(--centi-font-size);

    > li {
        display: inline-block;

        &:before {
            content: "\2002\2022\2002";
            opacity: 0.6;
        }

        &:first-child:before {
            content: none;
        }

        a {
            color: inherit;
            opacity: 1;

            &:hover,
            &:focus,
            &:active {
                opacity: 0.8;
            }
        }

        @media screen and (max-width: 47.9375em) { /* 767px */
            display: none;
            margin-left: 0;

            &:first-child,
            &:nth-last-child(2) {
                display: inline-block;
            }

            &:nth-last-child(2):before{
                background: none;
                content: '\00a0\003c\00a0';
                position: unset;
                font-weight: bold;
                position: relative;
                left: -1px;
                margin-left: 0px;
                top: 0;
            }
        }
    }
}

@media screen and (max-width: 61.25em) { /* 980px */
    /* code for the blog posts because the breadcrumbs are nonsensical */
    .blog-post-controller .breadcrumb {
        > li {
            display: none;
            margin-left: 0;

            &:nth-last-child(5) {
                display: inline-block;
            }

            &:nth-last-child(2) {
                display: none;
            }

            &:nth-last-child(5):before {
                background: none;
                content: '\003c\00a0';
                position: unset;
                font-weight: bold;
                position: relative;
                left: -1px;
                margin-left: 0px;
                top: 0;
            }
        }
    }
}
