
.publication-list {
    @include list-reset;
}

.publication-grid {
    @include list-reset;
    display: grid;
    gap: $spacing*1.5;

    @include screen-480 {
        gap: $spacing*4;
        grid-template-columns: repeat(auto-fit, minmax(195px, 1fr));
    }
    @include screen-768 {
        // gap: $spacing*6;
    }

    &__item {
        display: flex;

        &:first-child:last-child {
            @include screen-768 {
                max-width: calc(40% - 32px);
            }
        }


        .publication {
            margin: 0;
        }
    }

}

.publication {
    display: flex;
    flex-flow: column nowrap;
    gap: $spacing*1.5;

    @include screen-360 {

    }
    @include screen-480 {
        gap: $spacing*2;
    }

    &--detailed {
        display: grid;
        justify-content: start;
        gap: $spacing*1.5;
        padding-bottom: $spacing*2;
        margin-bottom: $spacing*4;
        border-bottom: 3px solid $color-accent-01-mid;

        @include screen-480 {
            gap: $spacing*3;
            grid-auto-flow: column;
        }
    }

    &__img {
        width: 100px;

        @include screen-480 {
            width: auto;
        }

        img {
            box-shadow: 0px 0px 6px rgb(0 0 0 / 20%);
        }
    }

    &--detailed &__img {
        width: 100px;

        @include screen-768 {
            width: 140px;
        }
        @include screen-480 {
            padding-bottom: $spacing*2;
        }
    }


    &__details {
        display: flex;
        flex-flow: column nowrap;
        height: 100%;

        &__year {
            margin: 0;
        }

        &__name {
            color: $color-primary;
            margin-top: 0;
            font-size: 2rem;
            flex: 1 0 auto;
        }

        &__publication {
            margin-bottom: $spacing;
            font-weight: $fw-bold;
        }
    }
    &--detailed &__details__name {
        margin-bottom: $spacing*1.5;
    }


}
