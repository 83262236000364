.field-element--datepicker .field-input,
.field-element--daterangepicker .field-input {
    position: relative;
}

.field-element--datepicker .field-input:before,
.field-element--daterangepicker .field-input:before {
    content: "\00a0";
    display: block;
    position: absolute;
    left: calc( #{$form-spacing}/4);
    top: 0;
    width: $form-spacing*5;
    height: calc(var(--field-padding-vertical)*2 + (var(--body-line-height) * 1em));
    background-image: url(../images/icon-system/icon_form_datepicker.svg);
    background-repeat: no-repeat;
    background-size: 24px;
    background-position: center center;
    z-index: 1;
}
