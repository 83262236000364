.widget-RelatedLinks {
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    ul.depth1 {
        background: url(../images/qaams-artwork-borderless.jpg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    li {
        a {
            display: block;
            line-height: normal;
            text-decoration: none;
            width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;

            span {
                color: $color-body-font;
                display: block;
                padding: 1em 64px 1em 24px;
                transform: none;
                background-color: $color-white;
                border-top: 1px solid $color-grey-01;
                transition:
                    color $link-transition,
                    opacity $link-transition,
                    background-color $link-transition,
                    width $link-transition,
                    transform 300ms cubic-bezier(0.250, 0.460, 0.450, 0.940);
            }

            &:hover,
            &:focus,
            &:active {
                span {
                    transform: translateX(56px);
                }
            }
        }

        /* - Depth 1 - */
        &.depth1 {
            position: relative;
        }

        /* On */
        &.depth1.on > a span {
            position: relative;
            background-color: $color-accent-01;
            color: $color-white;
            border-color: $color-accent-01;
        }

        ul.depth2 {
            display: none;
        }

        &.on ul.depth2 {
            display: block;
        }

        &.ancestor ul.depth2 {
            display: block;
        }

        /* - Depth 2 - */
        &.depth2 {
            font-size: var(--centi-font-size);

            a {
                span {
                    padding: 0.75em 64px 0.75em 32px;
                }

                &:hover,
                &:focus,
                &:active {
                    span {
                        transform: translateX(38px);
                    }
                }
            }
        }

        /* On */
        &.depth2.on > a span {
            color: $color-secondary;
            font-weight: bold;
        }

        &.depth3 {
            a {
                span {
                    padding-left:  48px;
                }
            }
        }
    }
}
