:root {
    --body-font-size: 1.6rem;
    --body-line-height: 1.5;

    // headings
    --h1-font-size: 4rem;

    --h2-font-size: 3.2rem;

    --h3-font-size: 2.4rem;

    --h4-font-size: 2rem;

    --h5-font-size: 2rem;

    --h6-font-size: 1.8rem;

    /* [02] */
    // big
    --giga-font-size: 8rem;
    --mega-font-size: 4rem;
    --kilo-font-size: 3.2rem;

    // small
    --milli-font-size: 1.1rem;
    --centi-font-size: 1.3rem;
}

@include screen-768 {
    :root {
        --body-font-size: 1.7rem;

        --kilo-font-size: 4rem;
        --mega-font-size: 5.25vw;
    }
}
@include screen-1600 {
    :root {
        --body-font-size: 1.8rem;
    }
}


@include screen-1920 {
    :root {
        --mega-font-size: 10rem;
    }
}


/* Weights */


$fw-light: 300;

$fw-normal: 400;

$fw-medium: 500;

$fw-semibold: 600;

$fw-bold: 700;

$fw-heavy: 800;



/*
Basic system font stacks
*/

$primary-font: nunito-sans, helvetica neue, helvetica, roboto, noto, arial, sans-serif;
/*
$fw-normal
$fw-semibold
$fw-bold
$fw-heavy
*/

$accent-font: brandon-grotesque, sans-serif;
/*
$fw-normal
$fw-medium
$fw-bold
*/

$mono-font: Menlo, Consolas, Monaco, "Courier New", Liberation Mono, Lucida Console, monospace;




/* Size */

$font-small: var(--milli-font-size);

$font-body: var(--body-font-size);


// Color

$color-body-font: $color-grey-08;


/* -----
* NOTES
* [02]
* A series of classes for setting massive type; for use in heroes, mastheads,
* promos, etc.
* As per: csswizardry.com/2012/02/pragmatic-practical-font-sizing-in-css
------ */


/*
Brandon Grotesque Regular
font-family: brandon-grotesque, sans-serif;
font-weight: 400;
font-style: normal;
*/

/*
Brandon Grotesque Medium
font-family: brandon-grotesque, sans-serif;
font-weight: 500;
font-style: normal;
*/

/*
Brandon Grotesque Bold
font-family: brandon-grotesque, sans-serif;
font-weight: 700;
font-style: normal;
*/

/*
Nunito Sans Regular
font-family: nunito-sans, sans-serif;
font-weight: 400;
font-style: normal;
*/

