.sponsors-list {
    display: grid;
    gap: $spacing;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));

    @include screen-480 {
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }

    &::after,
    &::before {
        content: unset;
    }

    &__item {
        width: unset;
        min-height: unset;
        margin: 0;
        border: 1px solid $color-grey-02;
        padding: $spacing;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        background-color: $color-white;
    }
    &__item-link {
        text-decoration: none;
        display: grid;
        align-content: space-between;
        height: 100%;
        color: inherit;
        background-color: $color-white;
        position: relative;
        z-index: 3;

        &:before {
            content: '';
            transition: all $link-transition;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            opacity: 0;
            background-color: $color-grey-02;
            z-index: -1;
        }

        &:hover:before,
        &:focus:before,
        &:active:before {
            top: -$spacing;
            bottom: -$spacing;
            left: -$spacing;
            right: -$spacing;
            opacity: 1;
        }
    }

    &__img-wrap {
        padding: $spacing;
        display: grid;
        justify-content: center;
        align-items: center;
        aspect-ratio: 1;
        background-color: $color-white;

        &__vert-center {
            aspect-ratio: 1;
        }

        img {
            object-fit: contain;
            width: 100%;
            height: 100%;
        }
    }

    .sponsors-list-item-name {
        margin-top: $spacing;
        font-size: 1.4rem;
        line-height: 1.12;
    }
}

// .sponsors-list__category-Health_Organisations {
    .sponsors-list-item-name {
        display: none;
    }
// }
