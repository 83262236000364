:root {
    --button-border-width: 0;
    --button-border-radius: 2em;
}

@mixin button-base() {
    text-align: center;
    vertical-align: middle;
    display: inline-grid;
    grid-auto-flow: column;
    column-gap: 0.5em;
    justify-content: center;
    align-items: center;
    white-space: normal;
    cursor: pointer;
    border-radius: var(--button-border-radius);
    outline: none;
    opacity: 1;
    position: relative;
    margin: 0;
    text-decoration: none;
    transition:
        background-color $link-transition,
        border-color $link-transition,
        color $link-transition;
}

@mixin button-regular() {
    @include button-width-regular;
    @include button-height-regular;
}

@mixin button-typography() {
    font-family: $primary-font;
    font-weight: $fw-bold;
    line-height: var(--body-line-height);
}

@mixin button($background: $color-primary, $colour: $color-white) {
    @include button-base;
    @include button-regular;
    @include button-typography;
    color: $colour;
    background: $background;
    border: var(--button-border-width) solid $background;

    svg {
        max-height: 1em;
        max-width: 1em;

        @include svg-contents {
            fill: $colour;
            transition: fill $link-transition;
        }
    }

    &:hover,
    &:focus,
    &:active {
        background-color: darken($background, 10%);
        border-color: darken($background, 10%);
        color: $colour;

        svg {
            @include svg-contents {
                fill: $colour;
            }
        }
    }

    &:focus {
        @include focus;
    }
}

/* --------------------------------
Sizes
----------------------------------- */

/* Width - Inline Padding  */
@mixin button-width-small() {
    padding-left: 1em;
    padding-right: 1em;
}

@mixin button-width-regular() {
    padding-left: 2.5em;
    padding-right: 2.5em;
}

@mixin button-width-large() {
    padding-left: 3em;
    padding-right: 3em;
}

/* Height - Block Padding */
@mixin button-height-small() {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
}

@mixin button-height-regular() {
    padding-top: 0.75em;
    padding-bottom: 0.75em;
}

@mixin button-height-large() {
    padding-top: 1em;
    padding-bottom: 1em;
}

/* Text Size */
@mixin button-text-tiny() {
    font-size: 66.666%;
}

@mixin button-text-small() {
    font-size: 80%;
}

@mixin button-text-regular() {
    font-size: var(--body-font-size);
}

@mixin button-text-large() {
    font-size: 150%;
}
