.calendar-columns {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;
    padding-left: 40px;
    padding-right: 40px;

    @include screen-560 {
        padding-left: 10vw;
        padding-right: 10vw;
    }

    @include screen-768 {
        gap: 56px;
        grid-template-columns: 1fr 1fr;
        padding-left: min(8vw, var(--container-padding));
        padding-right: min(8vw, var(--container-padding));
    }

    @include screen-992 {
        column-gap: 72px;
    }

    @include screen-1200 {
        grid-template-columns: 1fr 1fr 1fr;
    }

    @include screen-1400 {
        gap: 98px;
    }

    @include screen-1600 {
        padding-left: 0;
        padding-right: 0;
    }

    .calendar-item:last-child {
        @include screen-768 {
            grid-column: span 2;
        }

        @include screen-1200 {
            grid-column: span 1;
        }
    }
}

.calendar-item {
    background-color: $color-accent-02-pastel;
    padding-top: 24px;
    border-radius: 2px;

    &__date {
        @include attention-pill($color-accent-02-mid, $color-accent-02, 'heading');
        display: inline-grid;
        margin-left: -29px;

        @include screen-1400 {
            margin-left: -58px;
        }
    }

    &__content {
        padding: 24px 24px 32px;
    }

    &__heading {
        font-size: var(--h3-font-size);
        color: $color-primary;
        line-height: 1;
        margin-bottom: 0.25em;

        @include screen-768 {
            font-size: var(--h2-font-size);
        }
    }

    &__description {
        margin-top: calc(var(--body-font-size)/2);
    }

    &__cta {
        color: #D78000;
        @include text-link;
    }
}

.qa-reminder {
    background-color: $color-accent-01-pastel;

    .calendar-item__date {
        @include attention-pill($color-accent-01-mid, $color-accent-01, 'heading');
        display: inline-grid;
    }

    .calendar-item__cta {
        color: $color-accent-01;
    }
}

.qc-reminder {
    background-color: $color-alternative-pastel;

    .calendar-item__date {
        @include attention-pill($color-alternative-mid, $color-alternative, 'heading');
        display: inline-grid;
    }

    .calendar-item__cta {
        color: $color-alternative;
    }
}

